import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import {
  newsAPI,
  NewsPaginationType,
  NewsPayloadsType,
  NewsType,
} from "../../../common/api/news/news-api";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const getNews = createAppAsyncThunk<NewsPayloadsType>(
  "news/getNews",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const res = await newsAPI.getNews();

    try {
      return res.data.payloads;
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);
const getOneNews = createAppAsyncThunk<NewsType, NewsType>(
  "news/getOneNews",
  async (arg, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    dispatch(newsActions.setOneNews(arg));
    const res = await newsAPI.getOneNews(arg.id);
    try {
      return res.data.payloads;
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);

const slice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getNews.fulfilled, (state, action) => {
      state.news = action.payload.data;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getOneNews.fulfilled, (state, action) => {
      state.oneNews.news = action.payload;
      state.oneNews.isShow = true;
    });
  },
  initialState: {
    news: [] as NewsType[],
    oneNews: {
      isShow: false,
      news: null as NewsType | null,
    },
    pagination: {} as NewsPaginationType,
  },
  name: "newsSlice",
  reducers: {
    closeOneNews: (state) => {
      state.oneNews.news = null;
      state.oneNews.isShow = false;
    },
    setOneNews: (state, action) => {
      state.oneNews.news = action.payload;
      state.oneNews.isShow = true;
    },
  },
});

const news = () => (state: RootState) => state.news.news;
const oneNews = () => (state: RootState) => state.news.oneNews;

export const newsSelector = { news, oneNews };
export const newsActions = slice.actions;
export const newsReducer = slice.reducer;

export const newsThunks = { getOneNews, getNews };
