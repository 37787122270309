import { useState } from "react";
import { ModelSelectionUndefined } from "./model-selection-icons";
import { PopupMessage } from "../../../../components/popup-message/popup-message";

import s from "./model-selection.module.css";

export const UndefinedModel = () => {
    const [isShow, setIsShow] = useState<boolean>(false)

    return (
        <div
            className={s.model_selection_field}
            onMouseMove={() => setIsShow(true)}
            onMouseLeave={() => setIsShow(false)}
        >
            <ModelSelectionUndefined />
            <div className={s.model_selection_label_1}>
                Новая модель
            </div>
            <div className={s.model_selection_label_soon}>
                Скоро
            </div>

            {isShow && (
                <PopupMessage 
                    msgText={["Еще работаем над ней,", "скоро будет доступна для выбора!"]}
                    isChangePosition={true}
                />
            )}

        </div> 
    )
}