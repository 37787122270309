import { SVGProps, Ref, forwardRef, memo } from "react";
export const IconCat5 = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg width="84" height="84" viewBox="0 0 84 84" {...props} ref={ref}>
      <g id="Group 107">
        <circle
          stroke="none"
          id="Ellipse 37"
          cx="42"
          cy="42"
          r="42"
          fill="url(#paint0_angular_622_12768)"
        />
        <circle
          stroke="none"
          id="Ellipse 32"
          cx="42"
          cy="42"
          r="21"
          fill="url(#paint1_angular_622_12768)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_angular_622_12768"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42 42) rotate(90) scale(38.25)"
        >
          <stop stopOpacity="0.9" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_angular_622_12768"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42 42) rotate(-135) scale(18.5616)"
        >
          <stop stopOpacity="0.9" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  ))
);
