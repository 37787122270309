import { useState } from "react"
import { PopupMessage } from "../popup-message/popup-message"
import { QuestionIcon } from "./icon-question"

import s from "./mini-header.module.css"

type PropsType = {
    text: string
    popupText: Array<string>
    notShowPopup?: boolean
}

export const QuestionsBlockMiniHeader = (props: PropsType) => {
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false)

    return (
        <div className={s.mini_header_top_line}>
            <div className={s.mini_header_class}>
                {props.text}
            </div>
            <div
                onMouseMove={() => setIsShowPopup(true)}
                onMouseLeave={() => setIsShowPopup(false)}
                className={s.model_selection_top_line_question_icon}
            >
                {!props.notShowPopup && <>
                    <QuestionIcon isSelected={isShowPopup}/>
                    {/* <PopupMessage msgText={props.popupText} /> */}
                    {isShowPopup && <PopupMessage msgText={props.popupText} />}
                </>}
            </div>
        </div>


                
    )
}