import { AxiosResponse } from "axios";
import { Avatar } from "../../../store/features/profile/profile-type";
import { instance } from "../instance";

export const filesAPI = {
  changeAvatar: (
    id_avatar: string
  ): Promise<AxiosResponse<ChangeAvatarResponse>> => {
    return instance.post("/files/set_avatars_user/", { id_avatar });
  },
  fetchAvatarList: () => {
    return instance.get<fetchAvatarListType>("/files/avatars_list/");
  },
};

export type fetchAvatarListType = {
  status: string;
  payloads: Avatar[];
};

export type ChangeAvatarResponse = {
  status: string;
  message: string;
};
