import { useEffect, useState } from "react";
import s from "./OneQuestionSemiOptional.module.css";
import { QuestionType } from "../../../../../store/features/questionTypes";
import { LineTextField1 } from "../../../../../components/textField/lineTextField";

type OneQuestionTextPropsType = {
  question: QuestionType;
  isDisabled?: boolean;
};
const OneQuestionSemiOptional = (props: OneQuestionTextPropsType) => {

  const [newVal, setNewVal] = useState<string>(props.question.answer || "");

  useEffect(() => {
    setNewVal(props.question.answer || "");
  }, [props.question.answer]);

  const onQuestionTextChangeHandler = (newValue: string) => {
    setNewVal(newValue);
  };

  return (
    <div className={s.OneQuestionSemiOptional_wrapper}>
      <div className={s.OneQuestionSemiOptional_option_div}>
        <input type="radio" checked={true} />
        <label>Пункт №...</label>
      </div>
      <LineTextField1
        type="text"
        text={newVal}
        onChangeFunction={(newValue: string) =>
          onQuestionTextChangeHandler(newValue)
        }
        placeholder={props.question.snippet ? props.question.snippet : ""}
      />
    </div>
  );
};

export default OneQuestionSemiOptional;
