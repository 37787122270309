import s from "./auth-page.module.scss";
import { useAppDispatch, useAppSelector } from "../../store/store";
import questionIcon from "../../assets/icons/loginPage/iconQuestion.svg";
import copyIcon from "../../assets/icons/loginPage/copy_icon.png";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import Politics from "./politics/politics";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button/button";
import telegramIcon from "../../assets/icons/loginPage/telegramIcon.svg";
import Header from "../../components/header/header";
import { Typography } from "../../components/typography/Typography";
import { TypographyVariant } from "../../store/storeConst/enums";
import { authSchemaFormType, useAuthForm } from "./auth-schema";
import { ControlledTextField } from "../../components/new-text-field/controlled-text-field";
import React, { useEffect } from "react";
import {
  authThunks,
  isLoginInThunk,
} from "../../store/features/auth/authThunk";
import { appSelectors } from "../../store/features/app/app-selectors";
import { Loader } from "../../components/loader/loader";

export const AuthPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(appSelectors.isLoadingApp());

  useEffect(() => {
    dispatch(isLoginInThunk({ navigate }));
  }, [dispatch, navigate]);

  const onCopyBotNameClickHandler = () => {
    copy("@AIPRprobot");
    toast.info("Текст скопирован");
  };
  const onSubmit = (data: authSchemaFormType) => {
    const { agreeWithPolitical, ...resData } = data;
    dispatch(authThunks.auth({ loginData: resData, navigate }));
  };
  const { control, handleSubmit, formState } = useAuthForm();
  if (isLoading) return <Loader />;

  return (
    <div className={s.wrapper}>
      <Header />
      <div className={s.fromWrapper}>
        <form onSubmit={handleSubmit(onSubmit)} className={s.loginForm}>
          <Typography variant={TypographyVariant.H3}>
            Добро пожаловать!
          </Typography>
          <Typography>Войдите или зарегистрируйтесь</Typography>
          <div>
            <ControlledTextField
              name={"username"}
              control={control}
              placeholder="Логин"
              autoComplete={"off"}
            />
            <ControlledTextField
              name={"password"}
              control={control}
              placeholder="Пароль"
              type="password"
              autoComplete={"off"}
            />
          </div>
          <Politics
            error={!!formState.errors.agreeWithPolitical}
            control={control}
          />
          <Button type={"submit"}>Войти</Button>
          <div className={s.regAnnouncementDiv}>
            <img alt="" src={questionIcon} />
            Получите логин и пароль в телеграм боте просто нажмите кнопку
            «Регистрация»
          </div>
          <Button
            as={"a"}
            className={s.Reg}
            img={telegramIcon}
            href="https://t.me/AIPRprobot"
            target="_blank"
          >
            Регистрация
          </Button>
          <div className={s.regBotnameDiv} onClick={onCopyBotNameClickHandler}>
            <img alt="" src={copyIcon} />
            Или найдите бота по имени @AIPRprobot
          </div>
        </form>
      </div>
    </div>
  );
};
