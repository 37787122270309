import s from "./profile-page.module.scss";
import {
  ButtonVariant,
  TypographyVariant,
} from "../../../store/storeConst/enums";
import { Typography } from "../../../components/typography/Typography";
import { Link, useNavigate } from "react-router-dom";
import { Paths } from "../../../common/router/paths";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { authLogoutThunk } from "../../../store/features/auth/authThunk";
import { Container } from "../../../components/container/container";
import { changeUsername } from "../../../store/features/profile/profile-thunk";
import {
  userAvatar,
  userName,
} from "../../../store/features/profile/profile-selectors";
import { NameSchemaType, useProfilePageForm } from "./profile-page-form";
import { useEffect } from "react";
import { Avatar } from "../../../components/avatar/avatar";
import { ControlledTextField } from "../../../components/new-text-field/controlled-text-field";
import { Button } from "../../../components/button/button";
import { motion } from "framer-motion";
import { animation } from "../../../assets/animation/animate";

export const ProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const avatar = useAppSelector(userAvatar());
  const username: string = useAppSelector(userName());
  const logout = () => dispatch(authLogoutThunk());
  const changePhoto = () => navigate(Paths.choosingPhoto);
  const changePassword = () => navigate(Paths.changePassword);

  const onSubmit = (data: NameSchemaType) => {
    dispatch(changeUsername({ userName: data, setError }));
  };
  useEffect(() => reset(), []);
  const {
    control,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useProfilePageForm(username);
  return (
    <Container>
      <motion.form
        variants={animation.up}
        initial="initial"
        animate="animate"
        exit="exit"
        onSubmit={handleSubmit(onSubmit)}
        className={s.Form}
      >
        <Avatar id={"changeAvatar"} image={avatar} onClick={changePhoto} />
        <div className={s.Centre}>
          <ControlledTextField
            name={"username"}
            errorMessage={errors.username?.message}
            control={control}
            isDefaultValue={true}
            autoComplete="off"
          />
          <Button
            id={"changePasswordButton"}
            onClick={changePassword}
            variant={ButtonVariant.Tertiary}
            fullWidth={true}
            type={"button"}
          >
            Именить пароль
          </Button>
        </div>
        <div className={s.ButtonsFooter}>
          <Typography
            onClick={logout}
            className={s.LogOut}
            variant={TypographyVariant.Body2}
            as={Link}
            to={Paths.auth}
          >
            Выйти из аккаунта
          </Typography>
          <Button
            type="submit"
            variant={
              username !== watch("username")
                ? ButtonVariant.Primary
                : ButtonVariant.Secondary
            }
            disabled={username === watch("username")}
          >
            Сохранить
          </Button>
        </div>
      </motion.form>
    </Container>
  );
};
