import React from "react";
import { LogoIcon } from "../../assets/icons/logo";
import s from "./loader.module.scss";
import clsx from "clsx";

type LoaderPropsType = {
  position?: "absolute" | "container";
};
export const Loader = (props: LoaderPropsType) => {
  return (
    <div className={clsx(s.Loader, s[props.position ?? "absolute"])}>
      <LogoIcon />
    </div>
  );
};
