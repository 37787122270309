import s from "./choosing-photo.module.scss";
import { Container } from "../../../../../components/container/container";
import clsx from "clsx";
import { Avatar } from "../../../../../store/features/profile/profile-type";

export type ChoosingPhotoPropsType = {
  images: Avatar[];
  activeImg?: Avatar | null;
  onClick: (active: Avatar) => void;
};
export const ChoosingPhoto = ({
  images,
  activeImg,
  onClick,
}: ChoosingPhotoPropsType) => {
  return (
    <Container color="first" className={s.wrapper}>
      {images.map((img) => {
        return (
          <div
            key={img.id}
            className={clsx(img.id === activeImg?.id && s.active)}
          >
            <img onClick={() => onClick(img)} src={img.src} alt="" />
          </div>
        );
      })}
    </Container>
  );
};
