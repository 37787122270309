import { useParams } from "react-router-dom";
import { CategoryType } from "../../store/features/questionTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
// import ContentBlockSubCategory from "./_contentBlockSubCat";
import ContentBlockParentCategory from "./contentBlockParentCategory";
import ContentBlockSubCategoryV2 from "./contentBlockSubCatV2";
import { ResizeType, useResize } from "../../common/hooks/use-resize/useResize";
import ContentBlockSubCategoryV2Mobile from "./contentBlockSubCategoryV2Mobile/contentBlockSubCategoryV2Mobile";

const PreContentBlock = () => {
  const param = useParams();
  const { categoryId } = param;

  const categories: Array<CategoryType> = useSelector(
    (state: RootState) => state.question.categories
  );
  const ourCategory: CategoryType | undefined =
    categories.length > 0
      ? [...categories].find((cat) => cat.id === categoryId) //|| undefined
      : undefined;
  const isParentCategory: boolean =
    ourCategory && !ourCategory.parentId ? true : false;

  const screenWidth: ResizeType = useResize();

  return (
    <>
      {
        // isParentCategory ? <ContentBlockParentCategory /> : <ContentBlockSubCategory />
        isParentCategory ? (
          <ContentBlockParentCategory />
        ) : screenWidth.width > 620 ? (
          <ContentBlockSubCategoryV2 />
        ) : (
          <ContentBlockSubCategoryV2Mobile />
        )
      }
    </>
  );
};

export default PreContentBlock;
