import { ChangingOneQuestionType, OneOptionType, QuestionOptionType, QuestionType } from "../../../../../store/features/questionTypes";
import { useAppDispatch } from "../../../../../store/store";
import { changeOneAnswerInQuestionAC } from "../../../../../store/features/questionSlice";
import uuid from "react-uuid";

import s from "./oneQuestionOptions.module.css";
import s2 from "./checker-class.module.css";
import clsx from "clsx";

type OneQuestionOptionsPropsType = {
    questionId: string
    options: Array<OneOptionType>
    answer: string | null
    isDisabled?: boolean
}
const OneQuestionOptions = (props: OneQuestionOptionsPropsType) => {
    const dispatch = useAppDispatch();
    const myOptions: Array<OneOptionType> = props.options || [];
    
    const onOptionClickHandler = (id: string) => {
        if (props.answer !== id) {
            const dataToChange:ChangingOneQuestionType = {
                question_id: props.questionId,
                answer: id,
            }
            dispatch(changeOneAnswerInQuestionAC(dataToChange))

        }
    }

    return (
        <div className={s.oneQuestionOptionsWrapper}>
            {
                myOptions.map((opt: OneOptionType, index: number) => {
                    const checkerClass: string = clsx(
                        s2.checker,
                        opt.id === props.answer ? s2.checker_border_yellow : s2.checker_border_white
                    )
                    const innerCheckerClass: string = clsx(
                        s2.inner_checker,
                        opt.id === props.answer && s2.inner_checker_yellow
                    )

                    return (
                        <div 
                            key={uuid()}
                            className={s.radioInputDiv}
                            onClick={props.isDisabled ? () => {} : () => onOptionClickHandler(opt.id)}
                        >
                            <div className={checkerClass}>
                                <div className={innerCheckerClass} />
                            </div>
                            <label>{opt.text}</label>
                        </div>
                    )
                })
            }
        </div>
    )
}


export default OneQuestionOptions;