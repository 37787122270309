import { SVGProps, Ref, forwardRef, memo } from "react";
export const IconCat3 = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <g id="Group 106">
        <path
          fill="none"
          id="Ellipse 16"
          d="M71 48C71 60.8541 68.3373 72.4419 64.08 80.7792C59.795 89.1706 54.0556 94 48 94C41.9444 94 36.205 89.1706 31.92 80.7792C27.6627 72.4419 25 60.8541 25 48C25 35.1459 27.6627 23.5581 31.92 15.2208C36.205 6.82939 41.9444 2 48 2C54.0556 2 59.795 6.82939 64.08 15.2208C68.3373 23.5581 71 35.1459 71 48Z"
          stroke="url(#paint0_linear_622_12670)"
          strokeWidth="2"
        />
        <path
          id="Ellipse 17"
          fill="none"
          d="M48 24C60.8541 24 72.4419 26.6627 80.7792 30.92C89.1706 35.205 94 40.9444 94 47C94 53.0556 89.1706 58.795 80.7792 63.08C72.4419 67.3373 60.8541 70 48 70C35.1459 70 23.5581 67.3373 15.2208 63.08C6.82939 58.795 2 53.0556 2 47C2 40.9444 6.82939 35.205 15.2208 30.92C23.5581 26.6627 35.1459 24 48 24Z"
          stroke="url(#paint1_linear_622_12670)"
          strokeWidth="2"
        />
        <circle
          fill="none"
          id="Ellipse 15"
          cx="48"
          cy="48"
          r="47"
          stroke="url(#paint2_linear_622_12670)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_622_12670"
          x1="48"
          y1="0.999999"
          x2="86.633"
          y2="82.1645"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_622_12670"
          x1="0.999999"
          y1="47"
          x2="82.1645"
          y2="8.36702"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_622_12670"
          x1="14"
          y1="12"
          x2="78"
          y2="93.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  ))
);
