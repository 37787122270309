import { AnimatePresence, motion } from "framer-motion";
import s from "./modal.module.scss";
import { CloseButton } from "../close-button/close-button";
import { FC, ReactNode, RefObject } from "react";
import { useOutsideClick } from "../../common/hooks/useOutsideClick";
import clsx from "clsx";
import { TypographyVariant } from "../../store/storeConst/enums";
import { Typography } from "../typography/Typography";

const animate = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

type ModalPropsType = {
  show: boolean;
  title?: string;
  theRef: RefObject<HTMLDivElement>;
  onChange: () => void;
  className?: string;
  isClose?: boolean;
  children: ReactNode[] | ReactNode;
};

export const Modal: FC<ModalPropsType> = ({
  onChange,
  show,
  title,
  children,
  theRef,
  isClose = true,
  className,
}) => {
  useOutsideClick(theRef, onChange, show);
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          variants={animate}
          initial="hidden"
          animate="show"
          exit="hidden"
          className={s.Layout}
        >
          <div ref={theRef} className={clsx(s.Wrapper, className)}>
            {isClose && <CloseButton onClick={onChange} />}
            <Typography variant={TypographyVariant.H2}>{title}</Typography>
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
