import { useAppSelector } from "../../store/store";
import { appSelectors } from "../../store/features/app/app-selectors";
import { ResizeType, useResize } from "../../common/hooks/use-resize/useResize";
import { newsSelector } from "../../store/features/news/news";
import { motion } from "framer-motion";
import { carouselSettings } from "./carousel-setting";
import "./slick.scss";
import "./slick-theme.scss";
import { NewsCard } from "./news-card/news-card";
import { FullNews } from "./news-card/full-news/full-news";
import Slider from "react-slick";

export const News = () => {
  const isOpen = useAppSelector(appSelectors.isOpenSideBar());
  const devWidth: ResizeType = useResize();
  const news = useAppSelector(newsSelector.news());
  const sideWidth: number = !devWidth.isScreenSm
    ? 40
    : !devWidth.isScreenLg
    ? 80
    : isOpen
    ? 410
    : 155;

  const slider = (
    // @ts-ignore
    <Slider {...carouselSettings}>
      {news.map((n) => (
        <NewsCard news={n} key={n.id} />
      ))}
    </Slider>
  );

  return (
    <>
      <motion.div
        id={"newsCarousel"}
        style={{ width: `calc(100vw - ${sideWidth}px)` }}
        animate={{
          width: `calc(100vw - ${sideWidth}px)`,
          transition: { duration: 0.3 },
        }}
        className={"carousel"}
      >
        {slider}
        <FullNews />
      </motion.div>
    </>
  );
};
