export type MenuSearchTagType = {
    categoryId: string,
    tags: Array<string>,
}

export const menuSearchTags: Array<MenuSearchTagType> = [
    {
        // "Знакомство с нейронками"
        categoryId: "69a3fd45-d269-4290-8cf4-4e095fb390da",
        tags: ["самая", "важная"]
    }
]
