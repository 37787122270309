import { ResizeType, useResize } from '../../../common/hooks/use-resize/useResize';
import { appSelectors } from '../../../store/features/app/app-selectors';
import { appActions } from '../../../store/features/app/app-slice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { FoldIconAnimate } from '../side-menu-animate';
import {FoldIcon} from '../../../assets/icons/category/fold';
import { useEffect, useState } from 'react';
import { CategoryType } from '../../../store/features/questionTypes';
import { LineTextField1 } from '../../textField/lineTextField';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../common/router/paths';

import searchIcon from '../../../assets/icons/search-icon.svg'

import s from './search-and-fold.module.css'
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { menuSearchTags } from './tags';



export const SearchAndFold = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const devWidth: ResizeType = useResize();

  const isOpen = useAppSelector(appSelectors.isOpenSideBar());

  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('')

  const toggle = () => {
    setIsSearchOpen(false)
    dispatch(appActions.toggleSideBar(!isOpen));
  }


  const categories: Array<CategoryType> = useAppSelector(state => state.question.categories)
  const [filteredCategories, setFilteredCategories] = useState<Array<CategoryType>>([])
  
  useEffect(() => {
    searchText.length > 0
        ? setFilteredCategories(categories.filter(c => {
            return (
                //первая строка - поиск по совпадению названий
                c.title.toLowerCase().includes(searchText.toLowerCase()) ||
                //вторая строка - поиск по тэгам, которые лежат в одительном файле
                menuSearchTags.find(elem => elem.categoryId === c.id && elem.tags.some(t => t.toLowerCase().includes(searchText.toLowerCase())))
            )
        }))
        : setFilteredCategories([])
  }, [categories, searchText])
  
  const onSearchIconClickHandler = () => {
    setIsSearchOpen(!isSearchOpen)
    dispatch(appActions.toggleSideBar(true));
  }

  const onCategoryClick = (catId: string) => {
    setIsSearchOpen(false)
    navigate(Paths.category + '/' + catId)
    !devWidth.isScreenLg && dispatch(appActions.toggleSideBar(!isOpen));
  }

    return (
        // Этот div - это строка над категориями, с кнопками "лупа" и свернуть/развернуть наше меню
        <div 
            className={clsx(
                s.search_and_fold_wrapper,
                isOpen ? s.search_and_fold_wrapper_row : s.search_and_fold_wrapper_col
            )}
        >
            
            {/* Иконка лупы - открывает и закрывает строку поиска */}
            <motion.img 
                alt="" 
                src={searchIcon} 
                className={s.search_icon_class} 
                onClick={onSearchIconClickHandler}

                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.3 }}
            />

            {/* Иконка сложить/разложить наше меню десктоп */}
            {devWidth.isScreenLg && (
                <FoldIcon
                  className={s.Fold}
                  animate={FoldIconAnimate(isOpen)}
                  onClick={toggle}
                />
            )}

            {/* Если строка поиска открыта, то отображаем ее и результаты: */}
            <AnimatePresence>
            {isSearchOpen && (
                <motion.div 
                    className={s.search_text_div}
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    exit={{ y: -50, opacity: 0 }}
                >

                    {/* Собственно, строка поиска */}
                    <div className={s.search_line_div}>
                        <LineTextField1 
                            type={'text'} 
                            text={searchText} 
                            onChangeFunction={(val: string) => setSearchText(val)}
                            classForInput={s.lineTextField}
                            placeholder='Поиск по категориям'
                        />
                        {/* <img alt='' src={crossIcon} /> */}
                    </div>

                    {/* Если польщзователь ввел текст, то показываем результаты или надпись об отсутствии совпадений */}
                    <AnimatePresence>
                    {searchText.length > 0 && (
                        <motion.div 
                            className={s.searched_categories_wrapper}
                            initial={{ x: -50, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            exit={{ x: -50, opacity: 0 }}
                        >

                            {filteredCategories.length === 0 && <div>Нет совпадений</div>}

                            {filteredCategories.length > 0 && filteredCategories.map(c => {
                                // Родиельская категория нам нужна, чтобы отобразить ее в результатах
                                const parentCategory: CategoryType | undefined = categories.find(cat => cat.id === c.parentId)

                                return (
                                    <div 
                                        key={uuid()} 
                                        className={s.searched_category_element}
                                        onClick={() => onCategoryClick(c.id)}
                                    >
                                        {parentCategory 
                                            ? <>{parentCategory.title} - {c.title}</>
                                            : <>{c.title}</>
                                        }
                                    </div>
                                )
                            })}
                        </motion.div>
                    )}
                    </AnimatePresence>
                </motion.div>
            )}
            </AnimatePresence>

        </div>
    )
}