import { useSelector } from "react-redux";
import {
  QuestionType,
  TemplateType,
} from "../../../../../store/features/questionTypes";
import s from "./myTemplates.module.css";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { useEffect, useRef, useState } from "react";
import {
  deleteTemplateThunk,
  getTemplatesThunk,
} from "../../../../../store/features/questionThunk";
import { tempChangeQuestionsAC } from "../../../../../store/features/questionSlice";
import { useParams } from "react-router-dom";
import { Modal } from "../../../../../components/modal/modal";
import { Typography } from "../../../../../components/typography/Typography";
import { TypographyVariant } from "../../../../../store/storeConst/enums";
import { Scrollbar } from "../../../../../components/scrollbar";
import { TemplateSection } from "./templateSection/templateSection";

type MyTemplatesPropsType = {
  onCloseFunction: () => void;
  show: boolean;
};

const MyTemplates = ({ onCloseFunction, show }: MyTemplatesPropsType) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const params = useParams();
  const currentCategoryId: string = params.categoryId || "";
  const templates: Array<TemplateType> = [
    ...useSelector((state: RootState) => state.question.templates),
  ].filter((t) => t.categoryId === currentCategoryId);
  const isTemplatesLoading: boolean = useSelector(
    (state: RootState) => state.question.loadingVars.isTemplatesLoading
  );

  const showTemplateQuestionsList: Array<string> = useSelector(
    (state: RootState) => state.question.templateVars.showTemplateQuestionsList
  );

  const [isCreateTemplate, setIsCreateTemplate] = useState<boolean>(false);

  // useEffect(() => {
  //   if (currentCategoryId && currentCategoryId.length > 0) {
  //     dispatch(getTemplatesThunk(currentCategoryId));
  //   }
  // }, []);

  const onApplyTemplateClickHandler = (templateId: string) => {
    const templateQuestions: Array<QuestionType> =
      templates.find((t) => t.id === templateId)?.questions || [];
    if (templateQuestions.length > 0) {
      dispatch(tempChangeQuestionsAC(templateQuestions));
    }
    onCloseFunction();
  };

  const onRemoveTemplateClickHandler = (
    templateId: string,
    templateTitle: string
  ) => {
    const answer = window.confirm("Удалить шаблон " + templateTitle + "?");
    if (answer) {
      dispatch(deleteTemplateThunk({ templateId: templateId }));
    }
  };

  return (
    <Modal
      className={s.Modal}
      theRef={ref}
      show={show}
      onChange={onCloseFunction}
    >
      <Typography variant={TypographyVariant.H2}>Мои шаблоны:</Typography>
      {templates.length === 0 ? (
        <div>
          <label>Пока у Вас нет сохранненых шаблонов в данном разделе.</label>
        </div>
      ) : (
        <Scrollbar className={s.Scroll}>
          <div className={s.Sections}>
            {templates.map((template: TemplateType) => (
              <TemplateSection
                key={template.id}
                template={template}
                onClose={onCloseFunction}
              />
            ))}
          </div>
        </Scrollbar>
      )}
    </Modal>
  );
};

export default MyTemplates;
