import { useState } from "react";
import s from "./localTextStat.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { statFunction, StatFunctionType } from "./localStatFunction";

// Компанент СТАТИСТИКИ в поле ответа GPT

type LocalTextStatPropsType = {
  gptResponse: string;
  answerId: string;
};

const LocalTextStat = (props: LocalTextStatPropsType) => {
  const answersTextEditing: Array<string> = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.answersTextEditing
  );
  const isEditModeOff: boolean = !answersTextEditing.some(
    (el) => el === props.answerId
  );
  const [show, setShow] = useState<boolean>(false);
  const statistics: StatFunctionType = statFunction(props.gptResponse);

  return (
    <div className={s.localTextStat_wrapper}>
      <div className={s.show_hide_div}>
        {show ? (
          <div onClick={() => setShow(false)}>Скрыть статистику</div>
        ) : (
          <div onClick={() => setShow(true)}>Показать статистику</div>
        )}
      </div>

      {show && (
        <div className={s.stat_div}>
          <label>Длина текста (с пробелами): {statistics.totalLength}</label>
          <label>Длина текста (без пробелов): {statistics.signsCount}</label>
          <label>Количество слов: {statistics.wordsCount}</label>
          <label>Количество абзацев: {statistics.paragraphCount}</label>
        </div>
      )}
    </div>
  );
};

export default LocalTextStat;
