import { SVGProps, Ref, forwardRef, memo } from "react";
import { motion } from "framer-motion";
export const FoldIcon = motion(
  memo(
    forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <g id="svg-gobbler (1) 1">
          <path
            id="Vector"
            d="M3.66669 5.49999C3.66669 5.01376 3.85984 4.54744 4.20366 4.20363C4.54747 3.85981 5.01379 3.66666 5.50002 3.66666H16.5C16.9863 3.66666 17.4526 3.85981 17.7964 4.20363C18.1402 4.54744 18.3334 5.01376 18.3334 5.49999V16.5C18.3334 16.9862 18.1402 17.4525 17.7964 17.7964C17.4526 18.1402 16.9863 18.3333 16.5 18.3333H5.50002C5.01379 18.3333 4.54747 18.1402 4.20366 17.7964C3.85984 17.4525 3.66669 16.9862 3.66669 16.5V5.49999Z"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M8.25 3.66666V18.3333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M15 7L11 11L15 15"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ))
  )
);
