import { AxiosResponse } from "axios";
import { instance } from "../instance";

export const newsAPI = {
  getNews: (): Promise<AxiosResponse<ResponseType<NewsPayloadsType>>> => {
    return instance.get("news/listing/");
  },
  getOneNews: (
    news_id: string
  ): Promise<AxiosResponse<ResponseType<NewsType>>> => {
    return instance.get(`news/get/?news_id=${news_id}`);
  },
};

export type NewsPaginationType = {
  total: number;
  page: number;
  size: number;
  pages: number;
};

export type NewsType = {
  created_data?: string;
  description: string;
  id: string;
  image?: NewsImageType[];
  images?: NewsImageType[];
  link_address?: string;
  link_text?: string;
  title: string;
};

export type NewsImageType = {
  id: string;
  image: string;
  news_id?: string;
  position?: number;
};

export type NewsPayloadsType = {
  data: NewsType[];
  pagination: NewsPaginationType;
};

export type ResponseType<T> = {
  payloads: T;
  status: string;
};
