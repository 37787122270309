import { SVGProps, Ref, forwardRef, memo } from "react";
import { motion } from "framer-motion";
export const CatIcon2 = motion(
  memo(
    forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          {...props}
          ref={ref}
        >
          <path
            d="M22.3854 22.3849C23.2051 21.5651 23.2051 20.2345 22.3854 19.4148L13.178 10.2074C12.9842 10.0071 12.7524 9.84748 12.4962 9.73769C12.24 9.62791 11.9646 9.5702 11.6859 9.56794C11.4072 9.56568 11.1308 9.6189 10.8729 9.72451C10.615 9.83012 10.3806 9.98601 10.1836 10.1831C9.9865 10.3802 9.83061 10.6145 9.725 10.8724C9.61939 11.1303 9.56617 11.4067 9.56843 11.6854C9.57069 11.9641 9.6284 12.2396 9.73818 12.4957C9.84796 12.7519 10.0076 12.9837 10.2079 13.1775L19.4153 22.3849C20.235 23.2046 21.5656 23.2046 22.3854 22.3849ZM22.3854 22.3849L20.7462 20.7464"
            strokeWidth="1.5"
            strokeLinecap="round"
            fill="none"
          />
          <path
            d="M9.31634 1V4.56415M7.53427 2.78207H11.0984M2.78207 6.34622V9.91036M1 8.12829H4.56415"
            strokeWidth="1.5"
            strokeLinecap="round"
            fill="none"
          />
          <path
            d="M5.15832 18.2266V22.9788M2.78223 20.6027H7.53442M20.603 2.78198V7.53418M18.2269 5.15808H22.9791"
            strokeWidth="1.5"
            strokeLinecap="round"
            fill="none"
          />
        </svg>
      );
    })
  )
);
