import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  changeAvatar,
  changeUsername,
  fetchAvatarsList,
  fetchProfile,
} from "./profile-thunk";

import { ProfileType, UserType } from "./profile-type";
import { toast } from "react-toastify";
import { authLogoutThunk } from "../auth/authThunk";
import { DARK_THEME } from "../../storeConst/consts";

const initialState: ProfileType = {
  user: {} as UserType,
  profileImages: [],
};

const slice = createSlice({
  name: "profileSlice",
  initialState: initialState as ProfileType,
  reducers: {
    setRemainingUses(state, action: PayloadAction<number>) {
      state.user.access!.remainingUses =
        state.user.access!.remainingUses - action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvatarsList.fulfilled, (state, action) => {
        state.profileImages = action.payload;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        document.documentElement.setAttribute("data-theme", DARK_THEME);
      })
      .addCase(changeAvatar.fulfilled, (state, action) => {
        state.user.avatar_src = action.payload;
        toast.success("Аватар успешно изменен");
      })
      .addCase(changeUsername.fulfilled, (state, action) => {
        state.user.username = action.payload.username;
        toast.success("Имя успешно изменено");
      })
      .addCase(authLogoutThunk.fulfilled, (state) => {
        state.user = initialState.user;
      });
  },
});

export const profileActions = slice.actions;
export const profileReducer = slice.reducer;
