import { SVGProps, Ref, forwardRef, memo } from "react";
export const LogoIcon = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
      width="206"
      height="243"
      viewBox="0 0 206 243"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...ref}
    >
      <g clipPath="url(#clip0_58_252)">
        <path d="M17 72L0 62V107.5L17 118V72Z" fill="#B4E3B8" />
        <path d="M17 124V118L0 107.5V113.5L17 124Z" fill="#2F9753" />
        <path
          d="M17 171V124L0 113.5V180.5L97.5 239.5V220L17 171Z"
          fill="#8CD61D"
        />
        <path
          d="M103 223.5L97.5 220V239.5L103 242.5L206 181V157L188.5 146V171.5L103 223.5Z"
          fill="#014C47"
        />
        <path d="M188.5 123.5L206 113.5V157L188.5 146V123.5Z" fill="#8CD61D" />
        <path d="M206 113.5V107.5L188.5 117V123.5L206 113.5Z" fill="#2F9753" />
        <path
          d="M206 63V107.5L188.5 117V72.5L103 20.5L17.5 72.5L0 62L103 0L206 63Z"
          fill="#9EEAC3"
        />
        <path
          d="M32 96.5L49.5 107V91.5L103 59.5L156.5 92V106.5L173.5 96.5V80.5L103 38L32 81V96.5Z"
          fill="#8CD61D"
        />
        <path d="M49.5 107V118.5L32 108.5V96.5L49.5 107Z" fill="#54A000" />
        <path
          d="M32.5 162.5L32 108.5L49.5 118.5V153.5L103 186L156.5 154V120L173.5 109V163L103 205.5L32.5 162.5Z"
          fill="#014C47"
        />
        <path d="M173.5 109V96.5L156.5 106.5V120L173.5 109Z" fill="#54A000" />
        <path
          d="M80.5 136.5V109L102.5 96V77L64.5 100V145.5L102.5 168.5V150L80.5 136.5Z"
          fill="#8CD61D"
        />
        <path
          d="M125 109L102.5 96V77L141.5 100.5V145.5L102.5 168.5V150L125 137V109Z"
          fill="#8CD61D"
        />
      </g>
    </svg>
  ))
);
