import { SVGProps, Ref, forwardRef, memo } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    ref={ref}
    {...props}
  >
    <path d="M7.88267 7.31699C7.91985 7.35413 7.94935 7.39822 7.96948 7.44676C7.98961 7.4953 7.99998 7.54733 8 7.59988C8.00002 7.65243 7.98968 7.70447 7.96958 7.75302C7.94947 7.80157 7.92 7.84569 7.88285 7.88285C7.84569 7.92 7.80157 7.94947 7.75302 7.96958C7.70447 7.98968 7.65243 8.00002 7.59988 8C7.54733 7.99998 7.4953 7.98961 7.44676 7.96948C7.39822 7.94935 7.35413 7.91985 7.31699 7.88267L3.99982 4.5655L0.682653 7.88267C0.607623 7.9576 0.505909 7.99968 0.399867 7.99965C0.293826 7.99961 0.192138 7.95747 0.117155 7.88249C0.0421727 7.80751 3.3271e-05 7.70582 1.96932e-08 7.59978C-3.32316e-05 7.49374 0.0420425 7.39202 0.116978 7.31699L3.43415 3.99982L0.116978 0.682653C0.0420425 0.607623 -3.32316e-05 0.505909 1.96932e-08 0.399867C3.3271e-05 0.293826 0.0421727 0.192138 0.117155 0.117155C0.192138 0.0421727 0.293826 3.3271e-05 0.399867 1.96932e-08C0.505909 -3.32316e-05 0.607623 0.0420425 0.682653 0.116978L3.99982 3.43415L7.31699 0.116978C7.39202 0.0420425 7.49374 -3.32316e-05 7.59978 1.96932e-08C7.70582 3.3271e-05 7.80751 0.0421727 7.88249 0.117155C7.95747 0.192138 7.99961 0.293826 7.99965 0.399867C7.99968 0.505909 7.9576 0.607623 7.88267 0.682653L4.5655 3.99982L7.88267 7.31699Z" />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);

export default memo(ForwardRef);
