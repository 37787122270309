import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  categoriesAPI,
  questionsAPI,
  templatesAPI,
} from "../../common/api/api";
import { apiErrorCodes, standartApiErrorMessage } from "../../common/api/errorCodes";
import {
  ChangingOneQuestionType,
  OneOptionType,
  QuestionOptionType,
} from "./questionTypes";

export const getCategoriesThunk = createAsyncThunk(
  "auth/getCategoriesThunk",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = await categoriesAPI.getCategories();
      return res.data.categories;
    } catch (err: any) {}
  }
);

export type GetQuestionsRequest_V2_Type = { categoryId: string };

export type ModelsType = { model_id: string; type_title: string };
export type GetQuestionsResponse_V2_Type = {
  models: ModelsType[];
  payloads: {
    answer: string;
    id: string;
    is_required: boolean;
    options: Array<OneOptionType> | null;
    order_index: number;
    question: string;
    question_type: QuestionOptionType;
    snippet: string | null;
  }[];
};
export const getQuestionsThunk = createAsyncThunk(
  "auth/getQuestionsThunk",
  async (data: GetQuestionsRequest_V2_Type, { rejectWithValue, dispatch }) => {
    const res = await questionsAPI.getQuestions_v2(data.categoryId);
    try {
      dispatch(getTemplatesThunk(data.categoryId!));
      return res.data;
    } catch (err: any) {
      if (err.response.status === 400) {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      } else if (err.response.status === apiErrorCodes.AccessTokenInvalidCode) {
        await dispatch(getQuestionsThunk(data));
      } else {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      }
    }
  }
);

export type getTemplatesThunk_ResponseType = {
  id: string;
  title: string;
  data: {
    answers: Array<ChangingOneQuestionType>;
    category_id: string;
  };
};
export const getTemplatesThunk = createAsyncThunk(
  "auth/getTemplatesThunk",
  async (category_id: string, { rejectWithValue, dispatch }) => {
    try {
      const res = await templatesAPI.getTemplates(category_id);
      return res.data.payloads;
    } catch (err: any) {
      if (err.response.status === 400) {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      } else if (err.response.status === apiErrorCodes.AccessTokenInvalidCode) {
        await dispatch(getTemplatesThunk(category_id));
      } else {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      }
    }
  }
);

export type DeleteTemplateThunkRequestType = { templateId: string };
export const deleteTemplateThunk = createAsyncThunk(
  "auth/deleteTemplateThunk",
  async (
    data: DeleteTemplateThunkRequestType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await templatesAPI.deleteTemplate(data.templateId);
      return res.data.templates;
    } catch (err: any) {
      if (err.response.status === 400) {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      } else if (err.response.status === apiErrorCodes.AccessTokenInvalidCode) {
        await dispatch(deleteTemplateThunk(data));
      } else {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      }
    }
  }
);

export type PostAddTemplateThunkRequestType = {
  category_id: string;
  title: string;
  answers: Array<ChangingOneQuestionType>;
};
export const postAddTemplateThunk = createAsyncThunk(
  "auth/postAddTemplateThunk",
  async (
    data: PostAddTemplateThunkRequestType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await templatesAPI.postAddTemplate(data);
      return res.data;
    } catch (err: any) {
      if (err.response.status === 400) {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      } else if (err.response.status === apiErrorCodes.AccessTokenInvalidCode) {
        await dispatch(postAddTemplateThunk(data));
      } else {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      }
      return { status: "fail" };
    }
  }
);

