import {
  ChangeEvent,
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
  useState,
} from "react";

import { clsx } from "clsx";

import s from "./text-field.module.scss";
import { Typography } from "../typography/Typography";
import { TypographyVariant } from "../../store/storeConst/enums";
import VisibilityOff from "../../assets/icons/visibility-off";
import Eye from "../../assets/icons/eye";

export type TextFieldProps = {
  containerProps?: ComponentProps<"div">;
  errorMessage?: string;
  isDefaultValue?: boolean;
  isError?: boolean;
  label?: string;
  labelProps?: ComponentProps<"label">;
  onValueChange?: (value: string) => void;
} & ComponentPropsWithoutRef<"input">;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className,
      containerProps,
      errorMessage,
      isDefaultValue,
      isError = true,
      label,
      labelProps,
      onChange,
      onValueChange,
      placeholder,
      type = "text",
      ...restProps
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const isShowPasswordButtonShown = type === "password";

    const finalType = getFinalType(type, showPassword);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      onValueChange?.(e.target.value);
    };

    const cl = {
      error: clsx(s.error),
      field: clsx(s.field, !!errorMessage && s.error, className),
      fieldContainer: clsx(s.fieldContainer),
      isDefaultValue: clsx(isDefaultValue && s.isDefaultValue),
      label: clsx(s.label, labelProps?.className),
      password: clsx(type === "password" && s.password),
      root: clsx(s.root, className),
    };

    return (
      <div className={cl.root}>
        {label && (
          <Typography className={cl.label} variant={TypographyVariant.Body2}>
            {label}
          </Typography>
        )}
        <div className={cl.fieldContainer}>
          <input
            className={clsx(cl.field, cl.password, cl.isDefaultValue)}
            onChange={handleChange}
            placeholder={placeholder}
            ref={ref}
            type={finalType}
            {...restProps}
          />
          {isShowPasswordButtonShown && (
            <button
              className={s.showPassword}
              onClick={() => setShowPassword((prev) => !prev)}
              type={"button"}
            >
              {showPassword ? <VisibilityOff /> : <Eye />}
            </button>
          )}
        </div>
        {isError && (
          <Typography className={s.Error} variant={TypographyVariant.ERROR}>
            {errorMessage}
          </Typography>
        )}
      </div>
    );
  }
);

function getFinalType(
  type: ComponentProps<"input">["type"],
  showPassword: boolean
) {
  if (type === "password" && showPassword) {
    return "text";
  }

  return type;
}
