export type CurrentModelsIdsType = {
    seriousModelId_1: string,
    seriousModelId_2: string,
    creativeModelId: string,
    rusModelId: string,
}

export const currentModelsIds:CurrentModelsIdsType = {
    seriousModelId_1: "02a80bec-d589-4966-8f61-4ec3c30fbd9a",
    seriousModelId_2: "1aeb52d6-d8fb-4760-89a4-1157d931c531",
    creativeModelId: "cb7194e2-edf1-479b-bc10-ef5b4b40562e",
    rusModelId: "4218c187-0a0a-442c-bee0-e1c2dca5632b",
}