import { SVGProps, Ref, forwardRef, memo } from "react";
export const IconCat6 = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <g id="Group 108">
        <g id="top">
          <g id="Group 102">
            <path
              id="Ellipse 97"
              stroke="none"
              d="M48.4848 0C42.1177 -7.59272e-08 35.813 1.2541 29.9305 3.69069C24.048 6.12728 18.7031 9.69865 14.2009 14.2009C9.69865 18.7031 6.12728 24.0481 3.69069 29.9305C1.2541 35.813 -9.61408e-07 42.1177 0 48.4849L48.4848 48.4848V0Z"
              fill="url(#paint0_linear_622_12867)"
            />
            <path
              id="Ellipse 99"
              stroke="none"
              d="M48.4847 12.1212C43.7094 12.1212 38.9808 13.0618 34.569 14.8892C30.1571 16.7167 26.1484 19.3952 22.7718 22.7719C19.3951 26.1486 16.7166 30.1573 14.8891 34.5691C13.0617 38.9809 12.1211 43.7095 12.1211 48.4849L48.4847 48.4849V30.303V12.1212Z"
              fill="url(#paint1_linear_622_12867)"
            />
          </g>
          <path
            id="Ellipse 98"
            stroke="none"
            d="M51.5156 6.06061C57.0869 6.06061 62.6035 7.15794 67.7507 9.28996C72.8978 11.422 77.5746 14.5469 81.5141 18.4864C85.4536 22.4258 88.5785 27.1027 90.7105 32.2498C92.8425 37.3969 93.9399 42.9136 93.9399 48.4849L51.5156 48.4848L51.5156 6.06061Z"
            fill="url(#paint2_linear_622_12867)"
          />
        </g>
        <g id="Bottom">
          <g id="Group 102_2">
            <path
              id="Ellipse 97_2"
              stroke="none"
              d="M51.5152 100C57.8823 100 64.187 98.7459 70.0695 96.3093C75.952 93.8727 81.2969 90.3014 85.7991 85.7991C90.3014 81.2969 93.8727 75.9519 96.3093 70.0695C98.7459 64.187 100 57.8823 100 51.5151L51.5152 51.5152V100Z"
              fill="url(#paint3_linear_622_12867)"
            />
            <path
              id="Ellipse 99_2"
              stroke="none"
              d="M51.5153 87.8788C56.2906 87.8788 61.0192 86.9382 65.431 85.1108C69.8429 83.2833 73.8516 80.6048 77.2282 77.2281C80.6049 73.8514 83.2834 69.8427 85.1109 65.4309C86.9383 61.0191 87.8789 56.2905 87.8789 51.5151L51.5153 51.5151V69.697V87.8788Z"
              fill="url(#paint4_linear_622_12867)"
            />
          </g>
          <path
            id="Ellipse 98_2"
            stroke="none"
            d="M48.4844 93.9394C42.9131 93.9394 37.3965 92.8421 32.2493 90.71C27.1022 88.578 22.4254 85.4531 18.4859 81.5136C14.5464 77.5742 11.4215 72.8973 9.28948 67.7502C7.15746 62.6031 6.06013 57.0864 6.06013 51.5151L48.4844 51.5152L48.4844 93.9394Z"
            fill="url(#paint5_linear_622_12867)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_622_12867"
          x1="11.3636"
          y1="14.3939"
          x2="46.9697"
          y2="49.2424"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_622_12867"
          x1="24.2423"
          y1="21.2121"
          x2="47.7272"
          y2="49.2424"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_622_12867"
          x1="83.3338"
          y1="18.9394"
          x2="51.5156"
          y2="48.4849"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_622_12867"
          x1="88.6364"
          y1="85.6061"
          x2="53.0303"
          y2="50.7576"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_622_12867"
          x1="75.7577"
          y1="78.7879"
          x2="52.2728"
          y2="50.7576"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_622_12867"
          x1="16.6662"
          y1="81.0606"
          x2="48.4844"
          y2="51.5151"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  ))
);
