export const sideMenuYandexId = (id) => {
  if (id === "69a3fd45-d269-4290-8cf4-4e095fb390da")
    return "Click_sideMenu_Znakomstvo_s_neironkami";
  if (id === "4689f7cc-9c02-46a8-bfbb-fa5251e48aef")
    return "Click_Znakomstvo_Obschalka";
  if (id === "0b327286-8902-1d42-1bc2-4e02844029c8")
    return "Click_Znakomstvo_PodborPodarkaNaNoviyGod";
  if (id === "9cd48984-725c-4dba-b394-b8cc24c29293")
    return "Click_Znakomstvo_Luchi_Poddergki";
  if (id === "2368b2e2-5230-4429-8a33-bb47db9ee303")
    return "Click_Znakomstvo_Gramotniy_Otezd";
  if (id === "bf5b1488-72dd-12d0-ee43-6fed1148470a")
    return "Click_Znakomstvo_Obosnovanie_Povysheniya";

  if (id === "3ed0a5a6-388b-d067-f281-7832ddbf37b4")
    return "TT_14_Poleznye_Instrumenty_click";
  if (id === "b972c9fe-95e6-495a-e35a-3a4c6ae3aba1")
    return "TT_15_Poleznye_Instrumenty_page_Corrector_click";
  if (id === "5767657f-30e8-4560-8d75-a0d586e638f0")
    return "TT_16_Poleznye_Instrumenty_page_Perevodchik_click";
  if (id === "e3204fba-b962-4461-96d8-2fce23c40db4")
    return "TT_17_Poleznye_Instrumenty_page_Adekvatizator_click";
  if (id === "cf7b450b-0f78-a9ab-a846-a8b9700dfbf8")
    return "TT_18_Poleznye_Instrumenty_page_Sutevik_click";
  if (id === "6c94f4c1-c38b-8199-a2dd-8d4e939110ee")
    return "TT_19_Poleznye_Instrumenty_page_Sokraschatel_click";
  if (id === "d441297b-3d83-1201-dfae-f82a2b71f836")
    return "TT_20_Poleznye_Instrumenty_page_PR_sovetnik_click";
  if (id === "88f5bf05-b3a6-3f20-5a37-ee6f7b446975")
    return "TT_21_Poleznye_Instrumenty_page_Pismo_Ob_Unikalnosti_Postavschika_click";
  if (id === "8739e394-76e8-1c22-adc9-19accec34ac9")
    return "Click_Poleznye_Instrumenty_Pozdravleniya_S_novim_godom_ot_rukovodstva";
  if (id === "690a8451-d001-43f4-07f4-aa74098f4ec1")
    return "Click_Poleznye_Instrumenty_Blagodarstvennoe_ili_Recomendatelnoe_Pismo";

  if (id === "9021c0e4-faba-4865-994b-05917d113df3")
    return "TT_22_Rabota_so_SMI_click";
  if (id === "9ee5b199-12b1-48b5-a0bd-aad8c8994120")
    return "TT_23_Rabota_so_SMI_page_Press_Relizi_click";
  if (id === "bb254361-a1b5-4d7b-af1c-857b4e91f284")
    return "TT_24_Rabota_so_SMI_page_Commentarii_click";
  if (id === "89aa86d8-f4cc-f1d5-841b-222427057233")
    return "Click_Rabota_so_SMI_Novost_Na_Sait";
  if (id === "72bc5f78-0920-a5ad-3e2c-b0dee21e8c1b")
    return "Click_Rabota_so_SMI_Podvedenie_Itogov_goda";
  if (id === "9464e308-acc7-4cad-9744-a229c7174e6e")
    return "TT_25_Rabota_so_SMI_page_Antikrizis_Commentarii_click";
  if (id === "79256b49-eaa2-4fce-882d-1ef3bb88dd3d")
    return "TT_26_Rabota_so_SMI_page_Statiy_click";
  if (id === "cd59fb36-f448-431b-a1d4-e4d89f3667b0")
    return "TT_27_Rabota_so_SMI_page_Plany_statei_click";
  if (id === "2664303b-abeb-43b8-92bc-796155d9771d")
    return "TT_28_Rabota_so_SMI_page_Reraits_click";
  if (id === "c1837e5a-c584-4ef2-bfe9-d431a2857650")
    return "TT_29_Rabota_so_SMI_page_Vneh_Rechi_Dlya_Rukovodstva_click";

  if (id === "e1d7679f-ad28-4df7-87ba-a0f40eaf1267")
    return "TT_30_SMM_page_click";
  if (id === "f9a0f898-6d13-4637-9e40-a9148509d2a7")
    return "TT_31_SMM_page_Telegram_click";
  if (id === "ae6c6c36-301f-4868-aa5a-ca4770f75c24")
    return "TT_32_SMM_page_Facebook_click";
  if (id === "47303353-9249-4e6f-9cb9-ecca46c7882f")
    return "TT_33_SMM_page_VK_click";
  if (id === "4261f8db-90bd-4fd8-b485-0d454fd03850")
    return "TT_34_SMM_page_Instagram_click";
  if (id === "1aa0c6b5-3ef6-49c6-955c-cb8f4318e4a2")
    return "TT_35_SMM_page_Templan_click";
  if (id === "c5baa293-bb9b-42fa-93e3-70d7d04f51e3")
    return "TT_36_SMM_page_Otzyvy_click";
  if (id === "5106fc71-a880-ab0d-5523-2bbfbd49533a")
    return "TT_37_SMM_page_Partizanskiy_marketing_click";

  if (id === "68b3ff50-f5a8-4b87-9a96-2a92a0e7f41d")
    return "TT_38_VKIK_page_click";
  if (id === "b2045070-0a3c-491e-8aef-8c67217c3c16")
    return "TT_39_VKIK_page_vnutr_rassilki_click";
  if (id === "88129fe1-0cf6-4444-a2db-9df8b32b53f7")
    return "TT_40_VKIK_page_Vnutr_antikrizis_click";
  if (id === "b9948285-e04c-43a4-8aeb-d35264072ab8")
    return "TT_41_VKIK_page_Rechi_dlya_rukovodstva_click";
  if (id === "f10ee27a-163b-4b02-b422-c40686d3ad5b")
    return "TT_42_VKIK_page_Strategii_VK_click";
  if (id === "d2b3cc95-597a-4901-ace0-c2dba0a44fd1")
    return "TT_43_VKIK_page_Podarki_Idei_click";

  if (id === "2d8c2b7c-7f4b-4971-bf47-7dbcc70789dd")
    return "TT_44_Events_page_click";
  if (id === "6e2e3dc6-ab44-400c-8609-af227e2e0c7b")
    return "TT_45_Events_page_Proglasheniya_click";
  if (id === "7ffedc61-0173-438a-a422-628ace1029ef")
    return "TT_46_Events_page_POS_broshury_click";
  if (id === "5a3034e6-5637-4052-80d5-5c4964498d3d")
    return "TT_47_Events_page_Strategii_eventov_click";
  if (id === "ce90da0b-ab50-4347-8dc8-38751edb93a2")
    return "TT_48_Events_page_Teksty_veduschih_click";

  return "";
};
export const yandexMetrikaSideMenuClickEvent = (id) => {
  const counterGoal = sideMenuYandexId(id);
  if (counterGoal.length > 0) {
    window.ym(94709670, "reachGoal", counterGoal);
  }
};

export const yandexMetrikaClickEvent = (goal) => {
  window.ym(94709670, "reachGoal", goal);
};

export const tariffFromIdToGoal = (id) => {
  if (id === "4a836865-8518-ba10-a38b-da5287599b80")
    return "TT_4_tariff_xs_click";
  if (id === "f30c35a3-298b-fdb7-9db6-44e8bac4b74c")
    return "TT_5_tariff_75000_click";
  if (id === "6c0bab51-32e9-f513-94ef-d62218df00b0")
    return "TT_6_tariff_s_click";
  if (id === "9225f64e-f08b-c7f6-633f-b142b635e43c")
    return "TT_7_tariff_m_click";
  if (id === "d0386d46-4087-b289-1013-4a4208686d00")
    return "TT_8_tariff_l_click";
  if (id === "15aa1cb4-6b69-f999-dfdb-2a6bc6a0790f")
    return "TT_9_tariff_xl_click";
  if (id === "3f87fd22-4f24-195d-de93-a05e7efba382")
    return "TT_10_tariff_xxl_click";
  return "";
};
export const yandexMetrikaPayTariffClickEvent = (id) => {
  const goal = tariffFromIdToGoal(id);
  if (goal.length > 0) {
    window.ym(94709670, "reachGoal", goal);
  }
};
