export type StatFunctionType = {
    totalLength: number,
    paragraphCount: number,
    wordsCount: number
    signsCount: number
}

export const statFunction = (text: string): StatFunctionType => {

    const totalLength: number = text.replaceAll('\n', '').length;

    const paragraphCount: number = text
        .split('\n')
        .filter(p => p.length > 0)
        .length

    const wordsCount: number = text
        .replaceAll('\n', '')
        .split(' ')
        .filter(w => w.length > 0)
        .length

    const signsCount: number = text
        .replaceAll(' ', '')
        .length

    return {totalLength, paragraphCount, wordsCount, signsCount}
}