import { ChoosingPhotoPage } from "../../page/setting/profile-page/choosing-photo-page/choosing-photo-page";

export const Paths = {
  mainPage: "/",
  setting: "/setting",
  profile: "/setting/profile",
  choosingPhoto: "/setting/choosing-photo",
  changePassword: "/setting/change-password",
  auth: "/auth",
  registration: "/registration",
  passwordRecovery: "/passwordRecovery",
  // sideBlockRelease: 'sideBlockRelease',
  // sideBlockComments: 'sideBlockComments',
  // sideBlockCrisis: 'sideBlockCrisis',
  // sideBlockAdequtizator: 'sideBlockAdequtizator',
  // sideBlockHelpBeams: 'sideBlockHelpBeams',
  category: "/category",
  gptHistory: "/responseHistory",
  tariffs: "/tariffs",
  serverIsAvailable: "/server-is-available",
};
