import s from "./politics.module.scss";
import { ControlledCheckbox } from "../../../components/checkbox/controlled-checkbox";
import React from "react";
import { Control } from "react-hook-form";
import clsx from "clsx";

type PoliticsPropsType = {
  control: Control<
    { agreeWithPolitical: boolean; username: string; password: string },
    any
  >;
  error?: boolean;
};

const Politics = ({ control, error }: PoliticsPropsType) => {
  return (
    <div className={clsx(s.politicsWrapper, error && s.error)}>
      Нажимая кнопку "Войти",
      <div className={s.container}>
        <ControlledCheckbox
          className={s.checkBox}
          control={control}
          name={"agreeWithPolitical"}
        />
        <div>
          <label>Я даю согласие на&nbsp;</label>
          <a
            href="https://aipr.pro/politics/PD_agreement.pdf"
            target="_blank"
            rel="noreferrer"
          >
            обработку своих персональных данных
          </a>
          <label>, а также принимаю условия&nbsp;</label>
          <a
            href="https://aipr.pro/politics/PD_politics.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Политики обработки персональных данных
          </a>
        </div>
      </div>
      {error && (
        <div className={s.politicsErrorDiv}>
          Для входа в портал Вам необходимо согласиться со всеми условиями
        </div>
      )}
    </div>
  );
};

export default Politics;
