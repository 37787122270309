import s from "./setting-page.module.scss";
import TitleLine from "./titleLine";
import { BottomSection } from "./bottom-section/bottomSection";
import { Outlet, useNavigate } from "react-router-dom";

import { Paths } from "../../common/router/paths";
import React, { useEffect } from "react";
import { fetchAvatarsList } from "../../store/features/profile/profile-thunk";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Typography } from "../../components/typography/Typography";
import { TypographyVariant } from "../../store/storeConst/enums";
import { Button } from "../../components/button/button";
import CheckPromoCode from "../tariffs/checkPromoCode/checkPromoCode";
import { avatarsList } from "../../store/features/profile/profile-selectors";
import { yandexMetrikaClickEvent } from "../../common/utils/yadexMetrikaCounter";
import { appSelectors } from "../../store/features/app/app-selectors";
import { appActions } from "../../store/features/app/app-slice";

const SettingPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const avatars = useAppSelector(avatarsList());
  const { tourActive } = useAppSelector(appSelectors.getOnboarding());

  useEffect(() => {
    avatars.length === 0 && dispatch(fetchAvatarsList());
    tourActive // Запускаем онбординг если он запущен при переходе на страницу.
      ? dispatch(appActions.setOnboarding({ run: true, stepIndex: 3 }))
      : dispatch(appActions.setOnboarding({ run: false, stepIndex: 0 }));
  }, []);

  const onProductClickHandler = () => {
    yandexMetrikaClickEvent("TT_3_click_tariff_in_profile");
    navigate(Paths.tariffs);
  };

  return (
    <div className={s.profileWrapper}>
      <TitleLine />
      <div className={s.profileMainAreaWrapper}>
        <div className={s.profileMainAreaLeft}>
          <Typography variant={TypographyVariant.H2} as={"h2"}>
            Профиль:
          </Typography>
          <div className={s.container}>
            <Outlet />
          </div>
        </div>
        <div className={s.profileMainAreaRight}>
          <Typography variant={TypographyVariant.H2} as={"h2"}>
            Тарифы и оплата:
          </Typography>
          <div className={s.oneSmallSection}>
            Современные нейросети настолько умны, что не только генерируют
            классный контент, но еще и требуют за это денег 🙈 Поэтому для
            доступа к генерациям мы тоже просим вас выбрать и оплатить удобный
            тарифный план!
            <div className={s.bottom}>
              <CheckPromoCode />
              <Button
                id={"tariffsButton"}
                onClick={onProductClickHandler}
                fullWidth={true}
                className={s.buttonRates}
              >
                Тарифы
              </Button>
            </div>
          </div>
        </div>
        <div className={s.profileMainAreaBottom}>
          <Typography variant={TypographyVariant.H2} as={"h2"}>
            Информация о подписке:
          </Typography>
          <BottomSection />
        </div>
      </div>
    </div>
  );
};

export default SettingPage;
