import { SVGProps, Ref, forwardRef, memo } from "react";
import { motion } from "framer-motion";
export const CatIcon1 = motion(
  memo(
    forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
      return (
        <svg width="24" height="23" viewBox="0 0 24 23" {...props} ref={ref}>
          <g id="&#240;&#159;&#166;&#134; icon &#34;sparks&#34;">
            <path
              fill="none"
              id="Vector"
              d="M4.07963 1V7.15926M1 4.07963H7.15927M4.69556 16.3982V21.3256M2.23185 18.8619H7.15927M11.4806 8.73973C8.87154 9.48623 7.56701 9.85825 7.37854 10.5173C7.31568 10.7379 7.31568 10.9717 7.37854 11.1924C7.56701 11.8514 8.87154 12.2234 11.4806 12.9699C12.0041 13.119 12.2653 13.1941 12.4636 13.3567C12.535 13.4158 12.5994 13.4827 12.6558 13.5563C12.8122 13.7595 12.8788 14.0244 13.0106 14.5528C13.7682 17.5807 14.1463 19.0947 14.846 19.2832C15.0554 19.3398 15.2772 19.3398 15.4866 19.2832C16.1863 19.0959 16.5645 17.5807 17.3221 14.5528C17.4539 14.0231 17.5192 13.7595 17.6768 13.555C17.7333 13.4824 17.7978 13.4163 17.869 13.3579C18.0661 13.1941 18.3285 13.119 18.8508 12.9699C21.4623 12.2234 22.7656 11.8514 22.9529 11.1924C23.0157 10.9717 23.0157 10.7379 22.9529 10.5173C22.7656 9.85825 21.4623 9.48623 18.8508 8.73973C18.3285 8.59068 18.0673 8.51553 17.869 8.35293C17.7976 8.29385 17.7332 8.2269 17.6768 8.15337C17.5192 7.95011 17.4539 7.68527 17.3221 7.1568C16.5645 4.12891 16.1863 2.61373 15.4866 2.42649C15.2768 2.37 15.0558 2.37 14.846 2.42649C14.1463 2.61373 13.7682 4.12891 13.0106 7.1568C12.8788 7.6865 12.8135 7.95011 12.6558 8.1546C12.5993 8.22727 12.5348 8.29338 12.4636 8.3517C12.2665 8.51553 12.0029 8.59068 11.4806 8.73973Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      );
    })
  )
);
