import dayjs from "dayjs";
import s from "./dateField.module.css";
import { COMMON_INPUT_DATE_FORMAT } from "../../store/storeConst/consts";
import { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";

type ValuePiece = Date | null;

type DateFieldPropsType = {
  value: string | null;
  onChangeFunction: (newDate: string | null) => void;
  calClass?: string;
};

const DateField = (props: DateFieldPropsType) => {
  const [calValue, setCalValue] = useState<string | null>(null);

  useEffect(() => {
    if (props.value) {
      setCalValue(dayjs(props.value).format("YYYY-MM-DD"));
    } else {
      setCalValue(null);
    }
  }, [props.value]);

  const onChangeHandler = (date: ValuePiece) => {
    if (date) {
      const dateStr: string = dayjs(
        new Date(date.getFullYear(), date.getMonth(), date.getDate())
      ).format(COMMON_INPUT_DATE_FORMAT);
      props.onChangeFunction(dateStr);
    } else {
      props.onChangeFunction(null);
    }
  };

  return (
    <DateTimePicker
      format="dd.MM.y"
      disableClock={true}
      onChange={(newValue) => onChangeHandler(newValue)}
      value={calValue}
      className={props.calClass ? props.calClass : s.date_class}
    />
  );
};

export default DateField;
