import { useNavigate } from "react-router-dom";
import { CategoryType } from "../../store/features/questionTypes";
import s from "./categorySections.module.scss";
import { Paths } from "../../common/router/paths";
import { Button } from "../button/button";
import { TypographyVariant } from "../../store/storeConst/enums";
import { Typography } from "../typography/Typography";
import { yandexMetrikaSideMenuClickEvent } from "../../common/utils/yadexMetrikaCounter";

type CategoriesSectionsPropsType = {
  categories: Array<CategoryType>;
  images: JSX.Element[];
};
const CategoriesSections = (props: CategoriesSectionsPropsType) => {
  const navigate = useNavigate();

  return (
    <div className={s.Wrapper}>
      {props.categories.map((cat: CategoryType, index: number) => {
        const srcImageIndex: number = index > 5 ? Math.round(index / 5) : index;

        const onButtonStartClickHandler = () => {
          yandexMetrikaSideMenuClickEvent(cat.id);
          navigate(Paths.category + "/" + cat.id);
        };

        return (
          <div key={cat.id} className={s.Container}>
            <div className={s.Title}>
              {props.images[srcImageIndex]}
              <Typography variant={TypographyVariant.H2}>
                {cat.title}
              </Typography>
            </div>
            <Typography>{cat.description}</Typography>
            <Button onClick={onButtonStartClickHandler} className={s.Button}>
              Начать
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default CategoriesSections;
