import { SVGProps, Ref, forwardRef, memo } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg ref={ref} {...props} viewBox="0 0 22 22">
    <path
      fill="none"
      d="M13.31 7L19.05 16.94M8.69 7H20.17M6.38 11L12.12 1.06M8.69 15L2.95 5.06M13.31 15H1.83M15.62 11L9.88 20.94M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);

export default memo(ForwardRef);
