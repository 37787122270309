export const ROLES = {
  USER: "user",
  ADMIN: "admin",
};

export const USERNAME_OPTION = "USERNAME_OPTION";

export const DARK_THEME = "dark";
export const LIGHT_THEME = "light";

export const ANSWER_EDIT_DELAY = 2000;

export const QUESTION_OPTION_TYPE = {
  text: "text",
  options: "options",
  numeric: "numeric",
  semiOptional: "semi-options",
};

export const COMMON_DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm";
export const COMMON_DATE_FORMAT = "DD.MM.YYYY";
export const COMMON_INPUT_DATE_FORMAT = "YYYY-MM-DD";

export const RESPONSE_WAITING_DIV_ID = "response_waiting_div_id";

export const HISTORY_SEARCH_FAV_VALUES = {
  all: "ALL",
  fav: "FAVORITES",
  notFav: "NOT_FAVORITES",
};
