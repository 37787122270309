import s from "./template-page.module.scss";

import { ButtonVariant } from "../../store/storeConst/enums";
import { Link } from "react-router-dom";
import { Typography } from "../../components/typography/Typography";
import { Button } from "../../components/button/button";

type TemplatePagePropsType = {
  img: string;
  text: string;
  buttonText: string;
  buttonPath: string;
};

export const TemplatePage = ({
  img,
  text,
  buttonText,
  buttonPath,
}: TemplatePagePropsType) => {
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <img src={img} alt="" />
        <Typography>{text}</Typography>
        <Button as={Link} to={buttonPath} variant={ButtonVariant.Tertiary}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
