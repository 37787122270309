import {
  ComponentPropsWithoutRef,
  FC,
  forwardRef,
  ReactNode,
  Ref,
} from "react";

import * as ScrollArea from "@radix-ui/react-scroll-area";
import { clsx } from "clsx";

import s from "./scrollbar.module.scss";
import { ScrollAreaViewportElement } from "@radix-ui/react-scroll-area";

export type ScrollbarProps = {
  children: ReactNode;
  className?: string;
  /** maxHeight viewport in pixels */
  maxHeight?: number | string;
  /** maxWidth viewport in pixels */
  maxWidth?: number | string;
  ref?: Ref<ScrollAreaViewportElement>;
  type?: ScrollArea.ScrollAreaProps["type"];
} & ComponentPropsWithoutRef<"div">;

export const Scrollbar: FC<ScrollbarProps> = forwardRef(
  (
    {
      children,
      className,
      maxHeight = "100%",
      maxWidth = "100%",
      type = "auto",
      ...rest
    },
    ref: Ref<ScrollAreaViewportElement>
  ) => {
    const classNames = {
      root: clsx(s.root, className),
      scrollbar: s.scrollbar,
      thumb: s.thumb,
      viewport: s.viewport,
    };

    const maxHeightConverted =
      typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight;
    const maxWidthConverted =
      typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth;

    const viewportStyles = {
      maxHeight: maxHeightConverted,
      width: maxWidthConverted,
    };

    return (
      <ScrollArea.Root asChild type={type} scrollHideDelay={1000}>
        <div className={classNames.root} {...rest}>
          <ScrollArea.Viewport
            ref={ref}
            className={classNames.viewport}
            style={viewportStyles}
          >
            {children}
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className={classNames.scrollbar}
            orientation={"vertical"}
          >
            <ScrollArea.Thumb className={classNames.thumb} />
          </ScrollArea.Scrollbar>
          <ScrollArea.Scrollbar
            className={classNames.scrollbar}
            orientation={"horizontal"}
          >
            <ScrollArea.Thumb className={classNames.thumb} />
          </ScrollArea.Scrollbar>
        </div>
      </ScrollArea.Root>
    );
  }
);
