import s from './radioButtons.module.css';

export type OneRadioOption = {
    value: string,
    text: string
}
type RadioButtonsPropsType = {
    options: Array<OneRadioOption>,
    checkedValue:  string | null,
    onChangeFunction: (newValue: string) => void
}

const RadioButtons = (props: RadioButtonsPropsType) => {

    const onChangeClickHandler = (newValue: string) => {
        props.onChangeFunction(newValue)
    }
    
    return (
        <div className={s.radio_btns_wrapper}>
            {
                props.options.map((opt, index) => {
                    const isChecked: boolean = opt.value === props.checkedValue;
                    const inputClass: string = isChecked 
                        ? s.one_radio_input + " " + s.checked 
                        : s.one_radio_input

                    return (
                        <div className={s.one_radio_div} key={opt.value + "_" + index}>
                            <input 
                                type='radio' 
                                checked={isChecked} 
                                className={inputClass}
                                onChange={() => onChangeClickHandler(opt.value)}
                            />
                            <label>{opt.text}</label>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default RadioButtons;