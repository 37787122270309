import axios, { AxiosError } from "axios";
import { AppDispatchType } from "../../store/store";

export const handleServerNetworkError = (
  e: unknown,
  dispatch: AppDispatchType
) => {
  const err = e as Error | AxiosError<{ message: string }>;
  if (axios.isAxiosError(err)) {
    const errorMessage = err?.response?.status === 409 ? "Имя уже занято" : "";
    return errorMessage;
  }
};
