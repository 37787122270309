import { RootState } from "../../store";

const isLoadingApp = () => (state: RootState) => state.app.isLoading;
const isOpenSideBar = () => (state: RootState) => state.app.isOpenSideBar;
const getOnboarding = () => (state: RootState) => state.app.onboarding;
const openedCategories = () => (state: RootState) => state.app.openedCategories;

const getPromo = () => (state: RootState) => state.app.promoCode;

export const appSelectors = {
  isLoadingApp,
  isOpenSideBar,
  getOnboarding,
  openedCategories,
  getPromo,
};
