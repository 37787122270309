import { useNavigate } from "react-router-dom";
import s from "./backButton.module.css";
import backIcon from "../../../assets/icons/contentPage/back_icon.png";

type BackButtonProsType = {
  path: string;
};

const CategoryBackButton = (props: BackButtonProsType) => {
  const navigate = useNavigate();
  const onBackButtonClickHandler = () => navigate(props.path);

  return (
    <div className={s.backButton_wrapper} onClick={onBackButtonClickHandler}>
      <img alt="" src={backIcon} />
      <label>Назад</label>
    </div>
  );
};

export default CategoryBackButton;
