const up = {
  initial: { opacity: 0, y: 200 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -200 },
};
const down = {
  initial: { opacity: 0, y: -200 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 200 },
};

export const spring = {};

export const animation = { up, down };
