import { useSelector } from "react-redux";
import s from "./bottomSection.module.css";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { CategoryType } from "../../../store/features/questionTypes";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../common/router/paths";
import dayjs from "dayjs";
import { COMMON_DATE_FORMAT } from "../../../store/storeConst/consts";
import { Button } from "../../../components/button/button";
import { userAccess } from "../../../store/features/profile/profile-selectors";
import { Typography } from "../../../components/typography/Typography";
import { BuyAdditionsProduct } from "./buyAdditions/buyAdditions";
import { yandexMetrikaClickEvent } from "../../../common/utils/yadexMetrikaCounter";
import { appActions } from "../../../store/features/app/app-slice";

export const BottomSection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accessInfo = useAppSelector(userAccess());

  const categories: Array<CategoryType> = useSelector(
    (state: RootState) => state.question.categories
  );

  const myCategories: string[] | null =
    accessInfo && accessInfo.categoryIds
      ? accessInfo!.categoryIds &&
        categories
          .filter((cat) => accessInfo!.categoryIds.some((el) => el === cat.id))
          .map((cat) => cat.title)
      : [];

  const expDate: string =
    accessInfo && accessInfo.expirationTime
      ? accessInfo!.expirationTime
        ? dayjs(accessInfo!.expirationTime).isValid()
          ? dayjs(accessInfo!.expirationTime).format(COMMON_DATE_FORMAT)
          : ""
        : ""
      : "";

  const remainingCount: number =
    accessInfo && accessInfo.remainingUses
      ? accessInfo!.remainingUses
        ? accessInfo!.remainingUses
        : -1
      : -1;

  const onBuyOtherProductClickHandler = () => {
    yandexMetrikaClickEvent("TT_13_profile_buy_another_tariff_click");
    navigate(Paths.tariffs);
  };
  const onBuyAdditionalProdClickHandler = () => {
    yandexMetrikaClickEvent("TT_12_profile_buy_additional_symbols_click");
    dispatch(appActions.changeShowAdditionalProduct(true));
  };

  return (
    <div className={s.bottomWrapper}>
      {accessInfo ? (
        <>
          <BuyAdditionsProduct />
          <div className={s.oneField_class}>
            <label>Количество оставшихся дней:</label>
            {expDate}
          </div>
          <div className={s.oneField_class}>
            <label>Количество оставшихся символов:</label>
            {remainingCount}
          </div>
          <div>
            <label>Доступные разделы: </label>
            <ul>
              {myCategories.map((title: string, index: number) => {
                return <li key={index}>{title}</li>;
              })}
            </ul>
          </div>
          <div className={s.buttons}>
            <Button
              onClick={onBuyAdditionalProdClickHandler}
              className={s.button}
            >
              Приобрести доп. символы
            </Button>
            <Button
              onClick={onBuyOtherProductClickHandler}
              className={s.button}
            >
              Купить другой тариф
            </Button>
          </div>{" "}
        </>
      ) : (
        <Typography className={s.noSubscriptions}>
          У вас пока нет подписок :(
        </Typography>
      )}
    </div>
  );
};
