import { SVGProps, Ref, forwardRef, memo } from "react";
export const IconCat4 = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <g id="Union">
        <path
          stroke="none"
          d="M48.0263 21.0526V0H51.9737V21.0526H48.0263Z"
          fill="url(#paint0_linear_622_10666)"
        />
        <path
          stroke="none"
          d="M45.2352 48.0263L31.8571 34.6482L34.6483 31.857L48.0263 45.235V26.3158H51.9737V45.235L65.3517 31.857L68.1429 34.6482L54.7648 48.0263H73.6842V51.9737H54.765L68.1429 65.3516L65.3517 68.1428L51.9737 54.7648V73.6842H48.0263V54.7648L34.6483 68.1428L31.8571 65.3516L45.235 51.9737H26.3158V48.0263H45.2352Z"
          fill="url(#paint1_linear_622_10666)"
        />
        <path
          stroke="none"
          d="M0 48.0263H21.0526V51.9737H0V48.0263Z"
          fill="url(#paint2_linear_622_10666)"
        />
        <path
          stroke="none"
          d="M78.9474 48.0263H100V51.9737H78.9474V48.0263Z"
          fill="url(#paint3_linear_622_10666)"
        />
        <path
          stroke="none"
          d="M48.0263 78.9474V100H51.9737V78.9474H48.0263Z"
          fill="url(#paint4_linear_622_10666)"
        />
        <path
          stroke="none"
          d="M28.1355 69.0732L13.249 83.9596L16.0403 86.7509L30.9267 71.8644L28.1355 69.0732Z"
          fill="url(#paint5_linear_622_10666)"
        />
        <path
          stroke="none"
          d="M69.0733 28.1354L83.9597 13.249L86.7509 16.0402L71.8645 30.9266L69.0733 28.1354Z"
          fill="url(#paint6_linear_622_10666)"
        />
        <path
          stroke="none"
          d="M71.8645 69.0732L86.751 83.9596L83.9597 86.7509L69.0733 71.8644L71.8645 69.0732Z"
          fill="url(#paint7_linear_622_10666)"
        />
        <path
          stroke="none"
          d="M30.9267 28.1354L16.0403 13.249L13.2491 16.0402L28.1355 30.9266L30.9267 28.1354Z"
          fill="url(#paint8_linear_622_10666)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_622_10666"
          x1="17.7632"
          y1="9.21053"
          x2="71.0526"
          y2="100.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.06" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_622_10666"
          x1="17.7632"
          y1="9.21053"
          x2="71.0526"
          y2="100.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.06" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_622_10666"
          x1="17.7632"
          y1="9.21053"
          x2="71.0526"
          y2="100.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.06" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_622_10666"
          x1="17.7632"
          y1="9.21053"
          x2="71.0526"
          y2="100.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.06" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_622_10666"
          x1="17.7632"
          y1="9.21053"
          x2="71.0526"
          y2="100.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.06" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_622_10666"
          x1="17.7632"
          y1="9.21053"
          x2="71.0526"
          y2="100.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.06" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_622_10666"
          x1="17.7632"
          y1="9.21053"
          x2="71.0526"
          y2="100.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.06" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_622_10666"
          x1="17.7632"
          y1="9.21053"
          x2="71.0526"
          y2="100.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.06" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_622_10666"
          x1="17.7632"
          y1="9.21053"
          x2="71.0526"
          y2="100.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.06" />
        </linearGradient>
      </defs>
    </svg>
  ))
);
