import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { productAPI } from "../../common/api/api";
import { apiErrorCodes, standartApiErrorMessage } from "../../common/api/errorCodes";
import { CategorySelectionType, ProductType } from "./productTypes";
import { fetchProfile } from "./profile/profile-thunk";
import { createAppAsyncThunk } from "../createAppAsyncThunk";
import { appActions } from "./app/app-slice";


export type GetProductsThunkResType = {
  message: string,
  data: Array<ProductType>,
  categories: Array<CategorySelectionType>,
}
export const getProductsThunk = createAppAsyncThunk<GetProductsThunkResType>(
  "product/getProductsThunk",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = await productAPI.getProducts();
      return res.data;
    } catch (err: any) {
      if (err.response.status === 400) {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      } else if (err.response.status === apiErrorCodes.AccessTokenInvalidCode) {
        await dispatch(getProductsThunk());
      } else {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      }
      return rejectWithValue(null);
    }
  }
);

export type CheckPromoRequestType = {
  promoCode: string;
  navigate: any;
  products: Array<ProductType>;
};
export type CheckPromoResponseType = {
  status: "ok" | "fail";
  message?: string;
  product_id?: string;
  new_price?: number;
};
export const checkPromoThunk = createAppAsyncThunk(
  "product/checkPromoThunk",
  async (requestData: CheckPromoRequestType, { dispatch, getState }) => {
    try {
      const promoSale = await productAPI.postPromo(requestData.promoCode);
      const productList = await productAPI.getProducts();

      if (promoSale.data.status === "fail") {
        toast.error("Простите, такой промокод не найден.");
        throw new Error("Ошибка");
      }
      const discountProduct: ProductType | undefined =
        requestData.products.find(
          (prod) => prod.id === promoSale.data.product_id
        );

      if (promoSale.data.message === "Free promo activate") {
        getState().app.onboarding.tourActive && // Для онбординга (после активации промокода переходим на следующий шаг)
          dispatch(appActions.setOnboarding({ run: true, stepIndex: 4 }));
        toast.success("Поздравляем! Ваш промокод успешно активирован!");
        dispatch(fetchProfile());
        dispatch(appActions.setPromo(""));

        // requestData.navigate(Paths.profile);
      } else {
        toast.success(
          "Поздравляем! Промокод дал вам скидку на тариф " +
            discountProduct?.title +
            "!"
        );
      }

      return productList.data.data.map((product) => {
        if (product.id === promoSale.data.product_id) {
          return { ...product, newPrice: promoSale.data.new_price };
        }
        return product;
      });
    } catch (err: any) {
      if (err.response.status === 404 || err.response.status === 405) {
        toast.error("Промокод не найден");
      } else if (err.response.status === apiErrorCodes.AccessTokenInvalidCode) {
        await dispatch(checkPromoThunk(requestData));
      } else if (err.response.status === apiErrorCodes.NotFoundItem) {
      } else if (err.response.status === 422) {
        toast.error("Не указан промокод или не выбран тариф");
      } else {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      }
    }
  }
);

export type PayProductRequestType = {
  product_id: string;
  promo_code: string | null;
  category_ids: null | string[];
};
export const payProductThunk = createAsyncThunk(
  "product/payProductThunk",
  async (data: PayProductRequestType, { dispatch }) => {
    try {
      const res = await productAPI.postPayUrl(data);
      return res.data.url;
    } catch (err: any) {
      if (err.response.status === 403) {
        toast.error("Неверное количество выбранных категорий");
      } else if (err.response.status === apiErrorCodes.AccessTokenInvalidCode) {
        await dispatch(payProductThunk(data));
      } else {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      }
    }
  }
);
