import s from "./responseWaiting.module.css";

const ResponseWaiting = () => {
  return (
    <div className={s.adviceWrapper}>
      <div className={s.titleDiv}>
        Ваш текст генерируется, подождите пару минут.
      </div>
    </div>
  );
};

export default ResponseWaiting;
