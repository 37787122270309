import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { Checkbox, CheckboxProps } from "./checkbox";

type ControlledInputProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<CheckboxProps, "onCheckedChange" | "checked" | "id">;

export const ControlledCheckbox = <T extends FieldValues>({
  name,
  control,
  ...restProps
}: ControlledInputProps<T>): JSX.Element => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return <Checkbox {...restProps} checked={value} onCheckedChange={onChange} />;
};
