import { FC, memo, MouseEventHandler, ReactNode } from "react";
import s from "./section.module.scss";
import { NavLink } from "react-router-dom";
import {
  CategoryTypeGpt,
  SVGElementType,
} from "../../../store/features/questionTypes";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";
import { SectionMenu } from "./section-menu/section-menu";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { appSelectors } from "../../../store/features/app/app-selectors";

import { appActions } from "../../../store/features/app/app-slice";
import done from "../../../assets/icons/done.svg";
import { yandexMetrikaSideMenuClickEvent } from "../../../common/utils/yadexMetrikaCounter";
import {
  ResizeType,
  useResize,
} from "../../../common/hooks/use-resize/useResize";
import { categoryImgAnimate, showAnimationSection } from "../side-menu-animate";
import { ArrowDownIcon } from "../../../assets/icons/arrow-down-icon";

export type SectionItemType<T = null> = {
  title: string;
  id: string;
  ImageSVG?: SVGElementType;
  isNew?: boolean;
  type_gpt?: CategoryTypeGpt;
  isNotViewedAnswer?: boolean;
  path?: string;
  itemType?: "category" | "support";
  child?: T;
};

type ParentWrapperPropsType = {
  path?: string;
  itemType: "category" | "support";
  children: ReactNode;
  id: string;
};
const ParentWrapper: FC<ParentWrapperPropsType> = (props) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(appSelectors.isOpenSideBar());
  const toggleMenu: MouseEventHandler<HTMLImageElement> = () => {
    isOpen
      ? dispatch(appActions.setOpenedCategories({ id: props.id }))
      : dispatch(appActions.setOpenedSupport({ id: props.id }));
    dispatch(appActions.toggleSideBar(true));
  };
  if (props.itemType === "category") {
    return (
      <NavLink
        className={({ isActive }) => clsx(s.Link, isActive && s.Active)}
        to={props.path!}
      >
        {props.children}
      </NavLink>
    );
  }
  return (
    <div onClick={toggleMenu} className={clsx(s.Link, s.Support)}>
      {props.children}
    </div>
  );
};

export const Section: FC<SectionItemType<SectionItemType[]>> = memo((props) => {
  const {
    id,
    ImageSVG,
    child,
    path,
    itemType = "category",
    isNotViewedAnswer,
    title,
    isNew,
  } = props;
  const dispatch = useAppDispatch();
  const openCategories = useAppSelector(appSelectors.openedCategories());
  const devWidth: ResizeType = useResize();
  const isOpenMenu = openCategories.some((openId) => openId === id);
  const isOpen = useAppSelector(appSelectors.isOpenSideBar());
  const toggleMenu: MouseEventHandler<SVGSVGElement> = (event) => {
    event.preventDefault();
    itemType === "category" &&
      dispatch(appActions.setOpenedCategories({ id: id }));
  };
  const clickLinkHandler = (categoryId: string) => {
    yandexMetrikaSideMenuClickEvent(categoryId);
    !devWidth.isScreenLg && dispatch(appActions.toggleSideBar(!isOpen));
  };

  return (
    <div className={s.Section}>
      <ParentWrapper id={id} path={path && path} itemType={itemType}>
        <div onClick={() => clickLinkHandler(id)} className={s.linkContainer}>
          {ImageSVG && (
            <ImageSVG
              animate={categoryImgAnimate(isOpen)}
              className={s.SVGIcon}
            />
          )}
          <AnimatePresence>
            {isOpen && (
              <div className={s.TitleWrapper}>
                <motion.div
                  className={s.title}
                  variants={showAnimationSection}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                >
                  {title}
                </motion.div>
              </div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          <ArrowDownIcon
            animate={{
              rotate: isOpenMenu ? -180 : 0,
              transition: { duration: 0.1 },
            }}
            onClick={toggleMenu}
            className={clsx(s.ArrowIcon, !isOpen && s.ArrowIconHidden)}
          />
        )}
        <motion.div
          animate={isOpen ? { top: "-6px" } : { top: "0px" }}
          transition={{
            duration: 0.1,
          }}
          className={s.Notice}
        >
          {isNew && (
            <motion.div
              animate={
                isOpen ? { width: "60px" } : { width: "10px", height: "10px" }
              }
              transition={{
                duration: 0.1,
              }}
              className={s.New}
            >
              {isOpen && "Новое"}
            </motion.div>
          )}
          {isNotViewedAnswer && (
            <motion.div
              animate={
                isOpen ? { width: "20px" } : { width: "10px", height: "10px" }
              }
              transition={{
                duration: 0.1,
              }}
              className={s.NewAnswer}
            >
              {isOpen && <img src={done} alt="" />}
            </motion.div>
          )}
        </motion.div>
      </ParentWrapper>
      <AnimatePresence>
        {isOpen && isOpenMenu && <SectionMenu child={child!} />}
      </AnimatePresence>
    </div>
  );
});
