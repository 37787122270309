import { SVGProps, Ref, forwardRef, memo } from "react";
export const LinkIcon = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <circle cx="15" cy="15" r="15" fill="#ACFF8E" />
      <path
        d="M10.4502 13.521L8.34704 15.5237C7.48455 16.345 7 17.459 7 18.6205C7 19.782 7.48455 20.896 8.34704 21.7173C9.20954 22.5386 10.3793 23 11.5991 23C12.8189 23 13.9887 22.5386 14.8512 21.7173L17.6533 19.0475C18.1734 18.5521 18.5611 17.9452 18.784 17.2776C19.0068 16.6101 19.0582 15.9014 18.9338 15.211C18.8095 14.5206 18.513 13.8687 18.0694 13.3099C17.6257 12.7512 17.0478 12.302 16.3841 12L15.4858 12.8554C15.3946 12.9424 15.3153 13.04 15.2497 13.1459C15.7625 13.2862 16.228 13.5516 16.6 13.9158C16.9721 14.28 17.2379 14.7303 17.3712 15.2223C17.5046 15.7143 17.5007 16.2308 17.3601 16.721C17.2195 17.2111 16.947 17.6578 16.5695 18.0169L13.7689 20.6853C13.1937 21.2331 12.4134 21.5408 11.5999 21.5408C10.7863 21.5408 10.0061 21.2331 9.43081 20.6853C8.85554 20.1375 8.53235 19.3945 8.53235 18.6198C8.53235 17.8451 8.85554 17.1021 9.43081 16.5543L10.6464 15.3982C10.4749 14.7862 10.4086 14.1532 10.4502 13.521Z"
        fill="black"
      />
      <path
        d="M12.3467 10.9516C11.8266 11.447 11.4389 12.054 11.216 12.7217C10.9932 13.3893 10.9418 14.0981 11.0662 14.7886C11.1905 15.4791 11.487 16.1311 11.9306 16.6899C12.3743 17.2487 12.9522 17.6979 13.6159 18L14.8039 16.8671C14.2842 16.7343 13.8103 16.4736 13.4299 16.1112C13.0495 15.7487 12.7759 15.2973 12.6367 14.8023C12.4976 14.3072 12.4976 13.786 12.6369 13.291C12.7763 12.796 13.0499 12.3446 13.4305 11.9823L16.2311 9.31359C16.8063 8.76571 17.5866 8.45792 18.4001 8.45792C19.2137 8.45792 19.9939 8.76571 20.5692 9.31359C21.1445 9.86146 21.4676 10.6045 21.4676 11.3793C21.4676 12.1542 21.1445 12.8972 20.5692 13.4451L19.3536 14.6013C19.5253 15.2145 19.5912 15.8496 19.5498 16.4802L21.653 14.4773C22.5155 13.6558 23 12.5417 23 11.3801C23 10.2184 22.5155 9.10432 21.653 8.28289C20.7905 7.46147 19.6207 7 18.4009 7C17.1811 7 16.0113 7.46147 15.1488 8.28289L12.3467 10.9516Z"
        fill="black"
      />
    </svg>
  ))
);
