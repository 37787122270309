import { FC, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import s from "./section-menu.module.scss";
import { motion } from "framer-motion";
import clsx from "clsx";
import { yandexMetrikaSideMenuClickEvent } from "../../../../common/utils/yadexMetrikaCounter";
import VisionIcon from "../../../../assets/icons/category/vision-icon";
import { appActions } from "../../../../store/features/app/app-slice";
import {
  ResizeType,
  useResize,
} from "../../../../common/hooks/use-resize/useResize";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { appSelectors } from "../../../../store/features/app/app-selectors";
import { menuAnimation, menuItemAnimation } from "../../side-menu-animate";
import { SectionItemType } from "../section";
import { Typography } from "../../../typography/Typography";

type ParentWrapperPropsType = {
  path?: string;
  itemType: "category" | "support" | "link";
  children: ReactNode;
  item: SectionItemType;
};
export const ParentWrapper: FC<ParentWrapperPropsType> = (props) => {
  if (props.itemType === "category") {
    return (
      <NavLink
        className={({ isActive }) =>
          clsx(
            s.Link,
            props.item.type_gpt === "gpt_vision" && s.LinkVision,
            isActive && s.Active,
            isActive && props.item.type_gpt === "gpt_vision" && s.ActiveVision,
            props.item.isNotViewedAnswer && s.isNotViewedAnswer
          )
        }
        to={props.path!}
      >
        {props.children}
      </NavLink>
    );
  }
  return (
    <a
      className={s.Link}
      rel="noopener noreferrer"
      target="_blank"
      href={props.path!}
    >
      {props.children}
    </a>
  );
};

type PropsType = {
  child: SectionItemType[];
};
export const SectionMenu: FC<PropsType> = ({ child }) => {
  const isOpen = useAppSelector(appSelectors.isOpenSideBar());
  const dispatch = useAppDispatch();
  const devWidth: ResizeType = useResize();
  const clickLinkHandler = (categoryId: string) => {
    yandexMetrikaSideMenuClickEvent(categoryId);
    !devWidth.isScreenLg && dispatch(appActions.toggleSideBar(!isOpen));
  };

  return (
    <motion.div
      variants={menuAnimation}
      initial="hidden"
      animate="show"
      exit="hidden"
      className={s.SectionMenu}
    >
      {child.map((c, i) => (
        <motion.div
          variants={menuItemAnimation}
          key={c.id}
          id={c.title}
          custom={i}
          className={s.Child}
          onClick={() => clickLinkHandler(c.id!)}
        >
          <ParentWrapper
            item={c}
            key={c.id}
            itemType={c.itemType!}
            path={c.path}
          >
            <>
              {c.type_gpt === "gpt_vision" && <VisionIcon />}
              {c.ImageSVG && <c.ImageSVG />}
              <Typography className={clsx(s.Title, c.isNew && s.New)}>
                {c.title}
              </Typography>
            </>
          </ParentWrapper>
        </motion.div>
      ))}
    </motion.div>
  );
};
