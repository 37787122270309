import s from "./template.module.css";
import {
  QuestionType,
  TemplateType,
} from "../../../../../../store/features/questionTypes";
import arrowIconUp from "../../../../../../assets/icons/contentPage/arrow_up.svg";
import arrowIconDown from "../../../../../../assets/icons/contentPage/arrow_down.svg";
import okIcon from "../../../../../../assets/icons/contentPage/ok_icon.png";
import removeIcon from "../../../../../../assets/icons/contentPage/remove_icon.png";
import { QUESTION_OPTION_TYPE } from "../../../../../../store/storeConst/consts";
import { RootState, useAppDispatch } from "../../../../../../store/store";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { deleteTemplateThunk } from "../../../../../../store/features/questionThunk";
import {
  changeShowTemplateQuestionsListAC,
  tempChangeQuestionsAC,
} from "../../../../../../store/features/questionSlice";

type SectionType = {
  template: TemplateType;
  onClose: () => void;
};

export const TemplateSection = ({ template, onClose }: SectionType) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const currentCategoryId: string = params.categoryId || "";
  const templates: Array<TemplateType> = [
    ...useSelector((state: RootState) => state.question.templates),
  ].filter((t) => t.categoryId === currentCategoryId);
  const isTemplatesLoading: boolean = useSelector(
    (state: RootState) => state.question.loadingVars.isTemplatesLoading
  );

  const showTemplateQuestionsList: Array<string> = useSelector(
    (state: RootState) => state.question.templateVars.showTemplateQuestionsList
  );

  const [isCreateTemplate, setIsCreateTemplate] = useState<boolean>(false);

  // useEffect(() => {
  //   if (currentCategoryId && currentCategoryId.length > 0) {
  //     dispatch(getTemplatesThunk(currentCategoryId));
  //   }
  // }, []);

  const onApplyTemplateClickHandler = (templateId: string) => {
    const templateQuestions: Array<QuestionType> =
      templates.find((t) => t.id === templateId)?.questions || [];
    if (templateQuestions.length > 0) {
      dispatch(tempChangeQuestionsAC(templateQuestions));
    }
    onClose();
  };

  const onRemoveTemplateClickHandler = (
    templateId: string,
    templateTitle: string
  ) => {
    const answer = window.confirm("Удалить шаблон " + templateTitle + "?");
    if (answer) {
      dispatch(deleteTemplateThunk({ templateId: templateId }));
    }
  };

  const isShowQuestions = showTemplateQuestionsList.some(
    (item) => item === template.id
  );
  const onShowHideIconClickHandler = () => {
    const arr: Array<string> = isShowQuestions
      ? [...showTemplateQuestionsList].filter((item) => item !== template.id)
      : [...showTemplateQuestionsList, template.id];
    dispatch(changeShowTemplateQuestionsListAC(arr));
  };
  const imgArrowIcon = isShowQuestions ? arrowIconUp : arrowIconDown;

  return (
    <div className={s.Section}>
      <div className={s.oneSectionHead}>
        <div className={s.oneSectionHead_title}>
          <h4>{template.title}</h4>
        </div>

        <div className={s.oneSectionMenuBtns}>
          {isShowQuestions && (
            <>
              <img
                alt=""
                src={okIcon}
                onClick={() => onApplyTemplateClickHandler(template.id)}
              />

              <img
                alt=""
                src={removeIcon}
                onClick={() =>
                  onRemoveTemplateClickHandler(template.id, template.title)
                }
              />
            </>
          )}
        </div>

        <div className={s.imgDiv}>
          <img alt="" src={imgArrowIcon} onClick={onShowHideIconClickHandler} />
        </div>
      </div>

      {isShowQuestions && (
        <div>
          {template.questions.map((tempQuestion: QuestionType, ind: number) => {
            const questionAsnwer: string =
              tempQuestion.questionType === QUESTION_OPTION_TYPE.options &&
              tempQuestion.options
                ? tempQuestion.options.find(
                    (el) => el.id === tempQuestion.answer
                  )?.text || ""
                : tempQuestion.answer || "";

            return (
              <div key={tempQuestion.id}>
                {tempQuestion.answer && tempQuestion.answer.length > 0 && (
                  <div className={s.oneQuestion_class} key={tempQuestion.id}>
                    <h5>Вопрос:</h5>
                    <label>{tempQuestion.question}</label>
                    <h5>Ответ:</h5>
                    <label>{questionAsnwer}</label>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
