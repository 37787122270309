import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const authSchema = z.object({
  username: z.string().nonempty("Введите логин").trim(),
  password: z.string().min(2, "Введите пароль").trim(),
  agreeWithPolitical: z.boolean().refine((value) => value, {
    message: "Для входа в портал Вам необходимо согласиться со всеми условиями",
    path: ["agreeWithPolitical"],
  }),
});

export type authSchemaFormType = z.infer<typeof authSchema>;

export const useAuthForm = () =>
  useForm<authSchemaFormType>({
    resolver: zodResolver(authSchema),
    defaultValues: {
      password: "",
      username: "",
      agreeWithPolitical: false,
    },
  });
