import s from "./headerAccountInfo.module.scss";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../common/router/paths";
import { ResizeType, useResize } from "../../common/hooks/use-resize/useResize";
import {
  user,
  userAvatar,
  userName,
} from "../../store/features/profile/profile-selectors";
import { Avatar } from "../avatar/avatar";
import { yandexMetrikaClickEvent } from "../../common/utils/yadexMetrikaCounter";
import { appActions } from "../../store/features/app/app-slice";
import { SettingIcon } from "../../assets/icons/setting";
import { Typography } from "../typography/Typography";

const HeaderAccountInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const avaImg = useAppSelector(userAvatar());
  const profile = useAppSelector(user());
  const devWidth: ResizeType = useResize();

  const username: string = useAppSelector(userName());

  const onClickHandler = () => {
    yandexMetrikaClickEvent("TT_2_click_profile");
    !devWidth.isScreenSm && dispatch(appActions.toggleSideBar(false));
    navigate(Paths.profile);
  };
  if (!profile.username) return null;
  return (
    <div
      id={"headerAccountInfo"}
      className={s.accountInfoWrapper}
      onClick={onClickHandler}
    >
      <Avatar size={"small"} image={avaImg} className={s.Avatar} />
      {devWidth.isScreenSm && (
        <Typography className={s.accountInfoUsernameLabel}>
          {username}
        </Typography>
      )}
      <SettingIcon />
    </div>
  );
};

export default HeaderAccountInfo;
