import { ChangeEvent } from 'react';
import s from './lineTextFiled.module.css';

type LineTextFieldPropsType = {
    type: 'text' | 'password',
    text: string,
    onChangeFunction: (newval: string) => void,
    error?: boolean,
    classForInput?: string
    placeholder?: string
}
export const AuthLineTextField = (props: LineTextFieldPropsType) => {

    const classForInputTextField = (): string => {
        return props.error ? s.lineTextField + " " + s.errorTextField : s.lineTextField + " " + s.normalTextField
    }
    const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChangeFunction(e.currentTarget.value);
    }

    return <input 
        type={props.type}
        className={classForInputTextField()}
        value={props.text}
        onChange={(e) => onTextChange(e) }
        spellCheck={false}
    />
}


export const LineTextField1 = (props: LineTextFieldPropsType) => {

    const classForInputTextField = props.classForInput ? props.classForInput : s.lineTextField;
    
    const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChangeFunction(e.currentTarget.value);
    }

    return <input 
        type={props.type}
        className={classForInputTextField}
        value={props.text}
        onChange={(e) => onTextChange(e) }
        spellCheck={false}
        placeholder={props.placeholder || ""}
        autoFocus={true}
    />

}