import s from "./home.module.css";

export const DescriptionAreaOnHomePage = () => {
  return (
    <div className={s.mainDescriptionDiv}>
      <div className={s.wellcomeText}>
        {/* <label>Всем привет!</label>
        <label>С вами Пиарошная! И да, мы наконец запустились 🙂</label>
        <div>
          <label>
            Кратко напомним кто мы такие (если кто-то пришел минуя лендинг
          </label>
          <a href="https://www.aipr.pro"> www.aipr.pro</a>
          <label>):</label>
        </div>
        <label>
          - Пиарошная – первая нейросеть для помощи конкретно пиарщикам и
          профессионалам, продвигающим свой бизнес;
        </label>
        <label>
          - Сервис генерирует тексты профессионального уровня для решения
          подавляющего большинства рутинных задач PR: работа со СМИ, соцсетями,
          внутренними коммуникациями, организацией мероприятий.
        </label>
        <label>
          - Наши основные пользователи - директора по PR, специалисты по связям
          с общественностью, event-менеджерs, SMM-профессионалы, маркетологи,
          коммуникационные агентства, владельцы и сотрудники малого и среднего
          бизнеса, связанные с продвижением и выводом на рынок компаний и
          брендов.
        </label>
        <label>
          - Принцип работы потрала: вы оплачиваете тариф (или вводите стартовый
          промокод для ознакомления: 👉 Start01 ), выбираете нужный вам раздел и
          подкатегорию, даете нейросети фактуру – и она генерирует для вас
          классный текст! Иногда для качественного результата требуется сделать
          2-3 генерации, или немного направить нейронку через профильные поля:
          просто давайте рекомендации как бы вы давали их сотруднику. Словами!
        </label> */}

        <label>
          Всем привет!
        </label>
        <label>
          Вы - в "Пиарошной"!
        </label>
        <label>
          Это первая нейросеть, созданная специально для пиарщиков, маркетологов и профессионалов, самостоятельно развивающих бизнес. 
        </label>
        <label>
          Если кратко, то:
        </label>
        <label>
          👉 Сервис генерирует тексты профессионального уровня для решения большинства рутинных задач PR: 
          работа со СМИ, соцсетями, внутренними коммуникациями, организацией мероприятий;
        </label>
        <label>
          👉 Дообученные модели AiPR работают на базе систем сразу трех мировых лидеров в области искусственного интеллекта: 
          вы легко подберете нужный "стиль" коммуникаций и максимально приблизите его к собственному Tone of voice;
        </label>
        <label>
          👉 Основные пользователи "Пиарошной" - директора по PR, специалисты по связям с общественностью, event-менеджерs, 
          SMM-профессионалы, маркетологи, коммуникационные агентства, владельцы и сотрудники малого и среднего бизнеса, 
          связанные с продвижением и выводом на рынок компаний и брендов.
        </label>
        <label>
          👉 Принцип работы портала: вы оплачиваете тариф (или вводите стартовый промокод для ознакомления: 👉 Start01 ), 
          выбираете нужный вам раздел и подкатегорию, выбираете креативный или серьезный подход к написанию, 
          даете нейросети фактуру – и она генерирует для вас классный текст! Иногда для качественного результата требуется 
          сделать 2-3 генерации, или немного направить нейронку через профильные поля: просто давайте рекомендации как бы вы 
          давали их сотруднику. Словами!
        </label>
      </div>
    </div>
  );
};
