import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { historyAPI, responseAPI } from "../../common/api/api";
import { ChangingOneQuestionType } from "./questionTypes";
import {
  changeIsLimitationOnAC,
  removeWhichCategoryGptLoadingAC,
} from "./answerSlice";
import { apiErrorCodes, standartApiErrorMessage } from "../../common/api/errorCodes";
import { profileActions } from "./profile/profile-slice";
import { setNoticeNewAnswerGPT } from "./questionSlice";
import { createAppAsyncThunk } from "../createAppAsyncThunk";

import {
  RequestVisionType,
  ResponseVisionType,
  visionAPI,
} from "../../common/api/vision-GPT/vision-GPT";

export type PostReceiveGptRequestV2Type = {
  question_answer: Array<ChangingOneQuestionType>;
  category_id: {
    model_gpt_id: string;
    categoryId: string;
  };
};
export type PostReceiveGptRequestV2_1_Type = {
  question_answer: Array<{
    question_id: string;
    answer: string | null;
  }>;
  category_id: {
    model_gpt_id: string;
    categoryId: string;
  };
};
export type PostReceiveGptResponseV2Type = {
  gpt_answer: string;
  gpt_answer_id: string;
  categoryId: string;
};
export const postReceiveGptThunkV2 = createAsyncThunk(
  "answer/postReceiveGptThunkV2",
  async (data: PostReceiveGptRequestV2Type, { rejectWithValue, dispatch }) => {
    try {
      const dataToSend: PostReceiveGptRequestV2_1_Type = {
        ...data,
        question_answer: [...data.question_answer].map((q) => {
          return {
            ...q,
            answer: q.answer && q.answer.length > 0 ? q.answer : null,
          };
        }),
      };
      const res = await responseAPI.getGptResponseV2(dataToSend);
      dispatch(removeWhichCategoryGptLoadingAC(data.category_id.categoryId));
      dispatch(
        setNoticeNewAnswerGPT({ id: data.category_id.categoryId, value: true })
      );
      dispatch(
        profileActions.setRemainingUses(res.data.payloads.gpt_answer.length)
      );
      return { ...res.data.payloads, categoryId: data.category_id.categoryId };
      
    } catch (err: any) {
      dispatch(removeWhichCategoryGptLoadingAC(data.category_id.categoryId));
      if (err.response.status === 400) {
        toast.error("Не все ответы предоставлены");
      } else if (err.response.status === apiErrorCodes.AccessTokenInvalidCode) {
        await dispatch(postReceiveGptThunkV2(data));
      } else if (
        err.response.data.message === "Bot send message notification error"
      ) {
        toast.error(
          "Доступ к данной категории возможен только с оплаченным тарифом"
        );
        dispatch(changeIsLimitationOnAC(true));
      } else if (
        err.response.status === apiErrorCodes.categoryHasNotPaid
      ) {
        toast.error(
          "Доступ к данной категории возможен только с оплаченным тарифом. Либо у Вас не оплачен тариф, либо Ваш тариф не включает данную категорию."
        );
      } else {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      }
    }
  }
);

type sendVisionGPTThunkType = ResponseVisionType & { category_id: string };

export const sendVisionGPTThunk = createAppAsyncThunk<
  sendVisionGPTThunkType,
  RequestVisionType
>("answer/sendVisionGPT", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  const formData = new FormData();
  const request_data = JSON.stringify(arg.request_data);
  formData.append("image", arg.image);
  formData.append("request_data", request_data);

  try {
    const data = await visionAPI.send(formData);
    dispatch(removeWhichCategoryGptLoadingAC(arg.request_data.category_id));
    dispatch(profileActions.setRemainingUses(data.data.content.length));
    return { ...data.data, category_id: arg.request_data.category_id };
  } catch (err: any) {
    dispatch(removeWhichCategoryGptLoadingAC(arg.request_data.category_id));
    console.log(err);
    if (err.response.data.message === "Access denied") {
      toast.error(
        "Доступ к данной категории возможен только с оплаченным тарифом"
      );
      dispatch(changeIsLimitationOnAC(true));
    } else {
      standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
    }
    return rejectWithValue(null);
  }
});

export type GetGptHistoryThunkRequestType = { categoryId: string };
export const getGptHistoryThunk = createAsyncThunk(
  "answer/getGptHistoryThunk",
  async (
    data: GetGptHistoryThunkRequestType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await historyAPI.getGptHistory(data.categoryId);
      return res.data.data;
    } catch (err: any) {
      standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
    }
  }
);

export type PostGptHistoryFavoriteThunkRequestType = { id: string };
export const postGptHistoryFavoriteThunk = createAsyncThunk(
  "answer/postGptHistoryFavoriteThunk",
  async (
    data: PostGptHistoryFavoriteThunkRequestType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await historyAPI.postGptHistoryFavorite(data);
      return res.data.data;
    } catch (err: any) {
      standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
    }
  }
);

export type DeleteGptHistoryFavoriteThunkRequestType = { id: string };
export const deleteGptHistoryFavoriteThunk = createAsyncThunk(
  "answer/deleteGptHistoryFavoriteThunk",
  async (
    data: PostGptHistoryFavoriteThunkRequestType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await historyAPI.deleteGptHistoryFavorite(data.id);
      return res.data.data;
    } catch (err: any) {
      standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
    }
  }
);
