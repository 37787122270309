import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { user } from "../../store/features/profile/profile-selectors";
import { appSelectors } from "../../store/features/app/app-selectors";
import React, { cloneElement, useEffect } from "react";
import { getMainData } from "../../store/features/profile/profile-thunk";
import { Loader } from "../../components/loader/loader";
import s from "./mainPage.module.scss";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import Header from "../../components/header/header";
import { AnimatePresence } from "framer-motion";
import { SideMenu } from "../../components/side-menu/side-menu";
import { Scrollbar } from "../../components/scrollbar";
import { clsx } from "clsx";
import { Onboarding } from "../../components/onboarding/onboarding";
import { ResizeType, useResize } from "../hooks/use-resize/useResize";
import { Paths } from "./paths";

const AppLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const profile = useAppSelector(user());
  const isLoading = useAppSelector(appSelectors.isLoadingApp());
  const devWidth: ResizeType = useResize();
  const element = useRoutes(privateRoutes);

  useEffect(() => {
    dispatch(getMainData());
  }, [dispatch]);

  if (isLoading) return <Loader />;

  return profile.id ? (
    <div className={clsx(s.wrapper, s.root)}>
      <Header />
      <div className={s.mainSection}>
        <SideMenu />
        <AnimatePresence mode="wait">
          <Scrollbar
            key={location.pathname}
            type={devWidth.isScreenLg ? "hover" : "scroll"}
            className={s.ScrollBar}
          >
            <div className={s.outletDiv}>

              {cloneElement(element!, { key: location.pathname })}
              
            </div>
          </Scrollbar>
        </AnimatePresence>
      </div>
      <Onboarding />
    </div>
  ) : (
    <Navigate to={Paths.auth} />
  );
};

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    path: "/*",
  },
  ...publicRoutes,
]);

export const Router = () => {
  return <RouterProvider router={router} />;
};
