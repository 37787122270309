import { useAppDispatch } from "../../../../../store/store";
import OneQuestionTextField from "./oneQuestionTextField/oneQuestionTextField";
import {
  ChangingOneQuestionType,
  QuestionType,
} from "../../../../../store/features/questionTypes";
import { changeOneAnswerInQuestionAC } from "../../../../../store/features/questionSlice";

type OneQuestionTextPropsType = {
  question: QuestionType;
  isDisabled?: boolean;
};
const OneQuestionText = (props: OneQuestionTextPropsType) => {
  const dispatch = useAppDispatch();
  const onQuestionTextChangeHandler = (newValue: string) => {
    const dataToChange: ChangingOneQuestionType = {
      question_id: props.question.id,
      answer: newValue,
    };
    dispatch(changeOneAnswerInQuestionAC(dataToChange));
  };

  return (
    <OneQuestionTextField
      id={props.question.id?.replace(/[0-9-]/g, "")}
      onChangeFunction={(newValue: string) =>
        onQuestionTextChangeHandler(newValue)
      }
      value={props.question.answer || ""}
      placeholder={props.question.snippet || ""}
      isDisabled={props.isDisabled}
    />
  );
};

export default OneQuestionText;
