export enum TypographyVariant {
  Large = "large",
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  Body1 = "body1",
  Body2 = "body2",
  Subtitle1 = "subtitle1",
  Subtitle2 = "subtitle2",
  Caption = "caption",
  Overline = "overline",
  Link1 = "link1",
  Link2 = "link2",
  ERROR = "error",
}

export enum Tokens {
  access = "access_t",
  refresh = "refresh_t",
}

export enum ButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Quaternary = "quaternary",
  Text = "text",
  // Link = "link",
}
