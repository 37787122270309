import { RouteObject } from "react-router-dom";
import { Paths } from "./paths";
import PreContentBlock from "../../page/contentBlock/preContentBlock";
import HomePage from "../../page/homePage/homePage";
import SettingPage from "../../page/setting/setting-page";
import { ProfilePage } from "../../page/setting/profile-page/profile-page";
import { ChoosingPhotoPage } from "../../page/setting/profile-page/choosing-photo-page/choosing-photo-page";
import { ChangePasswordPage } from "../../page/setting/profile-page/change-password-page/change-password-page";

import GptHistory from "../../page/gptHistory/gptHistory";
import {
  NotFoundPage,
  ServerNotConnected,
} from "../../page/template-page/template-pages";
import { AuthPage } from "../../page/auth/auth-page";
import { Tariffs } from "../../page/tariffs/tariffs";

export const profileRoutes: RouteObject[] = [
  { path: Paths.profile, element: <ProfilePage /> },
  { path: Paths.choosingPhoto, element: <ChoosingPhotoPage /> },
  { path: Paths.changePassword, element: <ChangePasswordPage /> },
];

export const privateRoutes: RouteObject[] = [
  { 
    path: Paths.category + "/:categoryId", 
    element: <PreContentBlock />,
    errorElement: <NotFoundPage />,
  },
  { path: Paths.mainPage, element: <HomePage /> },
  { path: Paths.setting, element: <SettingPage />, 
    children: profileRoutes,
    errorElement: <NotFoundPage />,
  },

  { path: Paths.tariffs, element: <Tariffs /> },
  {
    path: Paths.gptHistory + "/:categoryId",
    element: <GptHistory />,
    errorElement: <NotFoundPage />,
  },
  { path: "*", element: <NotFoundPage /> },
];

export const publicRoutes: RouteObject[] = [
  {
    path: Paths.auth,
    element: <AuthPage />,
  },
  {
    path: Paths.serverIsAvailable,
    element: <ServerNotConnected />,
  },
  // { path: "*", element: <NotFoundPage /> },
];
