import { AuthLoginThunkPropsType } from "../../../store/features/auth/authThunk";
import { AxiosResponse } from "axios";
import { instance } from "../instance";

export const authAPI = {
  login: (
    data: AuthLoginThunkPropsType
  ): Promise<AxiosResponse<AuthResponseType>> => {
    return instance.post("auth/login", data);
  },
  changePassword: (data: ChangePasswordDataType): Promise<AxiosResponse> => {
    return instance.post("auth/changePassword", data);
  },
  refreshToken: (
    refresh_token: string
  ): Promise<AxiosResponse<Pick<AuthResponseType, "access_token">>> => {
    return instance.post("auth/refresh_token", { refresh_token });
  },
};

export type AuthResponseType = {
  access_token: string;
  refresh_token: string;
};
export type ChangePasswordDataType = {
  oldPassword: string;
  newPassword: string;
};
