import { ComponentPropsWithoutRef, ElementType, forwardRef, Ref } from "react";
import s from "./Typography.module.scss";
import { TypographyVariant } from "../../store/storeConst/enums";
import clsx from "clsx";

export type TypographyProps<T extends ElementType = "p"> = {
  variant?: TypographyVariant;
} & ComponentPropsWithoutRef<T>;

export const Typography = forwardRef(
  <T extends ElementType = "p">(
    {
      as,
      className,
      variant = TypographyVariant.Body2,
      ...restProps
    }: TypographyProps<T> &
      Omit<ComponentPropsWithoutRef<T>, keyof TypographyProps<T>>,
    ref: Ref<HTMLParagraphElement> // Используйте Ref<HTMLParagraphElement> для рефа
  ): JSX.Element => {
    const Component = as || "p";

    const typographyClasses = clsx(s[variant], className);

    return <Component className={typographyClasses} ref={ref} {...restProps} />;
  }
);
