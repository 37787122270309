type FormattedDateType = {
  date: string;
  time?: boolean;
};

export const formattedDate = ({ date, time = true }: FormattedDateType) => {
  const d = new Date(date);
  const formattedDate = `${d.getDate()}.${d.getMonth() + 1}.${d
    .getFullYear()
    .toString()
    .slice(-2)}`;
  const formattedTime = `${d.getHours()}:${d
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  return `${formattedDate} ${time ? formattedTime : ""}`;
};
