import { ChangeEvent } from 'react';
import s from './textAreaAutoResize.module.css';
import TextareaAutosize from 'react-textarea-autosize';

type TextAreaAutoResizablePropsType = {
    text: string
    onChangeFunction: (newVal: string) => void
    error?: boolean
    placeholder?: string
    isDisabled?: boolean
}
const TextAreaAutoResizable = (props: TextAreaAutoResizablePropsType) => {

    const onTextChange =(e: ChangeEvent<HTMLTextAreaElement>) => {
        props.onChangeFunction(e.currentTarget.value);
    }

    return (
        <div className={s.textAreaWrapper}>
            <TextareaAutosize 
                spellCheck={false}
                className={s.textArea} 
                value={props.text}
                onChange={e => onTextChange(e)}
                disabled={props.isDisabled}
            />
        </div>
    )
}

export default TextAreaAutoResizable;