import { TooltipRenderProps } from "react-joyride";
import { Button } from "../../button/button";
import s from "./tooltip.module.scss";
import { Typography } from "../../typography/Typography";
import {
  ButtonVariant,
  TypographyVariant,
} from "../../../store/storeConst/enums";
import { CloseButton } from "../../close-button/close-button";
import { useResize } from "../../../common/hooks/use-resize/useResize";

export const OnboardingTooltip = ({
  continuous,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
}: TooltipRenderProps) => {
  const { isScreenMd } = useResize();
  return (
    <div className={s.wrapper} {...tooltipProps}>
      <CloseButton onClick={skipProps.onClick} />
      <div className={s.textContainer}>
        {step.title && (
          <Typography
            variant={isScreenMd ? TypographyVariant.H1 : TypographyVariant.H2}
          >
            {step.title}
          </Typography>
        )}
        {step.content && (
          <Typography
            variant={
              isScreenMd ? TypographyVariant.Body1 : TypographyVariant.Body1
            }
          >
            {step.content}
          </Typography>
        )}
      </div>
      {!step.hideFooter && (
        <div className={s.buttonContainer}>
          {!isLastStep && !step.showSkipButton && (
            <Button variant={ButtonVariant.Text} {...skipProps} id="skip">
              {skipProps.title}
            </Button>
          )}
          {/*{index > 0 && <Button {...backProps} id="back"></Button>}*/}
          <Button
            className={s.nextButton}
            variant={ButtonVariant.Quaternary}
            {...primaryProps}
            id={continuous ? "next" : "close"}
          >
            {primaryProps.title}
          </Button>
        </div>
      )}
    </div>
  );
};
