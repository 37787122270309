import { useAppDispatch } from "../../../../../store/store";

// import deleteIcon from "../../../../../assets/icons/gprResponse/remove_icon.png";
import deleteIcon from "../../../../../assets/icons/gprResponse/delete_icon.svg";

import s from "./gptAnswerbuttons.module.css";
import { removeGptAnswerAC } from "../../../../../store/features/answerSlice";

type PropsType = {
    answerId: string
}

export const DeleteAnswerButton = ({answerId}: PropsType) => {

    const dispatch = useAppDispatch();

    const onDeleteClickHandler = () => {
        const answerAboutDeleting = window.confirm("Точно хотите удалить ответ?");
        if (answerAboutDeleting) {
          dispatch(removeGptAnswerAC(answerId));
        }
      };

    return (
        <div
          className={s.buttonSection}
          onClick={onDeleteClickHandler}
          data-title="Удалить"
        >
          <img alt="" src={deleteIcon} className={s.buttonSection_img}/>
        </div>
    )
}