import { ChangeEvent } from "react";
import s from "./oneQuestionTextFiled.module.css";
import TextareaAutosize from "react-textarea-autosize";

type OneQuestionTextFieldPropsType = {
  value: string;
  onChangeFunction: (newVal: string) => void;
  error?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  id?: string;
};
const OneQuestionTextField = (props: OneQuestionTextFieldPropsType) => {
  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    props.onChangeFunction(e.currentTarget.value);
  };

  return (
    <div className={s.textAreaWrapper}>
      <TextareaAutosize
        id={props.id}
        spellCheck={false}
        className={s.textArea}
        value={props.value}
        onChange={(e) => onTextChange(e)}
        disabled={props.isDisabled}
        placeholder={props.placeholder || ""}
      />
    </div>
  );
};

export default OneQuestionTextField;
