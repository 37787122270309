import { SVGProps, Ref, forwardRef, memo } from "react";
export const SettingIcon = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      {...props}
      ref={ref}
    >
      <g id="&#240;&#159;&#166;&#134; icon &#34;setting 2&#34;">
        <path
          id="Vector"
          d="M12.3473 4.54251C11.1126 4.54251 10.6078 3.66933 11.2217 2.59832C11.5764 1.97755 11.365 1.18623 10.7442 0.831499L9.56404 0.15615C9.02512 -0.164471 8.32931 0.0265374 8.00869 0.565453L7.93365 0.695065C7.31969 1.76607 6.31008 1.76607 5.68931 0.695065L5.61427 0.565453C5.30729 0.0265374 4.61148 -0.164471 4.07256 0.15615L2.8924 0.831499C2.27163 1.18623 2.06016 1.98437 2.41489 2.60514C3.03566 3.66933 2.53085 4.54251 1.29612 4.54251C0.586667 4.54251 0 5.12235 0 5.83863V7.03926C0 7.74871 0.579845 8.33538 1.29612 8.33538C2.53085 8.33538 3.03566 9.20856 2.41489 10.2796C2.06016 10.9003 2.27163 11.6917 2.8924 12.0464L4.07256 12.7217C4.61148 13.0424 5.30729 12.8514 5.62791 12.3124L5.70295 12.1828C6.3169 11.1118 7.32652 11.1118 7.94729 12.1828L8.02233 12.3124C8.34295 12.8514 9.03877 13.0424 9.57768 12.7217L10.7578 12.0464C11.3786 11.6917 11.5901 10.8935 11.2354 10.2796C10.6146 9.20856 11.1194 8.33538 12.3541 8.33538C13.0636 8.33538 13.6502 7.75553 13.6502 7.03926V5.83863C13.6434 5.12918 13.0636 4.54251 12.3473 4.54251ZM6.82171 8.656C5.60062 8.656 4.60465 7.66003 4.60465 6.43894C4.60465 5.21786 5.60062 4.22189 6.82171 4.22189C8.0428 4.22189 9.03877 5.21786 9.03877 6.43894C9.03877 7.66003 8.0428 8.656 6.82171 8.656Z"
        />
      </g>
    </svg>
  ))
);
