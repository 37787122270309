import s from "./titleLine.module.scss";
import { ButtonVariant, TypographyVariant } from "../../store/storeConst/enums";
import { Typography } from "../../components/typography/Typography";
import { Button } from "../../components/button/button";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { appSelectors } from "../../store/features/app/app-selectors";
import { OnboardingStatusVariant } from "../../store/features/app/app-types";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../common/router/paths";
import { appActions } from "../../store/features/app/app-slice";
import { ResizeType, useResize } from "../../common/hooks/use-resize/useResize";
import { StudyIcon } from "../../assets/icons/study";

const TitleLine = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const devWidth: ResizeType = useResize();
  const { status_education } = useAppSelector(appSelectors.getOnboarding());

  const launchOnboarding = () => {
    navigate(Paths.mainPage);
    dispatch(
      appActions.setOnboarding({
        status_education: OnboardingStatusVariant.Launched,
        tourActive: true,
        run: true,
        stepIndex: 0,
      })
    );
  };
  return (
    <div className={s.titleLineWrapper}>
      <div className={s.titleLineLeftPart}>
        <div className={s.titleContainer}>
          <Typography variant={TypographyVariant.H1} as={"h1"}>
            Настройки
          </Typography>
          {devWidth.isScreenLg &&
            status_education !== OnboardingStatusVariant.Launched && (
              <Button
                variant={ButtonVariant.Quaternary}
                onClick={launchOnboarding}
              >
                Пройти обучение
                <StudyIcon />
              </Button>
            )}
        </div>
        <Typography variant={TypographyVariant.Body1}>
          Управляйте своим профилем и оплатой сервиса
        </Typography>
      </div>
    </div>
  );
};

export default TitleLine;
