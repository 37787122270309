import { toast } from "react-toastify";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import { NavigateFunction } from "react-router-dom";
import { Paths } from "../../../common/router/paths";
import { appActions } from "../app/app-slice";
import {
  authAPI,
  ChangePasswordDataType,
} from "../../../common/api/auth-api/auth-api";
import { Tokens } from "../../storeConst/enums";
import { standartApiErrorMessage } from "../../../common/api/errorCodes";

export type AuthLoginThunkPropsType = {
  username: string;
  password: string;
};
const auth = createAppAsyncThunk<
  void,
  { loginData: AuthLoginThunkPropsType; navigate: NavigateFunction }
>("auth/authLoginThunk", async (arg, thunkAPI) => {
  const { dispatch } = thunkAPI;
  try {
    const res = await authAPI.login(arg.loginData);
    const { access_token, refresh_token } = res.data;
    localStorage.setItem(Tokens.access, access_token);
    localStorage.setItem(Tokens.refresh, refresh_token);
    dispatch(appActions.setLoading(true));
    arg.navigate(Paths.mainPage);
  } catch (err: any) {
    if (err.code === "ERR_NETWORK") {
      toast.error("Проблема с сервером. Обратитесь в техподдержку.");
    }
    if (err.response.status === 401) {
      toast.error("Неверное имя пользователя или пароль.");
    } else if (err.response.status === 409) {
      toast.error(
        "Повторная аутентификация невозможна"
      );
    } else if (err.response.status === 422) {
      toast.error("Ошибка валидации");
    } else {
      standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
    }
  }
});

export const isLoginInThunk = createAppAsyncThunk<
  void,
  { navigate: NavigateFunction }
>("auth/isLoginInThunk", async (arg, thunkAPI) => {
  const refresh_token = localStorage.getItem(Tokens.refresh);
  if (refresh_token) {
    const res = await authAPI.refreshToken(refresh_token);
    try {
      if (res.status === 200) {
        arg.navigate("/");
      }
    } catch (err: any) {
      thunkAPI.dispatch(appActions.setLoading(false));
      if (err.response.status === 300) {
      } else {
        standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
      }
    }
  } else {
    thunkAPI.dispatch(appActions.setLoading(false));
  }
});

export const authLogoutThunk = createAppAsyncThunk(
  "auth/authLogoutThunk",
  async () => {
    localStorage.removeItem(Tokens.access);
    localStorage.removeItem(Tokens.refresh);
    try {
    } catch (err: any) {
      if (err.response.status === 300) {
      } else {
        toast.error(
          " не так... код ошибки: " +
            err.response.data.code +
            "; сообщение: " +
            err.response.data.message
        );
      }
    }
  }
);

export const postChangePasswordThunk = createAppAsyncThunk(
  "auth/postChangePasswordThunk",
  async (data: ChangePasswordDataType) => {
    try {
      const res = await authAPI.changePassword(data);
      toast.success("Пароль успешно изменен");
      return res.data.data;
    } catch (err: any) {
      standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
    }
  }
);

export const authThunks = { auth };
