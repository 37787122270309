import { QuestionType } from "../../../../store/features/questionTypes";
import s from "./one.module.css";
import { QUESTION_OPTION_TYPE } from "../../../../store/storeConst/consts";
import OneQuestionOptions from "./oneQuestionOptions/oneQuestionOptions";
import OneQuestionText from "./oneQuestionText/oneQuestionText";
import OneQuestionNumeric from "./oneQuestionNumeric/oneQuestionNumeric";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import OneQuestionSemiOptional from "./oneQuestionSemiOptional/OneQuestionSemiOptional";
import { useParams } from "react-router-dom";
import { TypographyVariant } from "../../../../store/storeConst/enums";
import { Typography } from "../../../../components/typography/Typography";

type OneQuestionPropsType = {
  question: QuestionType;
};

const OneQuestion = (props: OneQuestionPropsType) => {
  const params = useParams();
  const currentCategoryId: string = params.categoryId || "";
  const whichCategoryGptAnswerLoading: Array<string> = useSelector(
    (state: RootState) => state.answer.loadingVars.whichCategoryGptAnswerLoading
  );
  const isThisCatAnswerLoading: boolean = whichCategoryGptAnswerLoading.some(
    (el) => currentCategoryId === el
  );

  return (
    <div className={s.oneWrapper}>
      <Typography variant={TypographyVariant.Body2}>
        {props.question.question}
        {!props.question.isRequired && (
          <label className={s.notRequiredField}>(не обязательно)</label>
        )}
      </Typography>

      {props.question.questionType === QUESTION_OPTION_TYPE.text && (
        <OneQuestionText
          question={props.question}
          isDisabled={isThisCatAnswerLoading}
        />
      )}

      {props.question.questionType === QUESTION_OPTION_TYPE.options &&
        props.question.options && (
          <OneQuestionOptions
            questionId={props.question.id}
            options={props.question.options}
            answer={props.question.answer}
            isDisabled={isThisCatAnswerLoading}
          />
        )}

      {props.question.questionType === QUESTION_OPTION_TYPE.numeric && (
        <OneQuestionNumeric
          question={props.question}
          isDisabled={isThisCatAnswerLoading}
        />
      )}

      {props.question.questionType === QUESTION_OPTION_TYPE.semiOptional && (
        <OneQuestionSemiOptional
          question={props.question}
          isDisabled={isThisCatAnswerLoading}
        />
      )}
    </div>
  );
};

export default OneQuestion;
