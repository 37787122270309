import { SVGProps, Ref, forwardRef, memo } from "react";
export const IconCat1 = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg width="84" height="84" viewBox="0 0 84 84" {...props} ref={ref}>
      <g id="Group 105">
        <g id="Top">
          <path
            d="M42 0C36.4845 0 31.023 1.08636 25.9273 3.19706C20.8316 5.30776 16.2016 8.40145 12.3015 12.3015C8.40145 16.2016 5.30776 20.8316 3.19706 25.9273C1.08636 31.023 0 36.4845 0 42H1.29231C1.29231 36.6542 2.34524 31.3607 4.391 26.4218C6.43675 21.483 9.43526 16.9954 13.2153 13.2153C16.9954 9.43526 21.483 6.43675 26.4218 4.391C31.3607 2.34524 36.6542 1.29231 42 1.29231C47.3458 1.29231 52.6393 2.34524 57.5782 4.391C62.517 6.43675 67.0046 9.43525 70.7847 13.2153C74.5647 16.9954 77.5632 21.483 79.609 26.4218C81.6548 31.3607 82.7077 36.6542 82.7077 42H84C84 36.4845 82.9136 31.023 80.8029 25.9273C78.6922 20.8316 75.5985 16.2016 71.6985 12.3015C67.7984 8.40145 63.1684 5.30776 58.0727 3.19706C52.977 1.08636 47.5155 0 42 0Z"
            fill="url(#paint0_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M42 5.16923C32.2319 5.16923 22.8638 9.0496 15.9567 15.9567C9.04961 22.8638 5.16923 32.2319 5.16923 42H6.46154C6.46154 32.5746 10.2058 23.5353 16.8705 16.8705C23.5353 10.2058 32.5746 6.46154 42 6.46154C51.4254 6.46154 60.4647 10.2058 67.1295 16.8705C73.7942 23.5353 77.5385 32.5746 77.5385 42H78.8308C78.8308 32.2319 74.9504 22.8638 68.0433 15.9567C61.1362 9.0496 51.7681 5.16923 42 5.16923Z"
            fill="url(#paint1_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M19.6119 19.6119C25.5496 13.6742 33.6028 10.3385 42 10.3385C50.3972 10.3385 58.4504 13.6742 64.3881 19.6119C70.3258 25.5496 73.6615 33.6028 73.6615 42H72.3692C72.3692 33.9456 69.1696 26.221 63.4743 20.5257C57.779 14.8304 50.0544 11.6308 42 11.6308C33.9456 11.6308 26.221 14.8304 20.5257 20.5257C14.8304 26.221 11.6308 33.9456 11.6308 42H10.3385C10.3385 33.6028 13.6742 25.5496 19.6119 19.6119Z"
            fill="url(#paint2_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M42 15.5077C34.9738 15.5077 28.2354 18.2988 23.2671 23.2671C18.2988 28.2354 15.5077 34.9738 15.5077 42H16.8C16.8 35.3165 19.455 28.9068 24.1809 24.1809C28.9068 19.455 35.3166 16.8 42 16.8C48.6835 16.8 55.0932 19.455 59.8191 24.1809C64.545 28.9068 67.2 35.3165 67.2 42H68.4923C68.4923 34.9738 65.7012 28.2354 60.7329 23.2671C55.7646 18.2988 49.0262 15.5077 42 15.5077Z"
            fill="url(#paint3_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M26.9223 26.9223C30.9212 22.9235 36.3448 20.6769 42 20.6769C47.6552 20.6769 53.0788 22.9235 57.0777 26.9223C61.0765 30.9212 63.3231 36.3448 63.3231 42H62.0308C62.0308 36.6875 59.9204 31.5926 56.1639 27.8361C52.4074 24.0796 47.3125 21.9692 42 21.9692C36.6875 21.9692 31.5926 24.0796 27.8361 27.8361C24.0796 31.5926 21.9692 36.6875 21.9692 42H20.6769C20.6769 36.3448 22.9235 30.9212 26.9223 26.9223Z"
            fill="url(#paint4_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M42 25.8461C37.7157 25.8461 33.6069 27.5481 30.5775 30.5775C27.5481 33.6069 25.8462 37.7157 25.8462 42H27.1385C27.1385 38.0585 28.7042 34.2784 31.4913 31.4913C34.2784 28.7042 38.0585 27.1385 42 27.1385C45.9415 27.1385 49.7216 28.7042 52.5087 31.4913C55.2958 34.2784 56.8615 38.0585 56.8615 42H58.1538C58.1538 37.7157 56.4519 33.6069 53.4225 30.5775C50.3931 27.5481 46.2843 25.8461 42 25.8461Z"
            fill="url(#paint5_linear_636_4989)"
            stroke="none"
          />
        </g>
        <g id="Bottom">
          <path
            d="M42 84C36.4845 84 31.023 82.9136 25.9273 80.8029C20.8316 78.6922 16.2016 75.5985 12.3015 71.6985C8.40145 67.7984 5.30776 63.1684 3.19706 58.0727C1.08636 52.977 0 47.5155 0 42H1.29231C1.29231 47.3458 2.34524 52.6393 4.391 57.5782C6.43675 62.517 9.43526 67.0046 13.2153 70.7847C16.9954 74.5647 21.483 77.5633 26.4218 79.609C31.3607 81.6548 36.6542 82.7077 42 82.7077C47.3458 82.7077 52.6393 81.6548 57.5782 79.609C62.517 77.5633 67.0046 74.5647 70.7847 70.7847C74.5647 67.0046 77.5632 62.517 79.609 57.5782C81.6548 52.6393 82.7077 47.3458 82.7077 42H84C84 47.5155 82.9136 52.977 80.8029 58.0727C78.6922 63.1684 75.5985 67.7984 71.6985 71.6985C67.7984 75.5985 63.1684 78.6922 58.0727 80.8029C52.977 82.9136 47.5155 84 42 84Z"
            fill="url(#paint6_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M42 78.8308C32.2319 78.8308 22.8638 74.9504 15.9567 68.0433C9.04961 61.1362 5.16923 51.7681 5.16923 42H6.46154C6.46154 51.4254 10.2058 60.4647 16.8705 67.1295C23.5353 73.7942 32.5746 77.5385 42 77.5385C51.4254 77.5385 60.4647 73.7942 67.1295 67.1295C73.7942 60.4647 77.5385 51.4254 77.5385 42H78.8308C78.8308 51.7681 74.9504 61.1362 68.0433 68.0433C61.1362 74.9504 51.7681 78.8308 42 78.8308Z"
            fill="url(#paint7_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M19.6119 64.3881C25.5496 70.3258 33.6028 73.6615 42 73.6615C50.3972 73.6615 58.4504 70.3258 64.3881 64.3881C70.3258 58.4504 73.6615 50.3972 73.6615 42H72.3692C72.3692 50.0544 69.1696 57.779 63.4743 63.4743C57.779 69.1696 50.0544 72.3692 42 72.3692C33.9456 72.3692 26.221 69.1696 20.5257 63.4743C14.8304 57.779 11.6308 50.0544 11.6308 42H10.3385C10.3385 50.3972 13.6742 58.4504 19.6119 64.3881Z"
            fill="url(#paint8_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M42 68.4923C34.9738 68.4923 28.2354 65.7012 23.2671 60.7329C18.2988 55.7646 15.5077 49.0262 15.5077 42H16.8C16.8 48.6835 19.455 55.0932 24.1809 59.8191C28.9068 64.545 35.3166 67.2 42 67.2C48.6835 67.2 55.0932 64.545 59.8191 59.8191C64.545 55.0932 67.2 48.6835 67.2 42H68.4923C68.4923 49.0262 65.7012 55.7646 60.7329 60.7329C55.7646 65.7012 49.0262 68.4923 42 68.4923Z"
            fill="url(#paint9_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M26.9223 57.0777C30.9212 61.0765 36.3448 63.3231 42 63.3231C47.6552 63.3231 53.0788 61.0765 57.0777 57.0777C61.0765 53.0788 63.3231 47.6552 63.3231 42H62.0308C62.0308 47.3125 59.9204 52.4074 56.1639 56.1639C52.4074 59.9204 47.3125 62.0308 42 62.0308C36.6875 62.0308 31.5926 59.9204 27.8361 56.1639C24.0796 52.4074 21.9692 47.3125 21.9692 42H20.6769C20.6769 47.6552 22.9235 53.0788 26.9223 57.0777Z"
            fill="url(#paint10_linear_636_4989)"
            stroke="none"
          />
          <path
            d="M42 58.1538C37.7157 58.1538 33.6069 56.4519 30.5775 53.4225C27.5481 50.3931 25.8462 46.2843 25.8462 42H27.1385C27.1385 45.9415 28.7042 49.7216 31.4913 52.5087C34.2784 55.2958 38.0585 56.8615 42 56.8615C45.9415 56.8615 49.7216 55.2958 52.5087 52.5087C55.2958 49.7216 56.8615 45.9415 56.8615 42H58.1538C58.1538 46.2843 56.4519 50.3931 53.4225 53.4225C50.3931 56.4519 46.2843 58.1538 42 58.1538Z"
            fill="url(#paint11_linear_636_4989)"
            stroke="none"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_636_4989"
          x1="42"
          y1="0"
          x2="42"
          y2="43.9385"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_636_4989"
          x1="42"
          y1="0"
          x2="42"
          y2="43.9385"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_636_4989"
          x1="42"
          y1="0"
          x2="42"
          y2="43.9385"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_636_4989"
          x1="42"
          y1="0"
          x2="42"
          y2="43.9385"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_636_4989"
          x1="42"
          y1="0"
          x2="42"
          y2="43.9385"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_636_4989"
          x1="42"
          y1="0"
          x2="42"
          y2="43.9385"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_636_4989"
          x1="42"
          y1="84"
          x2="42"
          y2="40.0615"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_636_4989"
          x1="42"
          y1="84"
          x2="42"
          y2="40.0615"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_636_4989"
          x1="42"
          y1="84"
          x2="42"
          y2="40.0615"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_636_4989"
          x1="42"
          y1="84"
          x2="42"
          y2="40.0615"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_636_4989"
          x1="42"
          y1="84"
          x2="42"
          y2="40.0615"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_636_4989"
          x1="42"
          y1="84"
          x2="42"
          y2="40.0615"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  ))
);
