import { SVGProps, Ref, forwardRef, memo } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="33"
    height="29"
    viewBox="0 0 33 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M28.9385 3.77459H24.5782L22.4353 0.560291C22.3204 0.387939 22.1647 0.24662 21.9821 0.148879C21.7995 0.0511375 21.5956 -2.33946e-06 21.3884 0H11.3229C11.1157 -2.20088e-06 10.9118 0.0511375 10.7292 0.148879C10.5465 0.24662 10.3909 0.38794 10.276 0.560291L8.13313 3.77459H3.77459C2.77386 3.77574 1.81444 4.17378 1.10682 4.88141C0.399191 5.58904 0.00114471 6.54845 0 7.54919V25.164C0.00114471 26.1647 0.399191 27.1241 1.10682 27.8317C1.81444 28.5394 2.77386 28.9374 3.77459 28.9385H28.9385C29.9393 28.9374 30.8987 28.5394 31.6063 27.8317C32.3139 27.1241 32.712 26.1647 32.7131 25.164V7.54919C32.712 6.54845 32.3139 5.58904 31.6063 4.88141C30.8987 4.17378 29.9393 3.77574 28.9385 3.77459ZM22.0185 15.7275C22.0185 16.8473 21.6864 17.942 21.0643 18.873C20.4421 19.8041 19.5579 20.5298 18.5233 20.9584C17.4887 21.3869 16.3503 21.499 15.252 21.2806C14.1537 21.0621 13.1448 20.5229 12.353 19.731C11.5612 18.9392 11.0219 17.9303 10.8035 16.8321C10.585 15.7338 10.6971 14.5953 11.1257 13.5608C11.5542 12.5262 12.2799 11.6419 13.211 11.0198C14.1421 10.3976 15.2368 10.0656 16.3566 10.0656C17.8577 10.0673 19.2968 10.6643 20.3583 11.7258C21.4197 12.7872 22.0168 14.2264 22.0185 15.7275Z"
      fill="white"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);

export default memo(ForwardRef);
