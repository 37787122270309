import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductInitContentType } from "./productTypes";
import {
  checkPromoThunk,
  getProductsThunk,
  payProductThunk,
} from "./productThunk";
import { productInitContent } from "../storeConst/vars";

export const productSlice = createSlice({
  name: "productSlice",
  initialState: productInitContent,
  reducers: {
    activePromoCode: (
      state: ProductInitContentType,
      action: PayloadAction<string>
    ) => {
      state.promoCode = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      getProductsThunk.pending,
      (state: ProductInitContentType) => {
        state.loadingVars.isProductLoading = true;
      }
    );
    builder.addCase(getProductsThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.products = action.payload.data
        state.categoriesSelection = action.payload.categories
      }
      state.loadingVars.isProductLoading = false;
    });
    builder.addCase(
      getProductsThunk.rejected,
      (state: ProductInitContentType) => {
        state.loadingVars.isProductLoading = false;
      }
    );

    // ----------

    builder.addCase(
      payProductThunk.pending,
      (state: ProductInitContentType) => {
        state.loadingVars.isPayProductLoading = true;
      }
    );
    builder.addCase(
      payProductThunk.fulfilled,
      (state: ProductInitContentType, action: PayloadAction<string>) => {
        if (action.payload && action.payload.length > 0) {
          window.location.href = action.payload;
        }
        state.loadingVars.isPayProductLoading = false;
      }
    );
    builder.addCase(
      payProductThunk.rejected,
      (state: ProductInitContentType) => {
        state.loadingVars.isPayProductLoading = false;
      }
    );

    // ----------

    builder.addCase(
      checkPromoThunk.pending,
      (state: ProductInitContentType) => {
        state.loadingVars.isPromoCheckLoading = true;
      }
    );
    builder.addCase(
      checkPromoThunk.fulfilled,
      (state: ProductInitContentType, action) => {
        state.loadingVars.isPromoCheckLoading = false;
        state.products = action.payload!;
      }
    );
    builder.addCase(
      checkPromoThunk.rejected,
      (state: ProductInitContentType) => {
        state.loadingVars.isPromoCheckLoading = false;
      }
    );
  },
});
export const productsActions = productSlice.actions;

export default productSlice.reducer;
