import { ResizeType } from "../../common/hooks/use-resize/useResize";

export const sideBarAnimation = (isOpen: boolean, devWidth: ResizeType) => ({
  width: !devWidth.isScreenSm && isOpen ? "100vw" : isOpen ? "330px" : "75px",
  minWidth:
    !devWidth.isScreenSm && isOpen ? "100vw" : isOpen ? "330px" : "75px",
  left:
    !devWidth.isScreenSm && !isOpen
      ? "-100vw"
      : !devWidth.isScreenLg && !isOpen
      ? "-75px"
      : "0px",
  transition: { duration: 0.3 },
});

export const FoldIconAnimate = (isOpen: boolean) => ({
  rotateY: isOpen ? 0 : -180,
  transition: {
    duration: 0.3,
  },
});

export const showAnimationSection = {
  hidden: {
    opacity: 0,
    x: -200,
    transition: {
      duration: 0.3,
    },
  },
  show: {
    opacity: 1,
    width: "auto",
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
  hiddenArrow: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
  showArrow: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.4,
      duration: 0.4,
    },
  },
};

export const categoryImgAnimate = (isOpen: boolean) =>
  isOpen
    ? {
        paddingLeft: 0,
      }
    : { paddingLeft: 5 };

export const menuAnimation = {
  hidden: {
    paddingTop: 0,
    opacity: 0,
    height: 0,
    transition: { duration: 0.2 },
  },
  show: {
    paddingTop: "10px",
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.2,
    },
  },
};

export const menuItemAnimation = {
  hidden: (i: number) => ({
    padding: 0,
    x: "-100%",
    transition: {
      duration: (i + 1) * 0.06,
    },
  }),
  show: (i: number) => ({
    x: 0,
    transition: {
      duration: (i + 1) * 0.06,
    },
  }),
};
