import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export const nameSchema = z.object({
  username: z.string().min(3, "Минимум 3 символа"),
});

export type NameSchemaType = z.infer<typeof nameSchema>;

export const useProfilePageForm = (username: string) =>
  useForm<NameSchemaType>({
    mode: "onSubmit",
    resolver: zodResolver(nameSchema),
    defaultValues: { username },
  });
