import s from "./header.module.scss";
import { Link } from "react-router-dom";
import { Paths } from "../../common/router/paths";
import { useAppDispatch, useAppSelector } from "../../store/store";
import HeaderAccountInfo from "./headerAccountInfo";
import { ResizeType, useResize } from "../../common/hooks/use-resize/useResize";
import { appSelectors } from "../../store/features/app/app-selectors";
import { motion } from "framer-motion";
import { Typography } from "../typography/Typography";
import { TypographyVariant } from "../../store/storeConst/enums";
import clsx from "clsx";
import React from "react";
import { user } from "../../store/features/profile/profile-selectors";
import { yandexMetrikaClickEvent } from "../../common/utils/yadexMetrikaCounter";
import { appActions } from "../../store/features/app/app-slice";
import { HamIcon } from "../../assets/icons/hamburger-side-menu";

import { LogoIcon } from "../../assets/icons/logo";

const Header = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(appSelectors.isOpenSideBar());
  const profile = useAppSelector(user());
  const devWidth: ResizeType = useResize();
  // const [theme, setTheme] = useState(false);
  // const useHandleThemeChanged = () => {
  //   document.body.classList.toggle("light-mode", !theme);
  //   setTheme(!theme);
  // };
  const toggle = () => dispatch(appActions.toggleSideBar(!isOpen));

  const onLogoClickHandler = () => {
    yandexMetrikaClickEvent("TT_1_click_logo");
    !devWidth.isScreenSm && dispatch(appActions.toggleSideBar(false));
  };

  return (
    <header className={s.headerDiv}>
      {devWidth.isScreenLg ? (
        <motion.div
          className={clsx(s.LogoWrapper, isOpen ? s.Show : s.Hidden)}
          animate={{
            width: devWidth.isScreenLg && isOpen ? "330px" : "75px",
            transition: { duration: 0.3 },
          }}
        >
          <Link
            id={"logo"}
            to={Paths.mainPage}
            className={s.Logo}
            onClick={onLogoClickHandler}
          >
            <LogoIcon />
            <Typography
              as={"h1"}
              variant={TypographyVariant.H1}
              className={s.Text}
            >
              Пиарошная
            </Typography>
          </Link>
        </motion.div>
      ) : (
        <>
          {profile.username && <HamIcon onClick={toggle} isOpen={isOpen} />}
          <Link
            id={"logo"}
            to={Paths.mainPage}
            className={clsx(s.Logo, !devWidth.isScreenLg && s.Smile)}
            onClick={onLogoClickHandler}
          >
            <LogoIcon />
            {devWidth.isScreenSm && (
              <Typography
                as={"h1"}
                variant={TypographyVariant.H1}
                className={s.Text}
              >
                Пиарошная
              </Typography>
            )}
          </Link>
        </>
      )}
      <div className={s.divTwo}>
        <div className={s.TariffInfo} id={"tariffInfo"}>
          {devWidth.isScreenMd && profile.access && (
            <Typography variant={TypographyVariant.Caption}>
              Осталось символов:
            </Typography>
          )}
          <Typography className={s.Number} variant={TypographyVariant.Caption}>
            {profile.access && profile.access.remainingUses}
          </Typography>
        </div>
        <HeaderAccountInfo />
      </div>
      {/*<Button onClick={useHandleThemeChanged}>Сменить тему</Button>*/}
    </header>
  );
};

export default Header;
