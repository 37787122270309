import s from "./createTemplate.module.scss";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "../../../../../store/store";
import {
  getTemplatesThunk,
  postAddTemplateThunk,
  PostAddTemplateThunkRequestType,
} from "../../../../../store/features/questionThunk";
import { useParams } from "react-router-dom";
import {
  ChangingOneQuestionType,
  QuestionType,
  TemplateType,
} from "../../../../../store/features/questionTypes";
import { useSelector } from "react-redux";
import { Modal } from "../../../../../components/modal/modal";
import { Typography } from "../../../../../components/typography/Typography";
import { TypographyVariant } from "../../../../../store/storeConst/enums";
import { TextField } from "../../../../../components/new-text-field/text-field";
import { Button } from "../../../../../components/button/button";

type CreateNewTemplatePropsType = {
  show: boolean;
  onCloseFunction: () => void;
};
const CreateNewTemplate = (props: CreateNewTemplatePropsType) => {
  const ref = useRef<HTMLDivElement>(null);
  const params = useParams();
  const currentCategoryId: string = params.categoryId || "";
  const dispatch = useAppDispatch();
  const [newTitle, setNewTitle] = useState<string>("");
  const templates: Array<TemplateType> = useSelector(
    (state: RootState) => state.question.templates
  );
  const questions: Array<QuestionType> = useSelector(
    (state: RootState) => state.question.questions
  );

  const onCloseFunction = () => {
    setNewTitle("");
    props.onCloseFunction();
  };

  const onSaveBtnClickHandler = () => {
    if (newTitle.length === 0) {
      toast.error("Обязательно ввести название шаблона");
    } else if (templates.some((t) => t.title === newTitle)) {
      toast.error("Такой шаблон уже существует");
    } else {
      if (currentCategoryId && currentCategoryId.length > 0) {
        const answersDataArray: Array<ChangingOneQuestionType> = questions.map(
          (q) => {
            return {
              question_id: q.id,
              answer: q.answer,
            };
          }
        );
        const data: PostAddTemplateThunkRequestType = {
          title: newTitle,
          category_id: currentCategoryId,
          answers: answersDataArray,
        };
        dispatch(postAddTemplateThunk(data));
        props.onCloseFunction();
      }
    }
  };

  return (
    <Modal
      show={props.show}
      theRef={ref}
      onChange={onCloseFunction}
      className={s.Wrapper}
    >
      <Typography className={s.Title} variant={TypographyVariant.H2}>
        Создание нового шаблона
      </Typography>
      <TextField
        value={newTitle}
        onValueChange={(newText: string) => setNewTitle(newText)}
        placeholder="Название шаблона..."
      />
      <Button onClick={onSaveBtnClickHandler}>Сохранить шаблон</Button>
    </Modal>
  );
};

export default CreateNewTemplate;
