import s from "./container.module.scss";
import { ComponentProps, ElementType } from "react";
import clsx from "clsx";

export type ContainerProps<T extends ElementType = "div"> = {
  as?: T;
  color?: "first" | "second" | "third";
} & ComponentProps<T>;

export const Container = <T extends ElementType = "p">({
  color,
  className,
  as,
  ...restProps
}: ContainerProps<T> &
  Omit<ComponentProps<T>, keyof ContainerProps<T>>): JSX.Element => {
  const Component = as || "div";
  return (
    <Component
      className={clsx(s.container, color && s[color], className)}
      {...restProps}
    />
  );
};
