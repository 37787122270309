import { RootState, useAppDispatch } from "../../../../store/store";
import { ProductType } from "../../../../store/features/productTypes";
import { useSelector } from "react-redux";
import { Tariff } from "../../../tariffs/tariff/tariff";
import { useEffect, useRef } from "react";
import { getProductsThunk } from "../../../../store/features/productThunk";
import { Modal } from "../../../../components/modal/modal";
import { appActions } from "../../../../store/features/app/app-slice";
import s from "./../bottomSection.module.css";

export const BuyAdditionsProduct = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const show: boolean = useSelector(
    (state: RootState) => state.app.isOpenAdditionalProduct
  );

  const products: Array<ProductType> = useSelector(
    (state: RootState) => state.product.products
  );
  const additionalProductList: Array<ProductType> = [...products].filter(
    (prod) => prod.expanding
  );
  useEffect(() => {
    products.length === 0 && dispatch(getProductsThunk());
  }, []);

  const onCloseClickHandler = () =>
    dispatch(appActions.changeShowAdditionalProduct(false));

  return (
    <Modal
      show={show}
      theRef={ref}
      onChange={onCloseClickHandler}
      title="Здесь можно приобрести доп. пакеты."
      className={s.AdditionsProduct}
    >
      <Tariff product={additionalProductList[0]} />
    </Modal>
  );
};
