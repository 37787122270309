import {
  UserName,
  UserType,
} from "../../../store/features/profile/profile-type";
import { AxiosResponse } from "axios";
import { instance } from "../instance";
import { OnboardingStatusVariant } from "../../../store/features/app/app-types";

export const profileAPI = {
  getProfile: (): Promise<AxiosResponse<PostResponseType>> => {
    return instance.get("profile/profile");
  },
  changeUsername: (
    body: UserName
  ): Promise<AxiosResponse<PostResponseType>> => {
    return instance.post("profile/changeUsername", body);
  },
  changeStatusEducation: (body: {
    status_education: OnboardingStatusVariant;
  }): Promise<AxiosResponse<PostResponseType>> => {
    return instance.post("profile/change_status_education", body);
  },
};

export type PostResponseType = {
  data: UserType;
  message: string;
};
