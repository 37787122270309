import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";

import { clsx } from "clsx";

import s from "./button.module.scss";
import { ButtonVariant } from "../../store/storeConst/enums";

export type ButtonProps<T extends ElementType = "button"> = {
  as?: T;
  children?: ReactNode;
  variant?: ButtonVariant;
  fullWidth?: boolean;
  className?: string;
  img?: string;
} & ComponentPropsWithoutRef<T>;

export const Button = <T extends ElementType = "button">(
  props: ButtonProps<T>
) => {
  const {
    as: Component = "button",
    variant = ButtonVariant.Primary,
    fullWidth,
    className,
    children,
    img,
    ...otherProps
  } = props;

  const classNames = {
    root: clsx(s[variant], fullWidth && s.fullWidth, img && s.img, className),
  };

  return (
    <Component className={classNames.root} {...otherProps}>
      {children}
      {img && <img src={img} alt="" />}
    </Component>
  );
};
