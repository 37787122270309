import { toast } from "react-toastify"

export enum apiErrorCodes {
  AccessTokenInvalidCode = 498,
  AccessTokenInvalidMessage = "",
  NotFoundItem = 404,
  serverErrorCode = 503,
  categoryHasNotPaid = 403,
}

export type StandartApiErrorMessageType = { code: number, message: string }
export const standartApiErrorMessage = (props: StandartApiErrorMessageType) => {
  toast.error('Что-то пошло не так... Попробуйте еще раз! А если снова не сработает, обратитесь в техподдержку @aipr_support !')
}
