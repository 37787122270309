import React from "react";
import { TemplatePage } from "./template-page";
import serverErrorImg from "../../assets/images/503 Error Service Unavailable.png";
import notFoundPageImg from "../../assets/images/404 Page not Found.png";
import { Paths } from "../../common/router/paths";

export const ServerNotConnected = () => {
  return (
    <TemplatePage
      img={serverErrorImg}
      text={
        "Пиарошная взяла короткий перерыв на полировку словесных шедевров. Мы шепчем новые формулы на ушко нейросетям, и вот-вот ваши тексты зазвучат по-новому. Дайте нам 10-15 минут ;)"
      }
      buttonPath={Paths.mainPage}
      buttonText={"Обновить страницу"}
    />
  );
};

export const NotFoundPage = () => {
  return (
    <TemplatePage
      img={notFoundPageImg}
      text={"Страница не найдена"}
      buttonPath={Paths.mainPage}
      buttonText={"Перейти на главную страницу"}
    />
  );
};
