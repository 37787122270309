import { SVGProps, Ref, forwardRef, memo } from "react";
export const IconCat2 = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        id="Vector"
        d="M44 88C46.8696 88 49.2212 85.8898 49.6196 82.9032C53.5651 56.3041 57.1919 52.6009 82.7792 49.6943C85.7281 49.3359 88 46.8672 88 44.0002C88 41.0932 85.7682 38.7041 82.8189 38.2661C57.3913 34.7222 54.2429 31.6163 49.6196 5.05723C49.1015 2.11058 46.8296 0 44 0C41.0907 0 38.7788 2.11058 38.3008 5.09684C34.4349 31.6563 30.8081 35.3594 5.26082 38.2661C2.23181 38.6645 0 41.0535 0 44.0002C0 46.8672 2.15213 49.2562 5.18114 49.6943C30.6484 53.3178 33.7571 56.3837 38.3008 82.9432C38.8985 85.9294 41.21 88 44 88Z"
        fill="url(#paint0_linear_622_8024)"
        stroke="none"
      />
      <defs>
        <linearGradient
          id="paint0_linear_622_8024"
          x1="44"
          y1="0"
          x2="44"
          y2="88"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  ))
);
