import React, { useEffect } from "react";
import { Container } from "../../../../components/container/container";
import { Button } from "../../../../components/button/button";
import { ButtonVariant } from "../../../../store/storeConst/enums";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../../common/router/paths";
import s from "./change-password-page.module.scss";
import { useAppDispatch } from "../../../../store/store";

import {
  ChangePasswordFormType,
  useChangePasswordForm,
} from "./change-password-form";
import { ControlledTextField } from "../../../../components/new-text-field/controlled-text-field";
import { postChangePasswordThunk } from "../../../../store/features/auth/authThunk";
import { motion } from "framer-motion";
import { animation } from "../../../../assets/animation/animate";

export const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const goBack = () => navigate(Paths.profile);
  const onSubmit = (data: ChangePasswordFormType) => {
    const { confirmPassword, ...resData } = data;
    dispatch(postChangePasswordThunk(resData)).then(() =>
      navigate(Paths.profile)
    );
  };
  useEffect(() => reset(), []);

  const { control, reset, handleSubmit } = useChangePasswordForm();

  return (
    <Container>
      <motion.form
        variants={animation.up}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.2 }}
        onSubmit={handleSubmit(onSubmit)}
        className={s.Form}
      >
        <Container color={"first"} className={s.PasswordsContainer}>
          <ControlledTextField
            name={"oldPassword"}
            control={control}
            label="Старый пароль"
            type={"password"}
            autoComplete={"off"}
          />
          <ControlledTextField
            name={"newPassword"}
            control={control}
            label="Новый пароль"
            type={"password"}
            autoComplete="off"
          />
          <ControlledTextField
            name={"confirmPassword"}
            control={control}
            label="Подтвердите новый пароль"
            type={"password"}
            autoComplete="off"
          />
        </Container>
        <div className={s.ButtonsContainer}>
          <Button
            type={"button"}
            onClick={goBack}
            variant={ButtonVariant.Secondary}
          >
            Назад
          </Button>
          <Button type={"submit"}>Сохранить</Button>
        </div>
      </motion.form>
    </Container>
  );
};
