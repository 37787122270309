import { useSelector } from "react-redux";
import { CategoryTypeGpt, QuestionType } from "../../../../store/features/questionTypes";
import { RootState, useAppDispatch } from "../../../../store/store";
import whiteStarIcon from "../../../../assets/icons/contentPage/white_star_icon.png";
import whitePlusIcon from "../../../../assets/icons/contentPage/white_plus_icon.png";
import whiteRefreshIcon from "../../../../assets/icons/contentPage/white_refresh_icon.png";
import { useState } from "react";
import CreateNewTemplate from "../templates/createTemplate/createTemplate";
import { useParams } from "react-router-dom";
import MyTemplates from "../templates/myTemplates/myTemplates";
import { Button } from "../../../../components/button/button";
import { ButtonVariant } from "../../../../store/storeConst/enums";
import { tempChangeQuestionsAC } from "../../../../store/features/questionSlice";
import { toast } from "react-toastify";
import { SendToGptButton } from "../send-to-gpt-button/send-button";

import s from "./questionsButtons.module.css";

type PropsType = {
  type_gpt: CategoryTypeGpt
  currentCategoryId: string
  imageForVision: File | null
  selectModel: string
}

const QuestionsButtons = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const categoryId: string = params.categoryId || "";

  const questions: Array<QuestionType> = useSelector(
    (state: RootState) => state.question.questions
  );
  const [isShowCreateTemplate, setIsShowCreateTemplate] =
    useState<boolean>(false);
  const [isShowMyTemplates, setIsShowMyTemplates] = useState<boolean>(false);

  const clearButtonClickHandler = () => {
    if (categoryId.length > 0) {
      dispatch(
        tempChangeQuestionsAC(
          questions.map((q) => {
            return { ...q, answer: "" };
          })
        )
      );
    }
  };

  const onCreateBtnClickHandler = () => {
    const isAllFilled: boolean = questions.every((q) => {
      if (q.isRequired === false) {
        return true;
      } else {
        return q.answer !== "" && q.answer !== null;
      }
    });

    if (isAllFilled) {
      setIsShowCreateTemplate(true);
    } else {
      toast.error(
        "Для создания шаблона необходимо заполнить ответы на обязательные вопросы!"
      );
    }
  };

  const onShowTemplatesBtnClickHandler = () => setIsShowMyTemplates(true);

  return (
    <>
      <CreateNewTemplate
        show={isShowCreateTemplate}
        onCloseFunction={() => setIsShowCreateTemplate(false)}
      />
      <MyTemplates
        show={isShowMyTemplates}
        onCloseFunction={() => setIsShowMyTemplates(false)}
      />
      <div className={s.lowerButtonsDiv}>
        <SendToGptButton 
          type_gpt={props.type_gpt} 
          imageForVision={props.imageForVision} 
          currentCategoryId={props.currentCategoryId} 
          // isThisCatAnswerLoading={false} 
          selectModel={props.selectModel} 
        />
        <Button
          variant={ButtonVariant.Secondary}
          onClick={onCreateBtnClickHandler}
          img={whitePlusIcon}
        >
          Создать шаблон
        </Button>
        <Button
          variant={ButtonVariant.Secondary}
          onClick={onShowTemplatesBtnClickHandler}
          img={whiteStarIcon}
        >
          Мои шаблоны
        </Button>
        <Button
          variant={ButtonVariant.Secondary}
          onClick={clearButtonClickHandler}
          img={whiteRefreshIcon}
        >
          Очистить
        </Button>
      </div>
    </>
  );
};

export default QuestionsButtons;
