import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import * as RadixCheckbox from "@radix-ui/react-checkbox";

import s from "./checkbox.module.scss";
import { Typography } from "../typography/Typography";
import { CheckIcon } from "../../assets/icons/check-icon";
import { TypographyVariant } from "../../store/storeConst/enums";
import { clsx } from "clsx";

type PositionType = "default" | "left";

export type CheckboxProps = {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  id?: string;
  label?: string;
  position?: PositionType;
} & Omit<ComponentPropsWithoutRef<typeof RadixCheckbox.Root>, "asChild">;

export const Checkbox = forwardRef<
  ElementRef<typeof RadixCheckbox.Root>,
  CheckboxProps
>(
  (
    {
      checked,
      onCheckedChange,
      disabled,
      name,
      label,
      id,
      position = "default",
      className,
      ...restProps
    },
    ref
  ): JSX.Element => {
    const classNames = {
      label: clsx(s.label, disabled && s.disabled, className),
      root: clsx(s.root),
      checkboxWrapper: clsx(
        s.checkboxWrapper,
        disabled && s.disabled,
        s[position]
      ),
      indicator: s.indicator,
    };

    return (
      <Typography
        className={classNames.label}
        variant={TypographyVariant.Body2}
        as="label"
      >
        <div className={classNames.checkboxWrapper}>
          <RadixCheckbox.Root
            ref={ref}
            className={classNames.root}
            id={id}
            name={name}
            checked={checked}
            onCheckedChange={onCheckedChange}
            disabled={disabled}
            required={restProps.required}
          >
            {checked && (
              <RadixCheckbox.Indicator
                className={classNames.indicator}
                forceMount
              >
                <CheckIcon size={1.2} />
              </RadixCheckbox.Indicator>
            )}
          </RadixCheckbox.Root>
        </div>
        {label}
      </Typography>
    );
  }
);
