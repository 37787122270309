import { useAppDispatch } from "../../../../../store/store";
import { addTextEditingAC, changeGptAnswerResponseTextAC, removeFullTextAnswerAC, removeTextEditingAC } from "../../../../../store/features/answerSlice";
import { ChangeOneAnswerResponseType } from "../../../../../store/features/answerTypes";

// import pencilIcon from "../../../../../assets/icons/gprResponse/pencil_short_icon.svg";
import pencilIcon from "../../../../../assets/icons/gprResponse/pencil_short_icon_2.svg";

import s from "./gptAnswerbuttons.module.css";

type PropsType = {
    isTextEditing: boolean
    isThisTextFull: boolean
    answerId: string
    newEditedText: string | undefined
}

export const EditAnswerButton = ({isTextEditing, isThisTextFull, answerId, newEditedText}: PropsType) => {

    const dispatch = useAppDispatch();

    const pencilIconClass: string = isTextEditing
    ? s.buttonSection + " " + s.buttonSectionSelected
    : s.buttonSection;

    const onPencilClickHandler = () => {
        if (isThisTextFull) {
          dispatch(removeFullTextAnswerAC(answerId));
        }
    
        if (isTextEditing) {
          const isConfirm = window.confirm("Сохранить изменения?");
          if (isConfirm) {
            const data: ChangeOneAnswerResponseType = {
              answerId: answerId,
              newAnswerText: newEditedText || "",
            };
            dispatch(changeGptAnswerResponseTextAC(data));
          }
          dispatch(removeTextEditingAC(answerId));
        } else {
          dispatch(addTextEditingAC(answerId));
        }
      };

    return (
        <div
          className={pencilIconClass}
          onClick={onPencilClickHandler}
          data-title="Редактировать"
        >
          <img alt="" src={pencilIcon} />
        </div>
    )
}