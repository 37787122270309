import Close from "../../assets/icons/close";
import s from "./close-botton.module.scss";
import { ButtonProps } from "../button/button";

export const CloseButton = ({ onClick }: ButtonProps) => {
  return (
    <button onClick={onClick} className={s.CloseButton}>
      <Close />
    </button>
  );
};
