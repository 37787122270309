import { TextField, TextFieldProps } from "./text-field";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

export type ControlledTextFieldProps<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
} & Omit<TextFieldProps, "onChange" | "value" | "id">;

export const ControlledTextField = <TFieldValues extends FieldValues>(
  props: ControlledTextFieldProps<TFieldValues>
) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <TextField
      {...props}
      {...field}
      errorMessage={error?.message}
      id={props.name}
    />
  );
};
