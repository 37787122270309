import { useSelector } from "react-redux";
import { ProductType } from "../../store/features/productTypes";
import s from "./tariffs.module.scss";
import { RootState, useAppDispatch } from "../../store/store";
import { useEffect } from "react";
import { getProductsThunk } from "../../store/features/productThunk";
import { Tariff } from "./tariff/tariff";
import CheckPromoCode from "./checkPromoCode/checkPromoCode";
import { Loader } from "../../components/loader/loader";
import { TypographyVariant } from "../../store/storeConst/enums";
import { Typography } from "../../components/typography/Typography";

export const Tariffs = () => {
  const dispatch = useAppDispatch();
  const isProductsLoading: boolean = useSelector(
    (state: RootState) => state.product.loadingVars.isProductLoading
  );

  const products: Array<ProductType> = useSelector(
    (state: RootState) => state.product.products
  );

  useEffect(() => {
    products.length === 0 && dispatch(getProductsThunk());
  }, []);

  return (
    <div className={s.productSectionWrapper}>
      {isProductsLoading ? (
        <Loader />
      ) : (
        <>
          <Typography variant={TypographyVariant.H1} as={"h1"}>
            Тарифы:
          </Typography>
          <CheckPromoCode />
          <div className={s.products_div + " " + s.prod_grid_areas}>
            {products.map((product) => {
              return <Tariff product={product} key={product.id} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};
