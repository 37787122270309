import { NewsType } from "../../../common/api/news/news-api";
import s from "./news-card.module.scss";
import { Typography } from "../../../components/typography/Typography";
import { TypographyVariant } from "../../../store/storeConst/enums";
import { ReadMoreButton } from "./read-more-button/read-more-button";

import { useAppDispatch } from "../../../store/store";
import { newsThunks } from "../../../store/features/news/news";

type NewsCardPropsType = {
  news: NewsType;
};
export const NewsCard = ({ news }: NewsCardPropsType) => {
  const { images, title, description } = news;
  const dispatch = useAppDispatch();
  const setOneNews = () => dispatch(newsThunks.getOneNews(news));
  return (
    <div className={s.card}>
      <div className={s.imgWrapper}>
        <img src={images![0].image} alt="" />
        {/*<NewsCardsIcon className={s.iconCards} />*/}
      </div>
      <div className={s.wrapper}>
        <div className={s.text}>
          <Typography className={s.title}>{title}</Typography>
          <Typography
            variant={TypographyVariant.Subtitle2}
            className={s.description}
          >
            {description}
          </Typography>
        </div>
        <ReadMoreButton onClick={setOneNews} />
      </div>
    </div>
  );
};
