import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ParametrInitContent } from "../storeConst/vars";
import { ParametrSliceType } from "./parametrTypes";

export const parametrSlice = createSlice({
  name: "parametrSlice",
  initialState: ParametrInitContent,
  reducers: {
    changeSideMenuSizeAC: (
      state: ParametrSliceType,
      action: PayloadAction<boolean>
    ) => {
      state.isSideMenuLarge = action.payload;
    },
    changeIsContentQuestionsShow: (
      state: ParametrSliceType,
      action: PayloadAction<boolean>
    ) => {
      state.contentBlockQuestionsShow = action.payload;
    },
  },
});
export const { changeSideMenuSizeAC, changeIsContentQuestionsShow } =
  parametrSlice.actions;

export default parametrSlice.reducer;
