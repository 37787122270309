import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef,
} from "react";

import * as RadixSelect from "@radix-ui/react-select";
import { clsx } from "clsx";

import s from "./Select.module.scss";

import { TypographyVariant } from "../../store/storeConst/enums";
import { Typography } from "../typography/Typography";
import { ArrowDownIcon } from "../../assets/icons/arrow-down-icon";
import { SelectItem } from "./selectItem/SelectItem";

export type SelectVariant = "default" | "multiple";

export type OptionType = {
  value: string;
  title: string;
  isChecked?: boolean;
};

export type SelectProps = {
  className?: string;
  fullWidth?: boolean;
  label?: string;
  options: OptionType[];
  placeholder?: ReactNode;
  variant?: SelectVariant;
  maxChecked?: number;
  onValueChangeMultiple?: (value: OptionType[]) => void;
} & ComponentPropsWithoutRef<typeof RadixSelect.Root>;

export const Select = forwardRef<
  ElementRef<typeof RadixSelect.Root>,
  SelectProps
>(
  (
    {
      className,
      disabled,
      fullWidth,
      label,
      onValueChange,
      options,
      maxChecked,
      placeholder = "Select value...",
      value,
      onValueChangeMultiple,
      variant = "default",
      ...restProps
    },
    ref
  ): JSX.Element => {
    const classNames = {
      content: clsx(s.content),
      icon: s.icon,
      label: clsx(s.text, disabled && s.disabled),
      trigger: clsx(s.trigger, fullWidth && s.fullWidth, className),
    };
    const disabledOptions =
      maxChecked ===
      options.reduce((num, op) => {
        if (op.isChecked) {
          return num + 1;
        }
        return num;
      }, 0);
    return (
      <RadixSelect.Root
        disabled={disabled}
        onValueChange={onValueChange}
        required={restProps.required}
        value={value}
      >
        {label && (
          <Typography
            as={"label"}
            className={classNames.label}
            variant={TypographyVariant.Body2}
          >
            {label}
          </Typography>
        )}
        <RadixSelect.Trigger
          aria-label={"select"}
          className={classNames.trigger}
          ref={ref}
        >
          <Typography className={s.text} variant={TypographyVariant.Body1}>
            {variant === "multiple" ? (
              placeholder
            ) : (
              <RadixSelect.Value
                className={s.value}
                placeholder={placeholder}
              />
            )}
          </Typography>
          <RadixSelect.Icon className={s.icon}>
            <ArrowDownIcon />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Portal>
          <RadixSelect.Content
            className={classNames.content}
            position={"popper"}
            ref={ref}
          >
            <RadixSelect.Viewport>
              {options.map((option) => {
                const checkedHandler = (value: boolean) => {
                  const newOptions: OptionType[] = options.map((op) =>
                    op.value === option.value
                      ? {
                          ...option,
                          isChecked: value,
                        }
                      : op
                  );
                  onValueChangeMultiple && onValueChangeMultiple(newOptions);
                };
                return (
                  <SelectItem
                    key={option.value}
                    onCheckedChange={checkedHandler}
                    value={option.value}
                    variant={variant}
                    title={option.title}
                    checked={option.isChecked}
                    disabled={!option.isChecked && disabledOptions}
                  />
                );
              })}
            </RadixSelect.Viewport>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
    );
  }
);
