import s from "./home.module.css";
import { CategoryType } from "../../store/features/questionTypes";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import CategoriesSections from "../../components/categorySections/CategoriesSections";
import { News } from "../news/news";
import { Typography } from "../../components/typography/Typography";
import { TypographyVariant } from "../../store/storeConst/enums";
import { DescriptionAreaOnHomePage } from "./descriptionArea";
import {
  CatIcon1,
  CatIcon2,
  CatIcon3,
  CatIcon4,
  CatIcon5,
  CatIcon6,
} from "../../assets/icons";
import { useEffect } from "react";
import { appSelectors } from "../../store/features/app/app-selectors";
import { appActions } from "../../store/features/app/app-slice";
const images = [
  <CatIcon1 />,
  <CatIcon2 />,
  <CatIcon3 />,
  <CatIcon4 />,
  <CatIcon5 />,
  <CatIcon6 />,
];
const HomePage = () => {
  const dispatch = useAppDispatch();
  const { tourActive, stepIndex } = useAppSelector(
    appSelectors.getOnboarding()
  );

  useEffect(() => {
    tourActive &&
      stepIndex === 9 && // Запускаем онбординг если он запущен при переходе на страницу.
      dispatch(appActions.setOnboarding({ run: true, stepIndex: 10 }));
  }, [dispatch, stepIndex, tourActive]);
  const mainPageCategories: Array<CategoryType> = useSelector(
    (state: RootState) => state.question.categories
  )
    .filter((cat) => cat.parentId === null || cat.parentId === undefined)
    .sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1));
  return (
    <div className={s.homeWrapper}>
      <Typography variant={TypographyVariant.H1}>Главная страница</Typography>
      <div className={s.news}>
        <Typography variant={TypographyVariant.H2}>Новости</Typography>
        <News />
      </div>
      <DescriptionAreaOnHomePage />
      <div>
        <Typography variant={TypographyVariant.H2}>Категории</Typography>
        <CategoriesSections images={images} categories={mainPageCategories} />
      </div>
    </div>
  );
};

export default HomePage;
