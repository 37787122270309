import { configureStore, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import questionSlice from "./features/questionSlice";
import parametrSlice from "./features/parametrSlice";
import answerSlice from "./features/answerSlice";
import productSlice from "./features/productSlice";
import { profileReducer } from "./features/profile/profile-slice";
import { appReducer } from "./features/app/app-slice";
import { newsReducer } from "./features/news/news";

export const store = configureStore({
  reducer: {
    app: appReducer,
    question: questionSlice,
    parametr: parametrSlice,
    answer: answerSlice,
    product: productSlice,
    news: newsReducer,
    profile: profileReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type ThunkDispatchType = ThunkDispatch<RootState, any, AnyAction>;
export type AppDispatchType = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<ThunkDispatchType>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
