import { useParams } from "react-router-dom";
import { CategoryType } from "../../store/features/questionTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import s from "./contentBlockParentCategory.module.css";
import CategoriesSections from "../../components/categorySections/CategoriesSections";
import CategoryBackButton from "./backButton/backButton";
import { Paths } from "../../common/router/paths";
import { Typography } from "../../components/typography/Typography";
import { TypographyVariant } from "../../store/storeConst/enums";
import {
  IconCat1,
  IconCat2,
  IconCat3,
  IconCat4,
  IconCat5,
  IconCat6,
} from "../../assets/icons/category/childrenCategory";

const images = [
  <IconCat1 />,
  <IconCat2 />,
  <IconCat3 />,
  <IconCat4 />,
  <IconCat5 />,
  <IconCat6 />,
];
const ContentBlockParentCategory = () => {
  const param = useParams();
  const { categoryId } = param;

  const categories: Array<CategoryType> = useSelector(
    (state: RootState) => state.question.categories
  );
  const childrenCategories: Array<CategoryType> = categories
    .filter((cat) => cat.parentId === categoryId)
    .filter((c) => c.parentId === categoryId);
  const ourCategory: CategoryType | undefined =
    categories.find((cat) => cat.id === categoryId) || undefined;

  return (
    <div className={s.parentCatWrapper}>
      <div className={s.title_with_back_button}>
        <CategoryBackButton path={Paths.mainPage} />
        <Typography
          className={s.Title}
          variant={TypographyVariant.H1}
          as={"h1"}
        >
          {ourCategory?.title}
        </Typography>
      </div>
      <Typography variant={TypographyVariant.Body1} className={s.Description}>
        {ourCategory?.description}
      </Typography>

      <CategoriesSections images={images} categories={childrenCategories} />
    </div>
  );
};

export default ContentBlockParentCategory;
