import { RootState } from "../../store";
export const user = () => (state: RootState) => state.profile.user;
export const avatarsList = () => (state: RootState) =>
  state.profile.profileImages;

export const userName = () => (state: RootState) => state.profile.user.username;
export const userAvatar = () => (state: RootState) =>
  state.profile.user.avatar_src;

export const userAccess = () => (state: RootState) => state.profile.user.access;
