import s from "./historySearch.module.css";
import arrowIconUp from "../../../assets/icons/contentPage/arrow_up.svg";
import arrowIconDown from "../../../assets/icons/contentPage/arrow_down.svg";
import { useState } from "react";
import { LineTextField1 } from "../../../components/textField/lineTextField";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { GptHistorySearchVarsType } from "../../../store/features/answerTypes";
import {
  changeDateEndSearchAC,
  changeDateStartSearchAC,
  changeGptTextSearchAC,
  changeHistorySearchFavAC,
  changeUserAnsSearchAC,
} from "../../../store/features/answerSlice";
import { GreenLightV1 } from "../../../components/greenLight/greenLight";
import DateField from "../../../components/textDateField/dateField";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import RadioButtons, {
  OneRadioOption,
} from "../../../components/radioButtons/radionButtons";
import { HISTORY_SEARCH_FAV_VALUES } from "../../../store/storeConst/consts";

const HistorySearch = () => {
  const dispatch = useAppDispatch();
  const [isShow, setIsShow] = useState<boolean>(false);
  const arrowIcon = isShow ? arrowIconUp : arrowIconDown;
  const gptHistorySearchVars: GptHistorySearchVarsType = useSelector(
    (state: RootState) => state.answer.gptHistorySearchVars
  );

  const isSearchOn: boolean = Object.values(gptHistorySearchVars).some((el) => {
    if (el === HISTORY_SEARCH_FAV_VALUES.all) return false;
    return el && el.length > 0;
  });

  const historySearchFavOptions: Array<OneRadioOption> = [
    { value: HISTORY_SEARCH_FAV_VALUES.all, text: "Все" },
    { value: HISTORY_SEARCH_FAV_VALUES.fav, text: "Избранные" },
    { value: HISTORY_SEARCH_FAV_VALUES.notFav, text: "Не избранные" },
  ];
  const onHistorySearchFavoritesChangeHandler = (newValue: string) => {
    dispatch(changeHistorySearchFavAC(newValue));
  };

  return (
    <div className={s.search_wrapper}>
      <div className={s.history_head}>
        <div className={s.imgDiv}>
          <img alt="" src={arrowIcon} onClick={() => setIsShow(!isShow)} />
        </div>
        <h4>Поиск:</h4>
        {isSearchOn && <GreenLightV1 />}
      </div>

      {isShow && (
        <div className={s.all_search_options}>
          <div className={s.one_search_option}>
            <label>Поиск по тексту ответа GPT:</label>
            <LineTextField1
              classForInput={s.textFieldClass}
              type="text"
              text={gptHistorySearchVars.gptTextSearch}
              onChangeFunction={(txt: string) =>
                dispatch(changeGptTextSearchAC(txt))
              }
            />
          </div>

          <div className={s.one_search_option}>
            <label>Поиск по тексту ответа Пользователя:</label>
            <LineTextField1
              classForInput={s.textFieldClass}
              type="text"
              text={gptHistorySearchVars.userAnswerTextSearch}
              onChangeFunction={(txt: string) =>
                dispatch(changeUserAnsSearchAC(txt))
              }
            />
          </div>

          <div className={s.one_search_option}>
            <label>Поиск по дате начала:</label>
            <DateField
              calClass={s.textFieldClass}
              value={gptHistorySearchVars.dateStartSearch}
              onChangeFunction={(newDate: string | null) =>
                dispatch(changeDateStartSearchAC(newDate))
              }
            />
          </div>

          <div className={s.one_search_option}>
            <label>Поиск по дате конца:</label>
            <DateField
              calClass={s.textFieldClass}
              value={gptHistorySearchVars.dateEndSearch}
              onChangeFunction={(newDate: string | null) =>
                dispatch(changeDateEndSearchAC(newDate))
              }
            />
          </div>

          <div className={s.one_search_option}>
            <label>Избранные:</label>
            <RadioButtons
              options={historySearchFavOptions}
              checkedValue={gptHistorySearchVars.favoriteSearch}
              onChangeFunction={(newValue: string) =>
                onHistorySearchFavoritesChangeHandler(newValue)
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HistorySearch;
