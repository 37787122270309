export enum OnboardingStatusVariant {
  Launched = "reject",
  NotStarted = "not_started",
  Later = "later",
  Completed = "completed",
}

export enum OnboardingVariant {
  First = "first",
  ActiveTariff = "active_tariff",
  WithoutTariff = "without_tariff",
}

export type OnboardingType = {
  run: boolean;
  stepIndex: number;
  tourActive: boolean;
  status_education?: OnboardingStatusVariant;
  variantEducation?: OnboardingVariant;
};

export type AppSliceType = {
  isLoading: boolean;
  isOpenSideBar: boolean;
  openedCategories: string[];
  isOpenAdditionalProduct: boolean;
  onboarding: OnboardingType;
  promoCode: string;
};
