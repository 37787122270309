import uuid from "react-uuid"
import clsx from "clsx"
import { useResize } from "../../common/hooks/use-resize/useResize"

import s from "./popup.module.css"

type PropsType = {
    msgText: Array<string>
    isChangePosition?: boolean
}

export const PopupMessage = (props: PropsType) => {

    const devWidth = useResize()
    console.log('devWidth.width=', devWidth.width, devWidth.width < 690);
    

    const popupClass: string = clsx(

        s.popup_message_wrapper,

        devWidth.width < 690
            ? s.popup_message_mobile
            : s.popup_message_desctop,

        props.isChangePosition 
            ? devWidth.width < 690 
                ? s.popup_message_translate_mobile
                : s.popup_message_translate_desctop
            : ""

    )

    return (
        <div className={popupClass}>
            {props.msgText.length > 0 && props.msgText.map(m => {
                return (
                    <div key={uuid()}>
                        {m}
                    </div>
                )
            })}
        </div>
    )
}