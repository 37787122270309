import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AppSliceType,
  OnboardingStatusVariant,
  OnboardingType,
  OnboardingVariant,
} from "./app-types";
import { fetchProfile } from "../profile/profile-thunk";

const initialState: AppSliceType = {
  isLoading: true, // Начальная загрузка при иницилизации App
  isOpenAdditionalProduct: false,
  isOpenSideBar: localStorage.getItem("isOpenSideBar")
    ? JSON.parse(localStorage.getItem("isOpenSideBar")!)
    : window.innerWidth >= 1100,
  openedCategories: localStorage.getItem("categories")
    ? JSON.parse(localStorage.getItem("categories")!)
    : [],
  onboarding: {
    run: false,
    stepIndex: 0,
    tourActive: false,
  },
  promoCode: "",
};

const slice = createSlice({
  name: "appSlice",
  initialState: initialState,
  reducers: {
    setOnboarding(state, action: PayloadAction<Partial<OnboardingType>>) {
      state.onboarding = Object.assign({}, state.onboarding, action.payload);
    },
    changeShowAdditionalProduct(state, action: PayloadAction<boolean>) {
      state.isOpenAdditionalProduct = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setPromo(state, action: PayloadAction<string>) {
      state.promoCode = action.payload;
    },
    toggleSideBar(state, action: PayloadAction<boolean>) {
      state.isOpenSideBar = action.payload;
      localStorage.setItem("isOpenSideBar", action.payload.toString());
    },
    setOpenedCategories(state, action: PayloadAction<{ id: string }>) {
      const categories = state.openedCategories;
      const index = categories.indexOf(action.payload.id);
      if (index === -1) {
        categories.push(action.payload.id);
      } else {
        categories.splice(index, 1);
      }
      localStorage.setItem("categories", JSON.stringify(categories));
    },
    setOpenOneCategory(state, action: PayloadAction<{ id: string }>) {
      state.openedCategories = [action.payload.id];
      localStorage.setItem("categories", JSON.stringify([action.payload.id]));
    },
    setOpenedSupport(state, action: PayloadAction<{ id: string }>) {
      state.openedCategories = [action.payload.id];
      localStorage.setItem("categories", JSON.stringify([action.payload.id]));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      const status = action.payload.status_education;
      const haveSymbols =
        action.payload.access && action.payload.access.remainingUses > 0;
      const variant = () => {
        if (
          (!haveSymbols && !action.payload.has_start_promo_been_used) ||
          (state.onboarding.tourActive &&
            state.onboarding.variantEducation === OnboardingVariant.First)
        ) {
          return OnboardingVariant.First;
        }
        if (haveSymbols) {
          return OnboardingVariant.ActiveTariff;
        }
        if (!haveSymbols && action.payload.has_start_promo_been_used) {
          return OnboardingVariant.WithoutTariff;
        }
      };
      state.onboarding = {
        ...state.onboarding,
        status_education:
          status === OnboardingStatusVariant.Launched
            ? OnboardingStatusVariant.Later
            : status,
        run:
          status === OnboardingStatusVariant.NotStarted ||
          state.onboarding.tourActive,
        tourActive:
          status === OnboardingStatusVariant.NotStarted ||
          state.onboarding.tourActive,
        variantEducation: variant(),
      };
    });
  },
});

export const appReducer = slice.reducer;

export const appActions = slice.actions;
