import s from "./avatar.module.scss";
import { ComponentPropsWithoutRef } from "react";
import clsx from "clsx";
import Camera from "../../assets/icons/camera";
import { Typography } from "../typography/Typography";
import { TypographyVariant } from "../../store/storeConst/enums";

export type AvatarProps = {
  image?: string;
  size?: "small" | "large" | "middle";
} & ComponentPropsWithoutRef<"img">;

export const Avatar = ({
  image,
  size,
  className,
  onClick,
  ...restProps
}: AvatarProps) => {
  return (
    <div
      {...restProps}
      className={clsx(
        className,
        s.Avatar,
        size && s[size],
        s.Image,
        image && s.WithoutPhoto
      )}
      onClick={onClick}
    >
      {image ? (
        <img src={image} {...restProps} alt="" />
      ) : (
        <div className={s.Change}>
          <Camera />
          {size !== "small" && (
            <Typography variant={TypographyVariant.Body2}>
              Изменить фото
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};
