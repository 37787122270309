import { SVGProps, Ref, forwardRef, memo } from "react";
import { motion } from "framer-motion";
export const CatIcon3 = motion(
  memo(
    forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
      return (
        <svg
          width="24"
          height="22"
          viewBox="0 0 24 22"
          fill="none"
          {...props}
          ref={ref}
        >
          <g id="&#240;&#159;&#166;&#134; icon &#34;news&#34;">
            <g id="Group">
              <path
                id="Vector"
                fill="none"
                d="M5.27778 14.9289V5.44444C5.27778 3.34889 5.27778 2.30222 5.994 1.65111C6.71022 1 7.86156 1 10.1667 1H18.1111C20.4162 1 21.5676 1 22.2838 1.65111C23 2.30222 23 3.34889 23 5.44444V16.5556C23 18.6511 23 19.6978 22.2838 20.3489C21.5676 21 20.4162 21 18.1111 21H5.88889C3.58378 21 2.43244 21 1.71622 20.3489C1 19.6978 1 18.6511 1 16.5556V7M17.5 13.7778H10.1667M17.5 17.1111H10.1667"
                strokeWidth="1.3"
                strokeLinecap="round"
              />
              <path
                id="Vector_2"
                fill="none"
                d="M9.55566 7.11138C9.55566 6.0636 9.55566 5.54027 9.91378 5.21472C10.2719 4.88916 10.8476 4.88916 12.0001 4.88916H15.6668C16.8193 4.88916 17.395 4.88916 17.7531 5.21472C18.1112 5.54027 18.1112 6.0636 18.1112 7.11138V7.66694C18.1112 8.71472 18.1112 9.23805 17.7531 9.5636C17.395 9.88916 16.8193 9.88916 15.6668 9.88916H12.0001C10.8476 9.88916 10.2719 9.88916 9.91378 9.5636C9.55566 9.23805 9.55566 8.71472 9.55566 7.66694V7.11138Z"
                strokeWidth="1.3"
                strokeLinecap="round"
              />
            </g>
          </g>
        </svg>
      );
    })
  )
);
