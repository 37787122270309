import { SVGProps, Ref, forwardRef, memo } from "react";
import { motion } from "framer-motion";
export const CatIcon6 = motion(
  memo(
    forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
      return (
        <svg width="24" height="19" viewBox="0 0 24 19" {...props} ref={ref}>
          <g id="&#240;&#159;&#166;&#134; icon &#34;hammer&#34;">
            <g id="megaphone">
              <path
                id="Vector"
                d="M3.87167 4.01503L15.3705 1.0668C15.6955 0.984797 16.0348 0.978201 16.3627 1.04752C16.6906 1.11684 16.9983 1.26024 17.2624 1.46677C17.5265 1.6733 17.7399 1.93751 17.8864 2.23921C18.0329 2.54092 18.1087 2.87216 18.1078 3.20763V13.4347C18.1087 13.7701 18.0329 14.1014 17.8864 14.4031C17.7399 14.7048 17.5265 14.969 17.2624 15.1755C16.9983 15.3821 16.6906 15.5255 16.3627 15.5948C16.0348 15.6641 15.6955 15.6575 15.3705 15.5755L3.87167 12.6273C3.05696 12.4463 2.32832 11.9924 1.80618 11.3407C1.28404 10.6889 0.999649 9.87836 1 9.04291V7.59938C0.999649 6.76393 1.28404 5.95337 1.80618 5.30164C2.32832 4.64992 3.05696 4.19604 3.87167 4.01503Z"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_2"
                d="M4.66699 13.2146V15.0496C4.66699 15.8607 4.98885 16.6386 5.56177 17.2122C6.13469 17.7857 6.91173 18.1079 7.72196 18.1079C8.53218 18.1079 9.30922 17.7857 9.88214 17.2122C10.4551 16.6386 10.7769 15.8607 10.7769 15.0496V14.4379"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_3"
                d="M21.7734 5.07983C22.5638 5.9744 23 7.12741 23 8.32166C23 9.5159 22.5638 10.6689 21.7734 11.5635"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </svg>
      );
    })
  )
);
