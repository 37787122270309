import React, { useRef } from "react";
import s from "./full-news.module.scss";
import { Typography } from "../../../../components/typography/Typography";
import {
  ButtonVariant,
  TypographyVariant,
} from "../../../../store/storeConst/enums";
import { Scrollbar } from "../../../../components/scrollbar";

import { Modal } from "../../../../components/modal/modal";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  newsActions,
  newsSelector,
} from "../../../../store/features/news/news";
import { Button } from "../../../../components/button/button";
import { formattedDate } from "../../../../common/hooks/formattedDate";
import { LinkIcon } from "../../../../assets/icons/linkIcon";
import Slider from "react-slick";
import "./slider.scss";

const carouselSettings = {
  dots: true,
  infinite: true,
  initialSlide: 0,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
};
export const FullNews = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const close = () => dispatch(newsActions.closeOneNews());
  const news = useAppSelector(newsSelector.oneNews());
  const { image, title, description, created_data, link_text, link_address } = {
    ...news.news!,
  };
  const formattedDateTime = formattedDate({ date: created_data!, time: false });

  const slider =
    news.news && news.news.image && news.news.image.length > 1 ? (
      // @ts-ignore
      <Slider arrows={false} {...carouselSettings}>
        {image!.map((img) => (
          <div className={s.imgWrapper} key={img.id}>
            <img alt={""} src={img.image} />
          </div>
        ))}
      </Slider>
    ) : (
      <div className={s.imgWrapper}>
        <img
          alt={""}
          src={
            news?.news?.images
              ? news!.news!.images[0].image
              : news?.news?.image![0].image
          }
        />
      </div>
    );

  return (
    <Modal
      isClose={false}
      className={s.Modal}
      theRef={ref}
      show={news.isShow}
      onChange={close}
    >
      <Scrollbar className={s.Scroll}>
        <div className={s.news}>
          <div className={s.sliderWrapper}>{slider}</div>
          <Typography className={s.title} variant={TypographyVariant.H2}>
            {title}
          </Typography>
          <Typography variant={TypographyVariant.Body1}>
            {description}
          </Typography>
        </div>
      </Scrollbar>
      <div className={s.bottom}>
        <div className={s.bottomContainer}>
          <Typography variant={TypographyVariant.Body1}>
            {formattedDateTime}
          </Typography>
          {link_address && (
            <a className={s.linkContainer} href={link_address}>
              <LinkIcon />
              {link_text}
            </a>
          )}
        </div>
        <Button variant={ButtonVariant.Secondary} onClick={close}>
          Закрыть
        </Button>
      </div>
    </Modal>
  );
};
