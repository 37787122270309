import { toast } from "react-toastify";
import { Button } from "../../../../components/button/button"
import { RootState, useAppDispatch, useAppSelector } from "../../../../store/store";
import { CategoryTypeGpt, ChangingOneQuestionType } from "../../../../store/features/questionTypes";
import { addWhichCategoryGptLoadingAC } from "../../../../store/features/answerSlice";
import { postReceiveGptThunkV2, sendVisionGPTThunk } from "../../../../store/features/answerThunk";
import { useSelector } from "react-redux";

import send from "../../../../assets/icons/send.svg";

type PropsType = {
    type_gpt: CategoryTypeGpt
    imageForVision: File | null
    currentCategoryId: string
    // isThisCatAnswerLoading: boolean
    selectModel: string
    mobileSwitch?: () => void;
}

export const SendToGptButton = (props: PropsType) => {

    const dispatch = useAppDispatch();
    const questions = useAppSelector((state) => state.question.questions);
    const whichCategoryGptAnswerLoading: Array<string> = useSelector(
        (state: RootState) => state.answer.loadingVars.whichCategoryGptAnswerLoading
      );
      const isThisCatAnswerLoading: boolean = //isGptAnswerLoading &&
        whichCategoryGptAnswerLoading.some((el) => props.currentCategoryId === el);

    const onSendToGPRClickHandler = () => {
        const condition: boolean = questions.some(
            (q) => q.isRequired && (q.answer === "" || q.answer === null)
        );
        if (condition) {
            toast.error("Не все обязательные поля заполнены");
        } else if (props.type_gpt === "gpt_vision" && !props.imageForVision) {
            toast.error("Вставьте изображение");
        } else {
            dispatch(addWhichCategoryGptLoadingAC(props.currentCategoryId));

            const userAnswers: Array<ChangingOneQuestionType> = [...questions].map(
            (q) => {
                return { question_id: q.id, answer: q.answer };
            }
            );
            props.mobileSwitch && props.mobileSwitch();
            props.type_gpt === "gpt_vision"
            ? dispatch(
                sendVisionGPTThunk({
                    image: props.imageForVision!,
                    request_data: {
                    category_id: props.currentCategoryId,
                    answers: userAnswers,
                    },
                })
                )
            : dispatch(
                postReceiveGptThunkV2({
                    question_answer: userAnswers,
                    category_id: {
                    categoryId: props.currentCategoryId,
                    model_gpt_id: props.selectModel && props.selectModel?.length > 0 ? props.selectModel : "",
                    },
                })
                );
        }
    };

    return (
        // <div>
        <Button
          id={"generateTextButton"}
        //   className={s.button}
          onClick={onSendToGPRClickHandler}
          img={send}
          disabled={isThisCatAnswerLoading}
        >
          Генерировать текст
        </Button>
        // </div>
    )
}