import { useEffect, useState } from "react";
import { CategorySelectionType, ProductType } from "../../../store/features/productTypes";
import s from "./tariff.module.scss";
import { CategoryType } from "../../../store/features/questionTypes";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { Button } from "../../../components/button/button";
import { Typography } from "../../../components/typography/Typography";
import { TypographyVariant } from "../../../store/storeConst/enums";
import { OptionType, Select } from "../../../components/select/Select";
import {
  PayProductRequestType,
  payProductThunk,
} from "../../../store/features/productThunk";
import { yandexMetrikaPayTariffClickEvent } from "../../../common/utils/yadexMetrikaCounter";
import { toast } from "react-toastify";

type DisplayProductPropsType = { product: ProductType };

export const Tariff = ({ product }: DisplayProductPropsType) => {
  const {
    id,
    newPrice,
    categoriesSize,
    priceRubbles,
    title,
    description,
    usageCount,
    availabilityDurationDays,
  } = product;
  const dispatch = useAppDispatch();
  const promoCode: string = useSelector(
    (state: RootState) => state.product.promoCode
  );
  const [promoPrice, setPromoPrice] = useState<number | undefined>(newPrice);
  useEffect(() => {
    setPromoPrice(newPrice);
  }, [newPrice]);

  const categories: Array<CategoryType> = useSelector(
    (state: RootState) => state.question.categories
  );
  const parentCategories: Array<CategoryType> = [...categories].filter(
    (c) => !c.parentId
  );

  const categorySelection: Array<CategorySelectionType> = useSelector((state: RootState) => 
    state.product.categoriesSelection
  )
  const initOptionsList: OptionType[] = categorySelection.map((c) => {
    return {
      value: c.id,
      title: c.title,
      isChecked: false,
    };
  });

  const [optionsList, setOptionsList] = useState<OptionType[]>(initOptionsList);

  const isAllCategoriesIncluded: boolean =
    categoriesSize >= initOptionsList.length || categoriesSize === 0;

  const onPayProductClickHandler = () => {
    const categoriesIncluded = () => {
      if (categoriesSize === 0) {
        return [];
      } else if (categoriesSize >= 5) {
        return parentCategories.slice(1).map((c) => c.id);
      }
      return optionsList.filter((el) => el.isChecked).map((el) => el.value);
    };
    const productToPay: PayProductRequestType = {
      product_id: id,
      promo_code: promoCode.length > 0 ? promoCode : null,
      category_ids: categoriesIncluded(),
    };
    if (categoriesIncluded().length < categoriesSize) {
      toast.error("Выберите категории");
    } else {
      yandexMetrikaPayTariffClickEvent(id);
      dispatch(payProductThunk(productToPay));
    }
  };
  return (
    <div className={s.tariff}>
      <div className={s.containerOne}>
        <Typography className={s.title} variant={TypographyVariant.Large}>
          {title}
        </Typography>
        <div>
          <Typography>Кол-во дней: {availabilityDurationDays} дней</Typography>
          <Typography> Кол-во знаков: {usageCount}</Typography>
          <Typography>{description}</Typography>
        </div>
        {!isAllCategoriesIncluded && (
          <Select
            placeholder={"Выбрать категории"}
            variant={"multiple"}
            options={optionsList}
            onValueChangeMultiple={setOptionsList}
            maxChecked={categoriesSize}
          />
        )}
      </div>
      <div className={s.containerTwo}>
        <div className={s.price}>
          {promoPrice && (
            <Typography
              className={promoPrice && s.newPrice}
              variant={TypographyVariant.Large}
            >
              {newPrice} руб.
            </Typography>
          )}
          <Typography
            className={promoPrice && s.oldPrice}
            variant={TypographyVariant.Large}
          >
            {priceRubbles} руб.
          </Typography>
        </div>
        <Button fullWidth={true} onClick={onPayProductClickHandler}>
          Оплатить
        </Button>
      </div>
    </div>
  );
};
