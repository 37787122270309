import { AxiosResponse } from "axios";

import { ChangingOneQuestionType } from "../../../store/features/questionTypes";
import { instance } from "../instance";

export const visionAPI = {
  send: (data: FormData): Promise<AxiosResponse<ResponseVisionType>> => {
    return instance.post("/vision/gpt_send/", data);
  },
};

export type RequestVisionType = {
  image: File;
  request_data: RequestVisionDataType;
};

export type RequestVisionDataType = {
  category_id: string;
  answers: ChangingOneQuestionType[];
};

export type ResponseVisionType = {
  content: string;
  img_url: string;
  status: string;
};
