import React, { useState } from "react";
import { Container } from "../../../../components/container/container";
import s from "./choosing-photo-page.module.scss";
import { ChoosingPhoto } from "./choosing-photo/choosing-photo";
import { Typography } from "../../../../components/typography/Typography";
import { Button } from "../../../../components/button/button";
import { TypographyVariant } from "../../../../store/storeConst/enums";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../../common/router/paths";
import { Avatar } from "../../../../store/features/profile/profile-type";
import { avatarsList } from "../../../../store/features/profile/profile-selectors";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { changeAvatar } from "../../../../store/features/profile/profile-thunk";
import { animation } from "../../../../assets/animation/animate";
import { motion } from "framer-motion";
import { CloseButton } from "../../../../components/close-button/close-button";

export const ChoosingPhotoPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const avatars = useAppSelector(avatarsList());
  const [active, setActive] = useState<Avatar | null>(null);
  const changeAvatarHandler = () =>
    dispatch(changeAvatar(active!)).then(() => {
      navigate(Paths.profile);
    });

  return (
    <Container className={s.ProfileContainer}>
      <motion.div
        variants={animation.up}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.2 }}
        className={s.ChangePhotos}
        color="first"
      >
        <CloseButton onClick={() => navigate(Paths.profile)} />
        <div className={s.ChoosingPhotoContainer}>
          <Typography className={s.Text} variant={TypographyVariant.Body2}>
            Выберите аватар
          </Typography>
          <ChoosingPhoto
            images={avatars}
            activeImg={active}
            onClick={(active) => setActive(active)}
          />
        </div>
        <Button onClick={changeAvatarHandler} fullWidth={true}>
          Сохранить
        </Button>
      </motion.div>
    </Container>
  );
};
