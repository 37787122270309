import { useAppDispatch } from '../../../../../store/store';
import { ChangingOneQuestionType, QuestionType } from '../../../../../store/features/questionTypes';
import OneQuestionNumericField from './oneQuestionNumericField/oneQuestionNumericField';
import { changeOneAnswerInQuestionAC } from '../../../../../store/features/questionSlice';

type OneQuestionNumericPropsType = {
    question: QuestionType
    isDisabled?: boolean
}
const OneQuestionNumeric = (props: OneQuestionNumericPropsType) => {
    
    const dispatch = useAppDispatch();
    const onQuestionTextChangeHandler = (newValue: string) => { 
        const dataToChange:ChangingOneQuestionType = {
            question_id: props.question.id,
            answer: newValue
        }
        dispatch(changeOneAnswerInQuestionAC(dataToChange))
    }

    return (
            <OneQuestionNumericField 
                    onChangeFunction={(newValue: string) => onQuestionTextChangeHandler(newValue)} 
                    value={props.question.answer} 
                    placeholder={props.question.snippet ? props.question.snippet : ""}
                    isDisabled={props.isDisabled}
                />
    )
}


export default OneQuestionNumeric;