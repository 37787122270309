import { Step } from "react-joyride";
import { FC, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import s from "./stepsStyle.module.scss";
import { changeOneAnswerInQuestionAC } from "../../../store/features/questionSlice";
import { appActions } from "../../../store/features/app/app-slice";
import { appSelectors } from "../../../store/features/app/app-selectors";
import {
  ResizeType,
  useResize,
} from "../../../common/hooks/use-resize/useResize";
import { OnboardingVariant } from "../../../store/features/app/app-types";
type PropsType = {
  setSteps: (value: Step[]) => void;
};
export const OnboardingStartSteps: FC<PropsType> = ({ setSteps }) => {
  const dispatch = useAppDispatch();
  const devWidth: ResizeType = useResize();
  const { variantEducation } = useAppSelector(appSelectors.getOnboarding());
  const onboardingSteps: Step[] = useMemo(
    () =>
      [
        {
          title: 'Приветствуем вас на портале "Пиарошная"!',
          content:
            "Работать с сервисом очень просто, но на всякий случай мы сделали короткую обучалку, чтобы вы сразу во всем разобрались!",
          placement: "center",
          target: "#logo",
          locale: {
            next: "Давайте разберемся!",
            skip: "Все знаю сам, закройте обучение!",
          },
          showSkipButton: false,
        },
        {
          title: "Супер!",
          content:
            "Знаем, что не терпится начать творить тексты. Но чтобы все работало -  сначала нужно ввести ваш промокод для генераций. Займет всего пару кликов!",
          placement: "center",
          target: "#logo",
          locale: { next: "Продолжить!" },
        },
        {
          target: "#headerAccountInfo",
          content:
            "Вбивать промокоды и управлять тарифами - можно в личном кабинете. Вход в него в верхнем правом углу экрана.  Нажмите в любом месте подсвеченной кнопки!",
          placement: "left",
          hideFooter: true,
          spotlightClicks: true,
        },
        {
          target: "#promoContainer",
          content: <StepUsePromo />,
          spotlightClicks: variantEducation === OnboardingVariant.First,
          placement: "left-start" as const,
          hideFooter: variantEducation === OnboardingVariant.First,
        },
        {
          target: "#tariffInfo",
          content:
            variantEducation === OnboardingVariant.ActiveTariff
              ? "Единственное, напомним - что число символов, доступное для генерации - всегда отображается в поле рядом с личным кабинетом."
              : 'Ура! Теперь вы можете генерировать тексты в "Пиарошной"! Сколько символов доступно для генерации - всегда можно подсмотреть в поле рядом с личным кабинетом.',
          locale: { next: "Понятно!" },
        },
        {
          target: "#tariffsButton",
          content:
            'И раз уж мы в личном кабинете… В блоке "Тарифы" вы всегда сможете выбрать нужный вам тариф, когда закончится промик...',
          locale: { next: "Логично!" },
        },
        {
          target: "#username",
          content:
            "...в этом поле вы можете ввести собственный ник(он же логин), чтобы не запоминать набор букв…",
          locale: { next: "Допустим 0_о" },
          placement: "right-start",
        },
        {
          target: "#changePasswordButton",
          content: '… нажав на кнопку "Изменить пароль" - поменять пароль...',
          locale: { next: "Ясно!" },
          placement: "right-start",
        },
        {
          target: "#changeAvatar",
          content:
            "… а нажав сюда- вы можете поставить себе на аватарку прикольного котика :) Смысла нет, но так будет веселее!",
          locale: { next: "Круто! А генерировать уже будем??" },
          placement: "right-start",
        },
        {
          target: "#logo",
          content:
            variantEducation === OnboardingVariant.WithoutTariff ? (
              <>
                А теперь, к сожалению, мы вынуждены прервать наше обучение
                вплоть до активации нового промокода или тарифа ( Но обучение
                всегда будет ждать вас в виде кнопки в Личном кабинете!
                Пополняйте баланс, забегайте - и быстренько завершим вводный
                курс!
              </>
            ) : (
              <>
                Все, теперь - идем к рабочей зоне "Пиарошной" :)
                <br /> Нажав на логотип в верхнем левом углу - вы всегда можете
                вернуться на главную страницу. Оттуда и начнем!
              </>
            ),
          locale: { next: "Понял!" },
          spotlightClicks: true,
          placement:
            variantEducation === OnboardingVariant.WithoutTariff
              ? "center"
              : "right",
          hideFooter: variantEducation !== OnboardingVariant.WithoutTariff,
        },
        {
          target: "#newsCarousel",
          content:
            'Итак - по центру главной страницы - у нас последние новости "Пиарошной". Пишем, когда добавляем новые крутые фичи или накатываем обновления.',
          locale: { next: "Ну где уже генерации???" },
          placement: "top-end" as const,
        },
        {
          target: "#sideBar",
          title: "Все - теперь - к генерациям.",
          content:
            'Слева у нас всегда находится меню основных разделов и инструментов "Пиарошной". Разделы - блоки, выделенные серым цветом. Под каждым таким блоком - входящие в него инструменты для создания текстов или решения других задач. Сейчас мы "Раскрыли" все разделы. Но если вам удобно видеть только содержимое нужного вам блока - смело сворачивайте лишнее, нажимая на "галочки" возле серых блоков.',
          locale: { next: "Круто! А генерировать уже будем??" },
          placement: "right",
        },
        {
          target: "#Пресс-релизы",
          content:
            'Проще всего понять принцип работы в "Пиарошной" в нашем самом первом инструменте - "Пресс-релизы". Нажмите на выделенный пункт!',
          hideFooter: true,
          spotlightClicks: true,
          placement: "right",
          spotlightPadding: 2,
        },
        {
          //Выбор модели
          // title: '"Пиарошная"!',
          content:
           <>
            Очень важное поле! Здесь меняется модель нейронки.
            <br /><br />
            На одной и той же фактуре, которую вы внесете далее, результаты на разных моделях будут принципиально разные!
            <br /><br />
            Мы вынесли в название ключевую суть «стиля» конкретных ИИ, но лучше попробуйте сами и подберите наиболее близкий 
            вам вариант <span>&#128522;</span>
            <br /><br />
            PS: менять модель можно после внесения фактуры. Вносить все данные повторно не потребуется 😉
           </>,
          spotlightClicks: true,
          placement: "right-end",
          target: "#model_selection_id",
          locale: {
            next: "С этим понятно!",
            // skip: "Все знаю сам, закройте обучение!",
          },
          // showSkipButton: false,
        },
        {
          target: "#questionsDiv",
          content: (
            <>
              Это - рабочая зона "Пиарошной", где для создания текста нужно...
              просто ответить на наши наводящие вопросы. Все просто :)
              'Небольшой тест: вбейте
              <span
                className={s.promoCode}
                onClick={() =>
                  dispatch(
                    changeOneAnswerInQuestionAC({
                      question_id: "88f1924c-529b-4999-b7ee-b6bdf6bfebca",
                      answer: "Пиарошная",
                    })
                  )
                }
              >
                "Пиарошная"
              </span>
              в поле "Заказчик. Кто автор релиза?".
            </>
          ),
          spotlightClicks: true,
          placement: "right",
          spotlightPadding: 2,
          locale: { next: "Сделано!" },
        },
        {
          target: "#generateTextButton",
          content: <StepContentTwo />,
          hideFooter: true,
          spotlightClicks: true,
          placement: "right-start",
          spotlightPadding: 2,
          locale: { next: "Сделано!" },
        },
        {
          target: "#sideBar",
          content: (
            <>
              На этом… вводное обучение закончено! Поздравляем: вы -
              квалифицированный пользователь "Пиарошной"! Текст будет готов
              примерно через минут :)
              <br /> <br /> Обратим внимание еще только на пару моментов:
              <br />
              1. Старайтесь качественно заполнять как можно больше полей (без
              воды, фактами). Больше фактуры - более качественный результат.
              <br />
              2. Не забывайте про поле "Доп. Пожеланий"! Оно прямо очень важное
              :) Если вам нужно дать какую-то особенную фактуру программе, или
              вы хотите скорректировать получаемые результаты - пишите пожелания
              именно туда! "Меньше воды", "Без деепричастных оборотов", "Пиши
              проще!" - прописываем прямо все что хотим добавить к нашему ТЗ. И
              поверьте, программа услышит, а ваши генерации - станут еще лучше!
            </>
          ),
          placement: "center",
          locale: { last: "Спасибо!" },
        },
      ] as Step[],
    [dispatch]
  );

  const onboardingMobileSteps: Step[] = useMemo(
    () => [
      {
        title: 'Приветствуем вас в "Пиарошной"!',
        target: "#logo",
        content:
          "Работать с сервисом очень просто, но также мы сделали короткую обучалку 🙂  \n" +
          'Она откроется  при первом запуске "Пиарошной" с компьютера или ноутбука. Если же вы хотите продолжить работу в мобильной версии, то перед созданием текстов рекомендуем вбить промокод Start01 (или любой другой) в личном кабинете. Иначе генерации не сработают  🙈',
        locale: { last: "Понял" },
        placement: "center",
      },
    ],
    []
  );

  useEffect(
    () =>
      devWidth.isScreenLg
        ? setSteps(onboardingSteps)
        : setSteps(onboardingMobileSteps),
    [devWidth.isScreenLg, onboardingSteps, setSteps]
  );
  return null;
};

const StepUsePromo = () => {
  const dispatch = useAppDispatch();
  const { variantEducation } = useAppSelector(appSelectors.getOnboarding());
  if (variantEducation === OnboardingVariant.First) {
    // Промокол не был исползован / нет активного тарифа
    return (
      <>
        Отлично, вы в личном кабинете! В выделенной области - поле для ввода
        промокода. Вбейте сюда промик, который вы получили в телеграм-боте при
        регистрации (если лень искать - то нажмите:
        <span
          className={s.promoCode}
          onClick={() => dispatch(appActions.setPromo("Start01"))}
        >
          Start01
        </span>
        ) и нажмите кнопку со стрелкочкой.
      </>
    );
  } else if (variantEducation === OnboardingVariant.WithoutTariff) {
    // Промокод был использован, активного тарифа нет
    return (
      <>
        Отлично, вы в личном кабинете! В выделенной области - поле для ввода
        промокода. Однако, судя по всему, стартовый промокод вы уже применили, и
        он либо истек, либо на нем закончились символы 🙁 Поэтому полностью
        пройти обучение, к сожалению, не получится вплоть до активации тарифа
        или применения нового промокода. Но это не помешает нам закончить
        "разборки" с личным кабинетом 🙂
      </>
    );
  } else {
    // Есть активный тариф
    return (
      <>
        Отлично, вы в личном кабинете! В выделенной области - поле для ввода
        промокода. У вас символы на балансе уже есть, и судя по всему, с
        промокодами и тарифами вы уже разобрались 🙂 Не делаем акцента, идем
        дальше 🙂
      </>
    );
  }
};

const StepContentTwo = () => {
  const dispatch = useAppDispatch();
  const { stepIndex } = useAppSelector(appSelectors.getOnboarding());
  const answers = [
    {
      question_id: "88f1924c-529b-4999-b7ee-b6bdf6bfebca",
      answer: "Пиарошная",
    },
    {
      question_id: "6d2da13f-32cb-4f6b-9dcd-402fafd2ea07",
      answer:
        'Запустился онлайн-сервис "Пиарошная", который позволяет генерировать тексты профессионального качества на базе ИИ для специалистов в области PR и связей с общественностью. ',
    },
    {
      question_id: "4da59369-0b41-4b75-8ee8-a613592c8967",
      answer:
        "Сервис полезен: Директорам по PR\n" +
        "Специалистам по связям с общественностью\n" +
        "Event-менеджерам\n" +
        "SMM-профессионалам\n" +
        "Маркетологам\n" +
        "Коммуникационным агентствам\n" +
        "Владельцам малого и среднего бизнеса",
    },
    {
      question_id: "d63d412d-e2ee-48eb-99f7-dba3108f7c45",
      answer:
        "Запущен первый в России сервис на нейронных сетях специально для пиарщиков - Пиарошная (aipr.pro). Проект генерирует тексты профессионального уровня для специалистов в области пиар и маркетинга. Упрощает жизнь, убирает рутинные операции, помогает людям без опыта в пиар.",
    },
    {
      question_id: "e4f70d2c-83c2-4c79-b90a-5ce422b9e5ed",
      answer:
        '"Пиарошная" запустилась и по доступной цене избавит PR-специалистов от рутинных задач.',
    },
    {
      question_id: "50fd7e24-08c4-36ea-d10a-f41dfacc293b",
      answer:
        'Пиши четко и конкретно, не используй сложные фразы, представь "Пиарошную" в выгодном свете.',
    },
  ];

  const handleClick = () => {
    dispatch(appActions.setOnboarding({ stepIndex: stepIndex + 1 }));
  };

  const element = document.getElementById("generateTextButton");
  useEffect(() => {
    answers.map((answer) => dispatch(changeOneAnswerInQuestionAC(answer)));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (element) {
      element.addEventListener("click", handleClick);
    }

    return () => {
      if (element) {
        element.removeEventListener("click", handleClick);
      }
    };
  }, []);

  return (
    <>
      Отлично! Принцип вы поняли :) Чтобы перейти к созданию текстов, мы сразу
      заполнили за вас еще несколько полей. Вам остается только нажать на кнопку
      "Генерировать текст" и дождаться ответа!
    </>
  );
};
