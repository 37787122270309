import { SVGProps, Ref, forwardRef, memo } from "react";
import { motion } from "framer-motion";
export const SupportIcon = motion(
  memo(
    forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
          ref={ref}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0" />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <title />{" "}
            <g id="Complete">
              {" "}
              <g id="support">
                <g>
                  <circle
                    cx="12"
                    cy="12"
                    fill="none"
                    r="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    transform="translate(-5 12) rotate(-45)"
                  />
                  <circle
                    cx="12"
                    cy="12"
                    fill="none"
                    r="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    transform="translate(-5 12) rotate(-45)"
                  />
                  <line
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    x1="5"
                    x2="9"
                    y1="5"
                    y2="9"
                  />{" "}
                  <line
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    x1="15"
                    x2="19"
                    y1="9"
                    y2="5"
                  />
                  <line
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    x1="15"
                    x2="19"
                    y1="15"
                    y2="19"
                  />
                  <line
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    x1="9"
                    x2="5"
                    y1="15"
                    y2="19"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    })
  )
);
