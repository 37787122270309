import { ChangeEvent, DragEvent, useRef, useState } from "react";
import s from "./upload-image.module.scss";
import AddImage from "../../assets/icons/add-Image";
import { Typography } from "../typography/Typography";
import { TypographyVariant } from "../../store/storeConst/enums";
import clsx from "clsx";

type PropsType = {
  setImage: (image: File) => void;
};

export const UploadImage = ({ setImage }: PropsType) => {
  const ref = useRef<HTMLInputElement>(null);
  const [drag, setDrag] = useState(false);
  const [previewImage, setPreviewImage] = useState<null | string>(null);
  const reader = new FileReader();
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    reader.onloadend = () => {
      setPreviewImage(reader.result as string);
    };
    file && reader.readAsDataURL(file);
    setImage(file!);
  };
  const dragHandlerStart = (e: DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDrag(true);
  };

  const dragHandlerLeave = (e: DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDropHandler = (e: DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    reader.onloadend = () => {
      setPreviewImage(reader.result as string);
    };
    file && reader.readAsDataURL(file);
    setImage(file);
    setDrag(false);
  };
  return (
    <div
      onDragStart={dragHandlerStart}
      onDragLeave={dragHandlerLeave}
      onDragOver={dragHandlerStart}
      onDrop={onDropHandler}
      className={clsx(
        drag && s.drag,
        s.uploader,
        previewImage && s.previewImage
      )}
      onClick={() => ref.current?.click()}
    >
      {previewImage ? (
        <img alt={"img"} src={previewImage} />
      ) : (
        <>
          <AddImage />
          <Typography variant={TypographyVariant.Body2}>
            {drag
              ? "Отпустите изображение чтобы загрузить его!"
              : "Выберите изображение или перетащите его в это поле."}
          </Typography>
        </>
      )}

      <input
        className={s.fileInput}
        ref={ref}
        accept={".png, .jpg, .jpeg, .webp"}
        onChange={onChangeHandler}
        type={"file"}
      />
    </div>
  );
};
