import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import * as RadixSelect from "@radix-ui/react-select";

import s from "../Select.module.scss";
import { SelectVariant } from "../Select";
import { TypographyVariant } from "../../../store/storeConst/enums";
import { Typography } from "../../typography/Typography";
import { clsx } from "clsx";
import { Checkbox } from "../../checkbox/checkbox";

type SelectItemProps = {
  variant?: SelectVariant;
  className?: string;
  checked?: boolean;
  onCheckedChange: (value: boolean) => void;
} & ComponentPropsWithoutRef<typeof RadixSelect.Item>;

export const SelectItem = forwardRef<
  ElementRef<typeof RadixSelect.Item>,
  SelectItemProps
>(
  (
    {
      variant = "default",
      title,
      checked,
      onCheckedChange,
      disabled,
      className,
      ...restProps
    },
    ref
  ): JSX.Element => {
    const typographyVariant =
      variant === "default" ? TypographyVariant.Body1 : TypographyVariant.Body2;

    const classNames = {
      selectItem: clsx(s.selectItem, disabled && s.disabled, className),
      text: s.text,
    };

    return variant === "multiple" ? (
      <Checkbox
        className={classNames.selectItem}
        disabled={disabled}
        checked={checked!}
        label={title}
        onCheckedChange={onCheckedChange}
      />
    ) : (
      <RadixSelect.Item
        ref={ref}
        className={classNames.selectItem}
        {...restProps}
      >
        <RadixSelect.ItemText>
          <Typography className={classNames.text} variant={typographyVariant}>
            {title}
          </Typography>
        </RadixSelect.ItemText>
      </RadixSelect.Item>
    );
  }
);
