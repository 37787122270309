import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import { Avatar, UserName, UserType } from "./profile-type";
import { filesAPI } from "../../../common/api/files-api/files-api";
import { profileAPI } from "../../../common/api/profile/profile-api";
import { handleServerNetworkError } from "../../../common/utils/errorUtils";
import { UseFormSetError } from "react-hook-form";
import { appActions } from "../app/app-slice";
import { getCategoriesThunk } from "../questionThunk";
import { newsThunks } from "../news/news";
import { OnboardingStatusVariant } from "../app/app-types";

export const getMainData = createAppAsyncThunk(
  "profile/getMainData",
  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    dispatch(appActions.setLoading(true));
    await Promise.all([
      dispatch(fetchProfile()),
      dispatch(getCategoriesThunk()),
      dispatch(newsThunks.getNews()),
    ]);
    try {
      dispatch(appActions.setLoading(false));
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);
export const fetchProfile = createAppAsyncThunk<UserType>(
  "profile/fetchProfile",
  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const profileResponse = await profileAPI.getProfile();
      return profileResponse.data.data;
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);

export const changeUsername = createAppAsyncThunk<UserName, ThunkBodyType>(
  "profile/changeUserName",
  async (arg, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await profileAPI.changeUsername(arg.userName);
      const username = response.data.data.username;
      return { username };
    } catch (e: unknown) {
      const message = handleServerNetworkError(e, dispatch);
      arg.setError("username", { message });
      return rejectWithValue(null);
    }
  }
);
export const changeStatusEducation = createAppAsyncThunk<
  void,
  { status_education: OnboardingStatusVariant }
>("profile/changeStatusEducation", async ({ status_education }, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    await profileAPI.changeStatusEducation({ status_education });
  } catch (e: unknown) {
    return rejectWithValue(null);
  }
});

export const fetchAvatarsList = createAppAsyncThunk<Avatar[]>(
  "profile/fetchProfileImages",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const avatarLists = await filesAPI.fetchAvatarList();
    try {
      return avatarLists.data.payloads;
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);

export const changeAvatar = createAppAsyncThunk<string, Avatar>(
  "profile/changeAvatar",
  async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await filesAPI.changeAvatar(arg.id);
      return arg.src;
    } catch (e: unknown) {
      return rejectWithValue(null);
    }
  }
);

type ThunkBodyType = {
  userName: Pick<UserType, "username">;
  setError: UseFormSetError<{ username: string }>;
};
