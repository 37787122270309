import { motion } from "framer-motion";
import s from "./side-menu.module.scss";
import {
  CategoryType,
  ParentCategoryType,
  SVGElementType,
} from "../../store/features/questionTypes";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { Section, SectionItemType } from "./section/section";
import {
  ContactsIcon,
  SupportIcon,
  MailIcon,
  TelegramIcon,
} from "../../assets/icons";
import { appSelectors } from "../../store/features/app/app-selectors";
import { appActions } from "../../store/features/app/app-slice";
import clsx from "clsx";
import { Scrollbar } from "../scrollbar";
import { ResizeType, useResize } from "../../common/hooks/use-resize/useResize";
import { FoldIconAnimate, sideBarAnimation } from "./side-menu-animate";
import { Paths } from "../../common/router/paths";
import { useMemo } from "react";
import {
  CatIcon1,
  CatIcon2,
  CatIcon3,
  CatIcon4,
  CatIcon5,
  CatIcon6,
  FoldIcon,
} from "../../assets/icons";
import { SearchAndFold } from "./search-and-fold/search-and-fold";

const imgArr = [CatIcon1, CatIcon2, CatIcon3, CatIcon4, CatIcon5, CatIcon6];
const supportItems: SectionItemType[] = [
  {
    title: "Tg-канал “Пиарошная»",
    path: "https://t.me/pyaroshnaya",
    id: "https://t.me/pyaroshnaya",
    ImageSVG: TelegramIcon,
  },
  {
    title: "Поддержка (телеграм)",
    path: "https://t.me/aipr_support",
    id: "https://t.me/aipr_support",
    ImageSVG: SupportIcon,
  },
  {
    title: "Поддержка (почта)",
    path: "mailto:info@aipr.pro",
    id: "mailto:info@aipr.pro",
    ImageSVG: MailIcon,
  },
];
export const SideMenu = () => {
  const dispatch = useAppDispatch();
  const devWidth: ResizeType = useResize();
  const categories: Array<CategoryType> = useSelector(
    (state: RootState) => state.question.categories
  );

  const isOpen = useAppSelector(appSelectors.isOpenSideBar());
  const toggle = () => dispatch(appActions.toggleSideBar(!isOpen));
  const parentCategories = useMemo(
    () =>
      categories
        .filter((cat) => !cat.parentId)
        .map((category, i) => ({
          ...category,
          child: categories
            .filter((cat) => cat.parentId === category.id)
            .map(
              (child) =>
                ({
                  title: child.title,
                  type_gpt: child.type_gpt,
                  id: child.id,
                  img: child.img,
                  itemType: "category",
                  path: `${Paths.category}/${child.id}`,
                  isNew: child.is_new_category,
                } as SectionItemType)
            ) as CategoryType[],
          img: imgArr[i],
          isNew: categories.some(
            (catChild) =>
              category.id === catChild.parentId && catChild.is_new_category
          ),
        })) as ParentCategoryType<SVGElementType>[],
    [categories]
  );

  return (
    <motion.div
      className={clsx(
        isOpen ? s.ShowWrapper : s.HiddenWrapper,
        !devWidth.isScreenSm && !isOpen
          ? s.MobileWrapper
          : !devWidth.isScreenLg && !isOpen && s.TabletWrapper
      )}
      animate={sideBarAnimation(isOpen, devWidth)}
    >
      <Scrollbar id={"sideBar"} type={"scroll"} className={s.ScrollBar}>
        <motion.div
          className={clsx(s.SideBar, isOpen ? s.Show : s.Hidden)}
          animate={sideBarAnimation(isOpen, devWidth)}
        >
          <div className={s.Links}>
            {/* {devWidth.isScreenLg && (
              <FoldIcon
                className={s.Fold}
                animate={FoldIconAnimate(isOpen)}
                onClick={toggle}
              />
            )} */}
            <SearchAndFold />
            {parentCategories.map((c) => {
              return (
                <Section
                  key={c.id}
                  ImageSVG={c.img!}
                  path={`${Paths.category}/${c.id}`}
                  id={c.id}
                  title={c.title}
                  isNotViewedAnswer={c.isNotViewedAnswer}
                  child={c.child}
                  isNew={c.isNew}
                />
              );
            })}
          </div>
          <Section
            itemType={"support"}
            ImageSVG={ContactsIcon}
            id={"support"}
            title={"Контакты"}
            child={supportItems}
          />
        </motion.div>
      </Scrollbar>
    </motion.div>
  );
};
