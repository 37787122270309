import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const signUpSchema = z
  .object({
    oldPassword: z.string().nonempty("Введите старый пароль").trim(),
    newPassword: z.string().min(4, "Минимум 4 символа").trim(),
    confirmPassword: z.string().min(4, "Минимум 4 символа").trim(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Пароли не совпадают.",
    path: ["confirmPassword"],
  });

export type ChangePasswordFormType = z.infer<typeof signUpSchema>;

export const useChangePasswordForm = () =>
  useForm<ChangePasswordFormType>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });
